import { Button, Container, FormControl, MenuItem, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';

const Step2 = ({
	classes,
	nextStep,
	prevStep,
	profession,
	state,
	changeState,
	subProfessions,
	yearsOfExperienceOptions,
	diplomaDegreeOptions
}) => {
	return (
		<>
			<Typography
				component='h2'
				variant='h5'
				className={clsx(classes.heading, classes.uppercase, classes.mapHeading)}
			>
				Qualification and Experience
			</Typography>

			<Typography
				component='h3'
				variant='h5'
			>
				{profession}
			</Typography>
			{
				profession === 'Psychologist' &&
				<Typography
					component='strong'
					variant='subtitle1'
				>
					Fill only if you have a masters degree in psychology
				</Typography>
			}

			<form className={classes.form} >

				<FormControl
					component='fieldset'
					className={classes.formControl}
					fullWidth
					style={{
						backgroundColor: "#EAEAEA",
						border: '2px solid #cec9de',
						borderRadius: '7px',
					}}
				>
					{
						subProfessions[profession] && <>
							<Typography
								variant='subtitle1'
								className={classes.mapFormLabel}
							>
								Profession *
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='area'
								name='area'
								value={state.profession}
								onChange={e => changeState({ profession: e.target.value })}
								error={state.professionError !== ''}
								helperText={state.professionError}
							>
								{
									subProfessions[profession].map((option, i) => (
										<MenuItem key={`profession-${i}`} value={option}>
											{option}
										</MenuItem>
									))
								}
							</TextField>
						</>
					}

					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						Diploma / Degree *
					</Typography>

					{
						profession === 'Psychiatrist' ?
							<TextField
								className={classes.formField}
								InputProps={{
									inputProps: {
										maxLength: 100,
									}
								}}
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='diplomaDegree'
								name='diplomaDegree'
								autoComplete='diplomaDegree'
								value={state.diplomaDegree}
								onChange={e => changeState({ diplomaDegree: e.target.value })}
								error={state.diplomaDegreeError !== ''}
								helperText={state.diplomaDegreeError}
								select
							>
								{
									diplomaDegreeOptions.map((option, i) => (
										<MenuItem key={`diplomaDegree-${i}`} value={option}>
											{option}
										</MenuItem>
									))
								}
							</TextField>
							:
							<TextField
								className={classes.formField}
								InputProps={{
									inputProps: {
										maxLength: 100,
									}
								}}
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='diplomaDegree'
								name='diplomaDegree'
								autoComplete='diplomaDegree'
								value={state.diplomaDegree}
								onChange={e => changeState({ diplomaDegree: e.target.value })}
								error={state.diplomaDegreeError !== ''}
								helperText={state.diplomaDegreeError}
							/>
					}

					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						Institution *
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 100,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='diplomaDegree'
						name='diplomaDegree'
						autoComplete='diplomaDegree'
						value={state.institution}
						onChange={e => changeState({ institution: e.target.value })}
						error={state.institutionError !== ''}
						helperText={state.institutionError}
					/>


					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						Years of Experience *
					</Typography>

					<TextField
						className={classes.formField}
						select
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='area'
						name='area'
						value={state.yearsOfExperience}
						onChange={e => changeState({ yearsOfExperience: e.target.value })}
						error={state.yearsOfExperienceError !== ''}
						helperText={state.yearsOfExperienceError}
					>
						{
							yearsOfExperienceOptions.map((option, i) => (
								<MenuItem key={`yearsOfExperience-${i}`} value={option}>
									{option}
								</MenuItem>
							))
						}
					</TextField>

				</FormControl>
			</form>

			<Typography
				variant='subtitle1'
				style={{
					color: '#38364c',
					fontSize: '1rem',
					fontWeight: 'bold',
					alignSelf: 'flex-start',
					marginLeft: '2rem'
				}}
			>
				* MUST BE COMPLETED
			</Typography>

			<Container className={classes.containerBetween}>

				<Button
					style={{
						backgroundColor: '#59567f',
						width: '100px',
						display: 'flex',
						fontWeight: 900
					}}
					variant='contained'
					color='primary'
					className={classes.submit}
					onClick={prevStep}
				>
					Back
				</Button>

				<Button
					style={{
						backgroundColor: '#59567f',
						width: '100px',
						display: 'flex',
						fontWeight: 900
					}}
					variant='contained'
					color='primary'
					className={classes.submit}
					onClick={nextStep}
				>
					Next
				</Button>
			</Container>
		</>
	)
};

export default Step2;

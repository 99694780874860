import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "./GridHeadings";
import CasesToolbar from "./CasesToolbar";
import CardView from "./CardView";
import { Helmet } from "react-helmet-async";
const AllCases = ({ rows, onUpdate, onDelete, authData }) => {
  const [isGridView, setIsGridView] = useState(false);
  const toggleView = (datafromchild) => {
    setIsGridView(datafromchild);
  };
  return (
    <div
      style={{
        height: 400,
        width: "100%",
      }}>
      {/* meta tags */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>All Cases</title>
        <meta name="description" content="All Cases" />
      </Helmet>
      {/* end meta */}
      <CasesToolbar
        rows={rows}
        isGridView={isGridView}
        settingGridView={toggleView}
      />
      {isGridView ? (
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            checkboxSelection={false}
            pageSize={20}
            disableSelectionOnClick={true}
          />
        </div>
      ) : (
        <CardView
          rows={rows}
          onUpdate={onUpdate}
          onDelete={onDelete}
          authData={authData}
        />
      )}
    </div>
  );
};
export default AllCases;

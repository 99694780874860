import { useEffect } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import Home from './Home';
import Login from './Login';


const Hamdard = ({
	authData, setAuth, courseData, setCourseData, classes, redirectToHome, logOut
}) => {
	const history = useHistory();
	let { path } = useRouteMatch();

	useEffect(() => redirectToHome(history), [redirectToHome, history]);

	return (
		<Switch>
			<Route path={`${path}/login`}>
				<Login
					authData={authData}
					setAuth={setAuth}
				/>
			</Route>

			<Route
				path={`${path}/home`}
			>
				<Home
					classes={classes}
					setAuth={setAuth}
					authData={authData}
					courseData={courseData}
					setCourseData={setCourseData}
					logOut={logOut}
				/>
			</Route>
		</Switch>
	);
};

export default Hamdard;

import {
	Button, CircularProgress, Container, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, TextField, Typography, Grid, Checkbox
} from '@material-ui/core';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			overflowX: 'hidden',
			height: '100%'
		},
		html: {
			overflowX: 'hidden',
			height: '100%'
		}
	},
	main: {
		marginTop: theme.spacing(4),
		width: '50%',
		backgroundColor: '#EAEAEA',
		[theme.breakpoints.down('xs')]: {
			width: '100%'
		},
	},
	modal: {
		backgroundColor: 'white',
		margin: 'auto',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '50%',
		borderRadius: '1rem'
	},
	loader: {
		width: '6rem !important',
		height: '6rem !important',
		color: '#07D5C2'
	},
	paper: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '2rem',
	},
	paperBackground: {
		backgroundColor: '#fafafa',
		border: 'solid black',
		borderWidth: '0.15rem',
		borderRadius: '0.3rem',
	},
	uppercase: {
		textTransform: 'uppercase',
	},
	bold: {
		fontWeight: 'bold'
	},
	avatar: {
		margin: theme.spacing(1),
		width: 172,
		height: 84,
		backgroundColor: theme.palette.background.main,
		borderRadius: 0,
	},
	pageLogo: {
		margin: 'auto',
	},
	banner: {
		width: '100vw'
	},
	criteriaPage: {
		marginTop: theme.spacing(2),
	},
	heading: {
		textAlign: 'center',
		backgroundColor: '#EAEAEA',
		fontWeight: 'bold',
		fontSize: '1.4rem',
		padding: '5px 0 5px 0',
		marginBottom: theme.spacing(2)
	},
	mapHeading: {
		color: '#59567f',
		textTransform: 'uppercase',
		margin: '1rem 0',
	},
	links: {
		alignSelf: 'normal',
		'& > *': {
			margin: theme.spacing(1)
		}
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	title: {
		flexGrow: 1,
	},
	formControl: {
		margin: `${theme.spacing(3)}px 0`,
		padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
		border: `2px solid ${theme.palette.primary.light}`,
	},
	formLabel: {
		color: '#07D5C2',
		fontSize: '0.825rem',
		fontWeight: 'bold',
		marginLeft: theme.spacing(1),
	},
	mapFormLabel: {
		color: '#07D5C2',
		marginLeft: theme.spacing(1),
		fontFamily: 'Arial',
		textTransform: 'uppercase',
		fontSize: '1rem',
		fontWeight: 550
	},
	formField: {
		margin: `${theme.spacing(1)}px 0`,
		backgroundColor: 'white',
		borderRadius: '7px',
	},
	controlError: {
		borderColor: theme.palette.error.main
	},
	checkboxContainer: {
		backgroundColor: 'white',
		borderRadius: '7px',
		border: `1px solid #bbb`,
		padding: theme.spacing(2),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		alignItems: 'center'
	},
	checkboxes: {
		display: 'flex',
		marginTop: theme.spacing(1),
		flexWrap: 'wrap',
	},
	formHeading: {
		margin: `${theme.spacing(2)}px 0`,
	},
	successAvatar: {
		marginBottom: theme.spacing(2),
		backgroundColor: theme.palette.secondary.main,
	},
	warningAvatar: {
		marginBottom: theme.spacing(2),
		backgroundColor: theme.palette.error.main,
	},
	warningContainer: {
		padding: theme.spacing(4),
		border: `2px solid ${theme.palette.error.main}`,
	},
	listBody: {
		fontSize: '1.25rem',
		'& > ol > li': {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	},
	successText: {
		textAlign: 'center'
	},
	caseGrid: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr 1fr",
		gridGap: 10,
		padding: 0,
		marginBottom: '3rem',
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: "1fr",
		}
	},
	case: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '1rem',
		backgroundColor: '#eaeaea',
		border: '2px solid #cec9de',
		borderRadius: '7px',
		position: 'relative'
	},
	purpleText: {
		color: '#451062',
	},
	warningText: {
		color: ' #a1035a',
		textAlign: 'center',
	},
	caseInfo: {
		fontWeight: "bold",
		textTransform: 'capitalize',
	},
	caseTop: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: 0
	},
	caseText: {
		marginTop: '1rem'
	},
	homeButton: {
		backgroundColor: '#9487bc',
		color: '#fafafa',
		fontSize: '0.875rem',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		margin: '0 auto',
		marginTop: '1rem',
		textTransform: 'uppercase',

		'&:hover': {
			backgroundColor: '#451062',
		},
	},
	input: {
		backgroundColor: '#fafafa',
		border: '2px solid #cec9de',
		borderRadius: '6px',
		marginTop: 8,
		marginBottom: 8
	},
	caseValue: {
		marginLeft: '0.5rem'
	},
	resp: {
		width: '50%',
		[theme.breakpoints.down('xs')]: {
			width: '75%'

		},
	},
	containerBetween: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	dropDown:{
		[theme.breakpoints.down('md')]: {
		display: 'block'
		},
	}
}));

const Step2 = ({ prevStep, register, hospitalTypeOptions, state, changeState }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false)
	const clickRegister = async () => {
		setLoading(true);
		const newLoading = await register();
		setLoading(newLoading);
	};

	return (
		<>
			<form className={classes.form}>
				<FormControl
					component='fieldset'
					className={classes.formControl}
					fullWidth
					style={{
						backgroundColor: "#EAEAEA",
						border: '2px solid #cec9de',
						borderRadius: '7px',
					}}
				>

					<Typography
						variant='subtitle1'
						className={classes.formLabel}
						style={{
							fontFamily: 'Arial',
							textTransform: 'uppercase',
							fontSize: '1rem',
							fontWeight: 550
						}}
					>
						Sector
					</Typography>

					<RadioGroup
						row
						style={{
							marginTop: '0.5rem',
							backgroundColor: 'white',
							width: '100%',
							borderRadius: '5px',
							display: 'flex',
							marginBottom: '0.5rem',
							justifyContent: 'space-around'

						}}
					>
						<FormControlLabel
							value="PUBLIC"
							control={
								<Radio
									checked={state.sector === 'PUBLIC'}
									onChange={e => changeState({ sector: e.target.value })}
									color='primary'
								/>
							}
							label="PUBLIC"
							classes={{
								label: clsx(classes.uppercase, classes.bold)
							}}
						/>

						<FormControlLabel
							value="PRIVATE"
							control={
								<Radio
									checked={state.sector === 'PRIVATE'}
									onChange={e => changeState({ sector: e.target.value })}
									color='primary'
								/>
							}
							label="PRIVATE"
							classes={{
								label: clsx(classes.uppercase, classes.bold)
							}}
						/>

						<FormControlLabel
							value="NGO"
							control={
								<Radio
									checked={state.sector === 'NGO'}
									onChange={e => changeState({ sector: e.target.value })}
									color='primary'
								/>
							}
							label="NGO"
							classes={{
								label: clsx(classes.uppercase, classes.bold)
							}}
						/>

						<FormControlLabel
							value="OTHERS"
							control={
								<Radio
									checked={state.sector === 'OTHERS'}
									onChange={e => changeState({ sector: e.target.value })}
									color='primary'
								/>
							}
							label="OTHERS"
							classes={{
								label: clsx(classes.uppercase, classes.bold)
							}}
						/>
						{
							state.sectorError &&
							<Grid item lg={12}>
								<Typography color='error'>
									{state.sectorError}
								</Typography>
							</Grid>
						}
					</RadioGroup>
				</FormControl>
			</form>

			<form className={classes.form}>
				<FormControl
					component='fieldset'
					className={classes.formControl}
					fullWidth
					style={{
						backgroundColor: "#EAEAEA",
						border: '2px solid #cec9de',
						borderRadius: '7px',
					}}
				>

					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						Category *
					</Typography>

					<Grid
						container
						className={classes.checkboxContainer}
					>
						<Grid item lg={12}>
							<Typography>
								More than one field can be ticked
							</Typography>
						</Grid>

						<Grid
							item
							lg={12}
							className={classes.checkboxes}
							style={{
								justifyContent: 'space-between'
							}}
						>
							<FormControlLabel
								control={
									<Checkbox
										name='isClinicalService'
										color='primary'
										checked={state.isClinicalService}
										onChange={e => changeState({ isClinicalService: e.target.checked })}
									/>
								}
								label='Clinical Service'
								classes={{
									label: clsx(classes.uppercase, classes.bold)
								}}
							/>
							<FormControlLabel
								control={
									<Checkbox
										name='isResearch'
										color='primary'
										checked={state.isResearch}
										onChange={e => changeState({ isResearch: e.target.checked })}
									/>
								}
								label='Research'
								classes={{
									label: clsx(classes.uppercase, classes.bold)
								}}
							/>
							<FormControlLabel
								control={
									<Checkbox
										name='isTraining'
										color='primary'
										checked={state.isTraining}
										onChange={e => changeState({ isTraining: e.target.checked })}
									/>
								}
								label='Training'
								classes={{
									label: clsx(classes.uppercase, classes.bold)
								}}
							/>
							<FormControlLabel
								control={
									<Checkbox
										name='isOthers'
										color='primary'
										checked={state.isOthers}
										onChange={e => changeState({ isOthers: e.target.checked })}
									/>
								}
								label='Other'
								classes={{
									label: clsx(classes.uppercase, classes.bold)
								}}
							/>
						</Grid>

						{
							state.categoryError &&
							<Grid item lg={12}>
								<Typography color='error'>
									{state.categoryError}
								</Typography>
							</Grid>
						}
					</Grid>
				</FormControl>
			</form>

			{
				state.isClinicalService && (
					<form className={classes.form}>

						<FormControl
							component='fieldset'
							className={classes.formControl}
							fullWidth
							style={{
								backgroundColor: "#EAEAEA",
								border: '2px solid #cec9de',
								borderRadius: '7px',
							}}
						>

							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Clinical Service
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='clinicalService'
								name='clinicalService'
								value={state.clinicalService}
								onChange={e => changeState({
									clinicalService: e.target.value
								})}
								helperText={state.clinicalServiceError}
								error={state.clinicalServiceError !== ''}
							>
								{
									hospitalTypeOptions.map((option, i) => {
										return (
											<MenuItem key={`clinicalService-${i}`} value={option}>
												{option}
											</MenuItem>
										);
									})
								}
							</TextField>

						</FormControl>
					</form>
				)
			}


			<Typography
				variant='subtitle1'
				style={{
					color: '#38364c',
					fontSize: '1.5rem',
					fontWeight: 'bold',
					alignSelf: 'flex-start',
					marginLeft: '1rem',
					textTransform: 'uppercase'
				}}
			>
				Secondary Care Hospital
			</Typography>

			<form className={classes.form}>

				<FormControl
					component='fieldset'
					className={classes.formControl}
					fullWidth
					style={{
						backgroundColor: "#EAEAEA",
						border: '2px solid #cec9de',
						borderRadius: '7px',
					}}
				>

					<Grid
						container
						row
						spacing={2}
						className={classes.dropDown}
					>
						<Grid item lg={6} md={12}>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Psychiatrist
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='secondaryPsychiatrists'
								name='secondaryPsychiatrists'
								value={state.secondary.psychiatrists}
								onChange={e => changeState({
									secondary: {
										psychiatrists: e.target.value
									}
								})}
								helperText={state.secondary.psychiatristsError}
								error={state.secondary.psychiatristsError !== ''}
							>
								{
									[...Array(21).keys()].map((num, i) => {
										return (
											<MenuItem key={i} value={num}>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
						</Grid>

						<Grid item lg={6} md={12} >
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Medical Officers
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='secondaryMedicalOfficers'
								name='secondaryMedicalOfficers'
								value={state.secondary.medicalOfficers}
								onChange={e => changeState({
									secondary: {
										medicalOfficers: e.target.value
									}
								})}
								helperText={state.secondary.medicalOfficersError}
								error={state.secondary.medicalOfficersError !== ''}
							>
								{
									[...Array(21).keys()].map((num, i) => {
										return (
											<MenuItem key={`medical-officers-${i}`} value={num}>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
						</Grid>
					</Grid>


					<Grid
						container
						row
						spacing={2}
						className={classes.dropDown}
					>
						<Grid item lg={6} md={12}>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Psychologists
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='secondaryPsychologists'
								name='secondaryPsychologists'
								value={state.secondary.psychologists}
								onChange={e => changeState({
									secondary: {
										psychologists: e.target.value
									}
								})}
								helperText={state.secondary.psychologistsError}
								error={state.secondary.psychologistsError !== ''}
							>
								{
									[...Array(21).keys()].map((num, i) => {
										return (
											<MenuItem key={`Psychologists-${i}`} value={num}>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
						</Grid>

						<Grid item lg={6} md={12}>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Psychiatrist Nurses
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='secondaryPsychiatristNurses'
								name='secondaryPsychiatristNurses'
								value={state.secondary.psychiatristNurses}
								onChange={e => changeState({
									secondary: {
										psychiatristNurses: e.target.value
									}
								})}
								helperText={state.secondary.psychiatristNursesError}
								error={state.secondary.psychiatristNursesError !== ''}
							>
								{
									[...Array(21).keys()].map((num, i) => {
										return (
											<MenuItem key={`psychiatristNurses-${i}`} value={num}>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
						</Grid>
					</Grid>
				</FormControl>
			</form>

			<Typography
				variant='subtitle1'
				style={{
					color: '#38364c',
					fontSize: '1.5rem',
					fontWeight: 'bold',
					alignSelf: 'flex-start',
					marginLeft: '1rem',
					textTransform: 'uppercase'
				}}
			>
				Tertiary Care Hospital
			</Typography>

			<form className={classes.form}>

				<FormControl
					component='fieldset'
					className={classes.formControl}
					fullWidth
					style={{
						backgroundColor: "#EAEAEA",
						border: '2px solid #cec9de',
						borderRadius: '7px',
					}}
				>

					<Grid
						container
						row
						spacing={2}
						className={classes.dropDown}
					>
						<Grid item lg={6} md={12}>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Psychiatrist
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='tertiaryPsychiatrists'
								name='tertiaryPsychiatrists'
								value={state.tertiary.psychiatrists}
								onChange={e => changeState({
									tertiary: {
										psychiatrists: e.target.value
									}
								})}
								helperText={state.tertiary.psychiatristsError}
								error={state.tertiary.psychiatristsError !== ''}
							>
								{
									[...Array(21).keys()].map((num, i) => {
										return (
											<MenuItem key={`tertiaryPsychiatrists-${i}`} value={num}>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
						</Grid>

						<Grid item lg={6} md={12}>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Medical Officers
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='tertiaryMedicalOfficers'
								name='tertiaryMedicalOfficers'
								value={state.tertiary.medicalOfficers}
								onChange={e => changeState({
									tertiary: {
										medicalOfficers: e.target.value
									}
								})}
								helperText={state.tertiary.medicalOfficersError}
								error={state.tertiary.medicalOfficersError !== ''}
							>
								{
									[...Array(21).keys()].map((num, i) => {
										return (
											<MenuItem key={`tertiaryMedicalOfficers-${i}`} value={num}>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
						</Grid>
					</Grid>

					<Grid
						container
						row
						spacing={2}
						className={classes.dropDown}
					>
						<Grid item lg={6} md={12}>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Psychologists
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='tertiaryPsychologists'
								name='tertiaryPsychologists'
								value={state.tertiary.psychologists}
								onChange={e => changeState({
									tertiary: {
										psychologists: e.target.value
									}
								})}
								helperText={state.tertiary.psychologistsError}
								error={state.tertiary.psychologistsError !== ''}
							>
								{
									[...Array(21).keys()].map((num, i) => {
										return (
											<MenuItem key={`tertiaryPsychologists-${i}`} value={num}>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
						</Grid>

						<Grid item lg={6} md={12}>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Psychiatrist Nurses
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='tertiaryPsychiatristNurses'
								name='tertiaryPsychiatristNurses'
								value={state.tertiary.psychiatristNurses}
								onChange={e => changeState({
									tertiary: {
										psychiatristNurses: e.target.value
									}
								})}
								helperText={state.tertiary.psychiatristNursesError}
								error={state.tertiary.psychiatristNursesError !== ''}
							>
								{
									[...Array(21).keys()].map((num, i) => {
										return (
											<MenuItem key={`tertiaryPsychiatristNurses-${i}`} value={num}>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
						</Grid>
					</Grid>

				</FormControl>
			</form>

			<Typography
				variant='subtitle1'
				style={{
					color: '#38364c',
					fontSize: '1.5rem',
					fontWeight: 'bold',
					alignSelf: 'flex-start',
					marginLeft: '1rem',
					textTransform: 'uppercase'
				}}
			>
				Tertiary Care &amp; Teaching Hospital
			</Typography>

			<form className={classes.form}>

				<FormControl
					component='fieldset'
					className={classes.formControl}
					fullWidth
					style={{
						backgroundColor: "#EAEAEA",
						border: '2px solid #cec9de',
						borderRadius: '7px',
					}}
				>

					<Grid
						container
						row
						spacing={2}
						className={classes.dropDown}
					>

						<Grid item lg={6} md={12}>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Psychiatrist (Faculty)
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='tertiaryTeachingsPsychiatrists'
								name='tertiaryTeachingsPsychiatrists'
								value={state.tertiaryTeaching.psychiatrists}
								onChange={e => changeState({
									tertiaryTeaching: {
										psychiatrists: e.target.value
									}
								})}
								helperText={state.tertiaryTeaching.psychiatristsError}
								error={state.tertiaryTeaching.psychiatristsError !== ''}
							>
								{
									[...Array(21).keys()].map((num, i) => {
										return (
											<MenuItem key={`tertiaryTeachingsPsychiatrists-${i}`} value={num}>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
						</Grid>

						<Grid item lg={6} md={12}>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Medical Officers
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='tertiaryMedicalOfficers'
								name='tertiaryMedicalOfficers'
								value={state.tertiaryTeaching.medicalOfficers}
								onChange={e => changeState({
									tertiaryTeaching: {
										medicalOfficers: e.target.value
									}
								})}
								helperText={state.tertiaryTeaching.medicalOfficersError}
								error={state.tertiaryTeaching.medicalOfficersError !== ''}
							>
								{
									[...Array(21).keys()].map((num, i) => {
										return (
											<MenuItem key={`tertiaryMedicalOfficers-${i}`} value={num}>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
						</Grid>

						<Grid item lg={6} md={12}>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Psychiatrist (Students)
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='tertiaryPsychiatristStudents'
								name='tertiaryPsychiatristStudents'
								value={state.tertiaryTeaching.psychiatristStudents}
								onChange={e => changeState({
									tertiaryTeaching: {
										psychiatristStudents: e.target.value
									}
								})}
								helperText={state.tertiaryTeaching.psychiatristStudentsError}
								error={state.tertiaryTeaching.psychiatristStudentsError !== ''}
							>
								{
									[...Array(21).keys()].map((num, i) => {
										return (
											<MenuItem key={`tertiaryPsychiatristStudents-${i}`} value={num}>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
						</Grid>

						<Grid item lg={6} md={12}>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Psychiatrist Nurses
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='tertiaryTeachingPsychiatristNurses'
								name='tertiaryTeachingPsychiatristNurses'
								value={state.tertiaryTeaching.psychiatristNurses}
								onChange={e => changeState({
									tertiaryTeaching: {
										psychiatristNurses: e.target.value
									}
								})}
								helperText={state.tertiaryTeaching.psychiatristNursesError}
								error={state.tertiaryTeaching.psychiatristNursesError !== ''}
							>
								{
									[...Array(21).keys()].map((num, i) => {
										return (
											<MenuItem
												key={`tertiaryTeachingPsychiatristNurses-${i}`}
												value={num}
											>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
						</Grid>

						<Grid item lg={6} md={12}>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Psychologists
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='tertiaryTeachingPsychologists'
								name='tertiaryTeachingPsychologists'
								value={state.tertiaryTeaching.psychologists}
								onChange={e => changeState({
									tertiaryTeaching: {
										psychologists: e.target.value
									}
								})}
								helperText={state.tertiaryTeaching.psychologistsError}
								error={state.tertiaryTeaching.psychologistsError !== ''}
							>
								{
									[...Array(21).keys()].map((num, i) => {
										return (
											<MenuItem key={`tertiaryTeachingPsychologists-${i}`} value={num}>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
						</Grid>

						<Grid item lg={6} md={12}>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Psychologists (Students)
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='tertiaryTeachingPsychologistsStudents'
								name='tertiaryTeachingPsychologistsStudents'
								value={state.tertiaryTeaching.psychologistStudents}
								onChange={e => changeState({
									tertiaryTeaching: {
										psychologistStudents: e.target.value
									}
								})}
								helperText={state.tertiaryTeaching.psychologistStudentsError}
								error={state.tertiaryTeaching.psychologistStudentsError !== ''}
							>
								{
									[...Array(21).keys()].map((num, i) => {
										return (
											<MenuItem
												key={`tertiaryTeachingPsychologistsStudents-${i}`}
												value={num}
											>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
						</Grid>
					</Grid>
				</FormControl>
			</form>


			{
				state.isTraining && (
					<form className={classes.form}>
						<FormControl
							component='fieldset'
							className={classes.formControl}
							fullWidth
							style={{
								backgroundColor: "#EAEAEA",
								border: '2px solid #cec9de',
								borderRadius: '7px',
							}}
						>

							<Typography
								variant='subtitle1'
								className={classes.mapFormLabel}
							>
								Training *
							</Typography>

							<Grid
								container
								className={classes.checkboxContainer}
							>
								<Grid item lg={12}>
									<Typography>
										More than one field can be ticked
									</Typography>
								</Grid>

								<Grid
									item
									lg={12}
									className={classes.checkboxes}
									style={{
										justifyContent: 'space-between'
									}}
								>
									<FormControlLabel
										control={
											<Checkbox
												name='Undergraduate'
												color='primary'
												checked={state.isUndergraduate}
												onChange={e => changeState({ isUndergraduate: e.target.checked })}
											/>
										}
										label='Undergraduate'
										classes={{
											label: clsx(classes.uppercase, classes.bold)
										}}
									/>
									<FormControlLabel
										control={
											<Checkbox
												name='isPostGraduate'
												color='primary'
												checked={state.isPostGraduate}
												onChange={e => changeState({ isPostGraduate: e.target.checked })}
											/>
										}
										label='Post-graduate'
										classes={{
											label: clsx(classes.uppercase, classes.bold)
										}}
									/>
									<FormControlLabel
										control={
											<Checkbox
												name='isSpecialists'
												color='primary'
												checked={state.isSpecialists}
												onChange={e => changeState({ isSpecialists: e.target.checked })}
											/>
										}
										label='Non-specialist healthcare workers'
										classes={{
											label: clsx(classes.uppercase, classes.bold)
										}}
									/>
									<FormControlLabel
										control={
											<Checkbox
												name='General population / community'
												color='primary'
												checked={state.isGeneral}
												onChange={e => changeState({ isGeneral: e.target.checked })}
											/>
										}
										label='General population / community'
										classes={{
											label: clsx(classes.uppercase, classes.bold)
										}}
									/>
								</Grid>

								{
									state.categoryError &&
									<Grid item lg={12}>
										<Typography color='error'>
											{state.categoryError}
										</Typography>
									</Grid>
								}
							</Grid>
						</FormControl>
					</form>
				)
			}

			{
				state.isResearch && (
					<form className={classes.form}>
						<FormControl
							component='fieldset'
							className={classes.formControl}
							fullWidth
							style={{
								backgroundColor: "#EAEAEA",
								border: '2px solid #cec9de',
								borderRadius: '7px',
							}}
						>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Research
							</Typography>

							<TextField
								className={classes.formField}
								InputProps={{
									inputProps: {
										maxLength: 250,
									}
								}}
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='research'
								name='research'
								placeholder='Briefly describe your organization (200 characters)'
								multiline
								rows={10}
								style={{
									marginBottom: '2rem',
									border: '2px solid #cec9de',
									borderRadius: '7px'
								}}
								value={state.research}
								onChange={e => changeState({ research: e.target.value })}
								helperText={state.researchError}
								error={state.researchError !== ''}
							/>
						</FormControl>
					</form>
				)
			}

			{
				state.isOthers && (
					<form className={classes.form}>
						<FormControl
							component='fieldset'
							className={classes.formControl}
							fullWidth
							style={{
								backgroundColor: "#EAEAEA",
								border: '2px solid #cec9de',
								borderRadius: '7px',
							}}
						>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550
								}}
							>
								Others
							</Typography>

							<TextField
								className={classes.formField}
								InputProps={{
									inputProps: {
										maxLength: 250,
									}
								}}
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='other'
								name='other'
								placeholder='For example advocacy, policy, legislation. Please summarise the work of your organization in 200 characters'
								multiline
								rows={10}
								style={{
									marginBottom: '2rem',
									border: '2px solid #cec9de',
									borderRadius: '7px'
								}}
								value={state.other}
								onChange={e => changeState({ other: e.target.value })}
								helperText={state.otherError}
								error={state.otherError !== ''}
							/>
						</FormControl>
					</form>
				)
			}

			<Container className={classes.containerBetween}>
				<Button
					style={{
						backgroundColor: '#59567f',
						width: '100px',
						display: 'flex',
						fontWeight: 900
					}}
					variant='contained'
					color='primary'
					className={classes.submit}
					onClick={prevStep}
				>
					Back
				</Button>

				<Button
					style={{
						backgroundColor: '#59567f',
						width: '100px',
						display: 'flex',
						fontWeight: 900
					}}
					variant='contained'
					color='primary'
					className={classes.submit}
					onClick={clickRegister}
				>
					{
						loading ?
							<CircularProgress color="inherit" size={24} />
							:
							'Register'
					}
				</Button>
			</Container>
		</>
	)
}

export default Step2;

import { Button } from '@material-ui/core';
import ImportExportOutlinedIcon from '@material-ui/icons/ImportExportOutlined';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { routes } from '../../../Auth/AuthApi';


const HamdardToolbar = () => {
	return (
		<GridToolbarContainer>
			<Button
				variant="text"
				color="primary"
				size="small"
				startIcon={
					<ImportExportOutlinedIcon />
				}
				onClick={() => window.open(routes.get_mhws_csv)}
			>
				EXPORT
			</Button>
		</GridToolbarContainer>
	)
};

export default HamdardToolbar;

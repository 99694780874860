import { Button, Container } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from 'react-router-dom';
import { getAuthData } from "../../services/AuthStorage";


const Home = ({ classes }) => {
	const history = useHistory();
	const [authData] = useState(getAuthData());


	useEffect(() => {
		if (!authData || Object.keys(authData).length === 0) {
			history.push('/hamdard/login');
		}
	}, [history, authData]);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Home</title>
				<meta
					name='description'
					content='users homepage'
				/>
			</Helmet>

			<Container component='main' maxWidth='sm'>
				<div
					className={classes.paper}
				>
					<Button
						style={{
							backgroundColor: '#9487bc',
							color: '#fafafa',
							width: '14rem',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							margin: '0 auto',
							marginTop: '2rem'
						}}
						size="large"
						className={classes.uppercase}
						component={Link}
						to='/home/cases'
					>
						View Assigned Cases
					</Button>

					<Button
						style={{
							backgroundColor: '#9487bc',
							color: '#fafafa',
							width: '14rem',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							margin: '0 auto',
							marginTop: '2rem'
						}}
						size="large"
						className={classes.uppercase}
						href='https://lms.mhpss.pk/login/index.php'
						target='_blank'
					>
						Go to courses
					</Button>

				</div>
			</Container>
		</>
	)
};

export default Home;

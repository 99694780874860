import { FormControl, TextField, Typography } from '@material-ui/core';


const Clinic = ({ classes, state, changeState }) => {
	return (
		<FormControl
			component='fieldset'
			className={classes.formControl}
			fullWidth
			style={{
				backgroundColor: "#EAEAEA",
				border: '2px solid #cec9de',
				borderRadius: '7px',
			}}
		>

			<Typography
				variant='subtitle1'
				className={classes.mapFormLabel}
			>
				Name of the Clinic *
			</Typography>

			<TextField
				className={classes.formField}
				InputProps={{
					inputProps: {
						maxLength: 100,
					}
				}}
				variant='outlined'
				margin='normal'
				required
				fullWidth
				id='name'
				name='name'
				autoComplete='name'
				value={state.name}
				onChange={e => changeState({ name: e.target.value })}
				error={state.nameError !== ''}
				helperText={state.nameError}
			/>

			<Typography
				variant='subtitle1'
				className={classes.mapFormLabel}
			>
				Address of the Clinic *
			</Typography>

			<TextField
				className={classes.formField}
				InputProps={{
					inputProps: {
						maxLength: 100,
					}
				}}
				variant='outlined'
				margin='normal'
				required
				fullWidth
				id='address'
				name='address'
				autoComplete='address'
				value={state.address}
				onChange={e => changeState({ address: e.target.value })}
				error={state.addressError !== ''}
				helperText={state.addressError}
			/>

		</FormControl>
	);
};

export default Clinic;

import { Button, Typography, makeStyles, useTheme } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const useStyle = makeStyles((theme) => ({
  note: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "620px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    fontSize: "14px",
    color: "#5D616D",
    margin: "2rem 0rem",
  },
  success: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "720px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      textAlign: "start",
    },
    textAlign: "center",
    fontSize: "16px",
  },
}));

const Success = () => {
  const theme = useTheme();
  const classes = useStyle(theme);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        component="p"
        // style={{
        //   fontSize: "16px",
        //   textAlign: "center",
        //   fontWeight: "500",
        // }}
        className={classes.success}
      >
        Your request has been submitted for processing. You will receive an
        email when your data is deleted from the system.
      </Typography>
      <Typography component="p" className={classes.note}>
        <strong>Note: </strong>If you are registered as part of our mental
        health team (trained as Hamdard Force or in mhGAP) and you have
        submitted case(s), please note that the case data will not be deleted.
      </Typography>
      <Button
        style={{
          backgroundColor: " #59567f",
          color: "#fafafa",
          fontSize: "0.8rem",
        }}
        component={Link}
        to="/"
      >
        Go back to Home Page
      </Button>
    </div>
  );
};

export default Success;

import React, { useState, useEffect } from 'react'
import { Container, Grid, Typography, FormControl, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Logo from "../img/Logo.png";
import ministryLogo from "../img/ministry-logo.png";
import unicef from "../img/unicef.png";
import watermark from '../img/watermark.png';
import { routes, makeRequest } from './AuthApi';

const useStyle= makeStyles(theme => ({
	main: {
		marginTop: theme.spacing(4)
	},
	heading: {
		textAlign: 'center',
		backgroundColor: '#EAEAEA',
		fontWeight: 'bold',
		fontSize: '1.4rem',
		padding: '5px 0 5px 0',
		marginBottom: theme.spacing(2),
		justifyContent: 'center',
	},
	uppercase: {
		textTransform: 'uppercase'
	},
	watermark: {
		backgroundImage: `url(${watermark})`,
		backgroundRepeat: 'no-repeat',
		backgroundColor: 'transparent',
		backgroundPosition: 'center',
		padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
		backgroundSize: 'contained',
	},
	topHeader: {
		textAlign: "center",
		padding: '10',
		background: "#eaeaea",
		color: "#383838",
		fontSize: '14',
		fontFamily: "Arial",
		fontWeight: '400'
	},
	right: {
		textAlign: "right",
		fontFamily: "Arial",
		[theme.breakpoints.down('sm')]: {
			alignItems:'center'

		},
		[theme.breakpoints.down('xs')]: {
			alignItems:'center'

		},
		[theme.breakpoints.down('lg')]: {
			alignItems:'center'

		},
		[theme.breakpoints.down('md')]: {
			alignItems:'center'

		}
	},
	center: {
		textAlign: "center",
		fontFamily: "Arial",
	},
	logo: {
		width: '7rem',
		height: '7rem',
		objectFit: "contain",
		display: 'flex',
		margin: 'auto',
	},
	textField: {
		backgroundColor: '#fafafa',
		border: '2px solid #cec9de',
		borderRadius: '6px'
	},
	root: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '2rem',
		backgroundColor: '#eaeaea',
		border: '2px solid #cec9de',
		borderRadius: '7px',
		paddingBottom: '2rem',
		width: '50%',
		margin: '0 auto'
		// paddingRight: 0,
		// paddingLeft: 0,

	},
	label:{
		color: '#07D5C2',
		padding: '0.5rem',
		fontSize: '1rem',
		fontWeight: 'bold',
		textAlign: 'left',
		textTransform: 'uppercase'
	},
}))


const useQuery = () => new URLSearchParams(useLocation().search);

const ResetPassword = () => {
	const classes= useStyle();
	const query = useQuery();
	const history = useHistory();
	const [success, setSuccess] = useState(false);
	const [passwordState, setPasswordState] = useState({
		password: '',
		passwordConfirmation: '',
		passwordError: '',
		confirmationError: '',
		reset_password_token: query.get('reset_password_token')
	});

	const validatePassword = val => {
		let error = '';

		if (val) {
			if (val.length < 8) {
				error = `is too short (minimum is ${8} characters)`;
			}
		}
		else {
			error = 'Password cannot be blank.';
		}

		setPasswordState(prev => {
			return {
				...prev,
				password: val,
				passwordError: error
			}
		});

		return error === '';
	}

	const validateConfirmation = val => {
		let error = '';
		if (!val) error = 'Please enter password again for confirmation';
		else if (passwordState.password !== val) error = 'Password does not match.';

		setPasswordState(prev => {
			return {
				...prev,
				passwordConfirmation: val,
				confirmationError: error
			}
		});
		return error === '';
	};

	const handleReset = async () => {
		if (
			validatePassword(passwordState.password) &&
			validateConfirmation(passwordState.passwordConfirmation)
		) {
			const res = await makeRequest(
				`${routes.change_password}?reset_password_token=${passwordState.reset_password_token}`,
				{
					password: passwordState.password,
					password_confirmation: passwordState.passwordConfirmation
				},
				'PUT'
			);

			if (res.ok) {
				setSuccess(true);
			}
			else {
				alert('Error');
			}
		}
	};

	useEffect(() => {
		if (!passwordState.reset_password_token) {
			history.push('/');
		}
	}, [history, passwordState.reset_password_token]);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Forgot Password</title>
				<meta
					name='forgot password'
					content='Forgot Password'
				/>
			</Helmet>

			<Grid
				container
				style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}
				direction="row"
				justifyContent="center"
				alignItems="center"
			>
				<img src={Logo} alt="logo" style={{ width: 120 }} className={classes.logo} />
			</Grid>
			<Grid
				className={classes.topHeader}
				container
				direction="row"
				justifyContent="space-between"
				alignItems="center" >
				<Grid item xs={0} lg={1} sm={0}></Grid>
				<Grid item xs={12} lg={2} sm={3}>
					<img src={ministryLogo} alt="logo" className={classes.logo} />
				</Grid>

				<Grid
					item
					xs={12}
					lg={5}
					sm={6}
					className={classes.center}
					style={{ fontSize: '1rem', fontFamily: 'Arial', fontWeight: 700, }}
				>
					Mental Health &amp; Psychosocial support <br />
					A Project of Planning, Development &amp; Special Initiatives <br />
					Government of Pakistan
				</Grid>

				<Grid
					item
					xs={12}
					lg={2}
					sm={3}
					className={classes.right}
				>
					<img src={unicef} alt="logo" className={classes.logo} style={{}} />
				</Grid>
				<Grid item xs={0} lg={1} sm={0}></Grid>
			</Grid>

			{
				success ?
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							marginTop: '2rem'
						}}
					>
						<Typography
							component='h1'
							variant='h5'
							className={`${classes.heading} ${classes.uppercase}`}
						>
							Your password has been changed successfully.
						</Typography>
						<Button
							style={{
								backgroundColor: ' #59567f',
								color: '#fafafa',
								fontSize: '0.8rem',
							}}
							component={Link}
							to='/'
						>
							Go back to Home Page
						</Button>
					</div>
					:
					<Container
						component='main'
						className={classes.main}
						style={{ marginBottom: '2rem' }}
					>
						<Typography
							component='h1'
							variant='h5'
							className={`${classes.heading} ${classes.uppercase}`}
							style={{
								// fontSize: '1rem',
								width: '50%',
								margin: '0 auto',
							}}
						>
							Change Password
						</Typography>

						<form className={classes.root}>
							<FormControl style={{ width: '100%', padding: '0rem' }}>
								<Typography
									variant='h6'
									className={classes.label}
								>
									New Password
								</Typography>

								<TextField
									className={classes.textField}
									variant='outlined'
									margin='normal'
									required
									fullWidth
									name='password'
									type='password'
									id='password'
									autoComplete='password'
									value={passwordState.password}
									onChange={e => validatePassword(e.target.value)}
									error={passwordState.passwordError !== ''}
									helperText={passwordState.passwordError}
								/>

								<Typography
									variant='h6'
									className={classes.label}
								>
									Confirm New Password
								</Typography>

								<TextField
									className={classes.textField}
									variant='outlined'
									margin='normal'
									required
									fullWidth
									name='password'
									type='password'
									id='password'
									autoComplete='password'
									value={passwordState.passwordConfirmation}
									onChange={e => validateConfirmation(e.target.value)}
									error={passwordState.confirmationError !== ''}
									helperText={passwordState.confirmationError}
								/>

							</FormControl>
						</form>

						<br />
						<br />
						<Button
							style={{
								backgroundColor: 'rgb(56, 54, 76)',
								color: '#fafafa',
								fontSize: '0.875rem',
								width: '11rem',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								margin: '0 auto',
							}}
							onClick={handleReset}
						>
							Change my Password
						</Button>

					</Container>
			}

		</>
	)
};

export default ResetPassword

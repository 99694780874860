import { FormControl, MenuItem, TextField, Typography, Container } from '@material-ui/core';

const Hospital = ({
	classes,
	state,
	changeState,
	positionOptions,
	typesOptions,
	academicOptions,
	nonAcademicOptions
}) => {
	return (
		<form className={classes.form}>

			<FormControl
				component='fieldset'
				className={classes.formControl}
				fullWidth
				style={{
					backgroundColor: "#EAEAEA",
					border: '2px solid #cec9de',
					borderRadius: '7px',
				}}
			>

				<Typography
					variant='subtitle1'
					className={classes.mapFormLabel}
				>
					Type of Hospital *
				</Typography>

				<TextField
					className={classes.formField}
					select
					variant='outlined'
					margin='normal'
					required
					fullWidth
					id='type'
					name='type'
					value={state.type}
					onChange={e => changeState({ type: e.target.value })}
					error={state.typeError !== ''}
					helperText={state.typeError}
				>
					{
						typesOptions.map((option, i) => (
							<MenuItem key={`type-${i}`} value={option}>
								{option}
							</MenuItem>
						))
					}
				</TextField>

				<Typography
					variant='subtitle1'
					className={classes.mapFormLabel}
				>
					Position *
				</Typography>

				<Container
					style={{
						display: 'flex',
						padding: 0,
						justifyContent: 'space-between',
						gap: '1rem'
					}}
				>

					<TextField
						className={classes.formField}
						select
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='position'
						name='position'
						value={state.position}
						onChange={e => changeState({ position: e.target.value })}
						error={state.positionError !== ''}
						helperText={state.positionError}
					>
						{
							positionOptions.map((option, i) => (
								<MenuItem key={`position-${i}`} value={option}>
									{option}
								</MenuItem>
							))
						}
					</TextField>

					<TextField
						className={classes.formField}
						select
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='positionSpec'
						name='positionSpec'
						value={state.positionSpec}
						onChange={e => changeState({ positionSpec: e.target.value })}
						error={state.positionSpecError !== ''}
						helperText={state.positionSpecError}
					>
						{
							(state.position === 'Academic' ? academicOptions : nonAcademicOptions)
								.map((option, i) => (
									<MenuItem key={`position-spec-${i}`} value={option}>
										{option}
									</MenuItem>
								))
						}
					</TextField>
				</Container>

				<Typography
					variant='subtitle1'
					className={classes.mapFormLabel}
				>
					Name of the Hospital *
				</Typography>

				<TextField
					className={classes.formField}
					InputProps={{
						inputProps: {
							maxLength: 100,
						}
					}}
					variant='outlined'
					margin='normal'
					required
					fullWidth
					id='name'
					name='name'
					autoComplete='name'
					value={state.name}
					onChange={e => changeState({ name: e.target.value })}
					error={state.nameError !== ''}
					helperText={state.nameError}
				/>

				<Typography
					variant='subtitle1'
					className={classes.mapFormLabel}
				>
					Address of the Hospital *
				</Typography>

				<TextField
					className={classes.formField}
					InputProps={{
						inputProps: {
							maxLength: 100,
						}
					}}
					variant='outlined'
					margin='normal'
					required
					fullWidth
					id='address'
					name='address'
					autoComplete='address'
					value={state.address}
					onChange={e => changeState({ address: e.target.value })}
					error={state.addressError !== ''}
					helperText={state.addressError}
				/>
			</FormControl>

		</form>
	);
};

export default Hospital;

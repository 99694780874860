import toast from "react-hot-toast";

import { makeAuthRequest, routes } from "../Auth/AuthApi";
import { getAuthData } from "../services/AuthStorage";

/**
 * Deletes a case by its ID.
 *
 * @param {string} id - The ID of the case to be deleted.
 * @param {Function} onDelete - The callback function to be called after the case is deleted.
 * @returns {Promise<void>} - A promise that resolves when the case is deleted successfully.
 */
export const deleteCaseById = async (id, onDelete) => {
  try {
    const authData = getAuthData();
    const response = await makeAuthRequest(
      `${routes.delete_case}/${id}`,
      {},
      "DELETE",
      authData
    );
    if (response.ok) {
      onDelete(id);
      toast(`Case has been deleted successfully.`);
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    console.error("DELETE CASE ERROR: ", error);
    toast.error(error.message ?? error);
  }
};

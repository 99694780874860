import React from 'react'
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';
import Logo from "../img/Logo.png";
import ministryLogo from "../img/ministry-logo.png";
import unicef from "../img/unicef.png";
import watermark from '../img/watermark.png';


const useStyle= makeStyles(theme => ({
	main: {
		marginTop: theme.spacing(4)
	},
	heading: {
		textAlign: 'center',
		backgroundColor: '#EAEAEA',
		fontWeight: 'bold',
		fontSize: '1.4rem',
		padding: '5px 0 5px 0',
		marginBottom: theme.spacing(2),
		justifyContent: 'center',
	},
	uppercase: {
		textTransform: 'uppercase'
	},
	watermark: {
		backgroundImage: `url(${watermark})`,
		backgroundRepeat: 'no-repeat',
		backgroundColor: 'transparent',
		backgroundPosition: 'center',
		padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
		backgroundSize: 'contained',
	},
	topHeader: {
		textAlign: "center",
		padding: '10',
		background: "#eaeaea",
		color: "#383838",
		fontSize: '14',
		fontFamily: "Arial",
		fontWeight: '400'
	},
	right: {
		textAlign: "right",
		fontFamily: "Arial",
		[theme.breakpoints.down('sm')]: {
			alignItems:'center'

		},
		[theme.breakpoints.down('xs')]: {
			alignItems:'center'

		},
		[theme.breakpoints.down('lg')]: {
			alignItems:'center'

		},
		[theme.breakpoints.down('md')]: {
			alignItems:'center'

		}
	},
	center: {
		textAlign: "center",
		fontFamily: "Arial",
	},
	logo: {
		width: '7rem',
		height: '7rem',
		objectFit: "contain",
		display: 'flex',
		margin: 'auto',
	},
}))





const Support = () => {
	const classes= useStyle();
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Support</title>
				<meta
					name='support'
					content='Support'
				/>
			</Helmet>

			<Grid
				container
				style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}
				direction="row"
				justifyContent="center"
				alignItems="center"
			>
				<img src={Logo} alt="logo" style={{ width: 120 }} className={classes.logo} />
			</Grid>
			<Grid
				className={classes.topHeader}
				container
				direction="row"
				justifyContent="space-between"
				alignItems="center" >
				<Grid item xs={0} lg={1} sm={0}></Grid>
				<Grid item xs={12} lg={2} sm={3}>
					<img src={ministryLogo} alt="logo" className={classes.logo} />
				</Grid>

				<Grid
					item
					xs={12}
					lg={5}
					sm={6}
					className={classes.center}
					style={{ fontSize: '1rem', fontFamily: 'Arial', fontWeight: 700, }}
				>
					Mental Health &amp; Psychosocial Support <br />
					A Project of Planning, Development &amp; Special Initiatives <br />
					Government of Pakistan
				</Grid>

				<Grid
					item
					xs={12}
					lg={2}
					sm={3}
					className={classes.right}
				>
					<img src={unicef} alt="logo" className={classes.logo} style={{}} />
				</Grid>
				<Grid item xs={0} lg={1} sm={0}></Grid>
			</Grid>

			<Container
				component='main'
				className={classes.main}
			>
				<Typography
					component='h1'
					variant='h5'
					className={`${classes.heading} ${classes.uppercase}`}
				>
					Support
				</Typography>

				<Container
					className={classes.watermark}
				>

					<Typography
						component='div'
						variant='body1'
						className={classes.listBody}
						gutterBottom
					>
						Mental Health and Psychosocial Support(MHPSS) is an evidence-driven model which is right-based, scalable and sustainable.
						</Typography>
					<Typography
						component='div'
						variant='body1'
						className={classes.listBody}
						gutterBottom
					>
						A pilot project is being implemented in Islamabad Capital Territory to launch a helpline for MHPSS through intersectoral collaboration.
					</Typography>

					<Typography
						component='div'
						variant='body1'
						className={classes.listBody}
						gutterBottom
					>
						There are 3 Mobile apps that will be published under this project:
						<ol>
							<li>
								Hamdard Force
							</li>
							<li>
								My Care+
							</li>
							<li>
								mhGAP-PK
							</li>
						</ol>
					</Typography>

                    <Typography
						component='div'
						variant='body1'
						className={classes.listBody}
						gutterBottom
					>
						In case you face any issues while using these apps, please contact us on <a rel="noopener noreferrer" href = "mailto: support@mhpss.pk" target = '_blank'>support@mhpss.pk</a>
					</Typography>

				</Container>
			</Container>
		</>

	)
}

export default Support





import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../img/Logo.png";
import ministryLogo from "../img/ministry-logo.png";
import unicef from "../img/unicef.png";
import { makeRequest, routes } from "./AuthApi";
import BookAppointmentForm from "./BookAppointmentForm";
import {
  validateEmail,
  validateExists,
  validateMobile,
  validateName,
  validateNum,
} from "./FormParts/Validation";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
  },
  paperBackground: {
    backgroundColor: "#fafafa",
    border: "solid black",
    borderWidth: "0.15rem",
    borderRadius: "0.3rem",
  },
  heading: {
    textAlign: "center",
    backgroundColor: "#eaeaea",
    minWidth: "800px",
    fontWeight: "bold",
    fontSize: "1.4rem",
    padding: "5px 0 5px 0",
    marginBottom: theme.spacing(2),
  },
  topHeader: {
    textAlign: "center",
    padding: "10",
    background: "#eaeaea",
    color: "#383838",
    fontSize: "14",
    fontFamily: "Arial",
    fontWeight: "400",
  },
  logo: {
    width: "7rem",
    height: "7rem",
    objectFit: "contain",
    display: "flex",
    margin: "auto",
  },
  alert_appointment: {
    color: "#ff2301",
    textAlign: "left",
    textTransform: "uppercase",
    padding: "0.5rem",
    fontSize: "1rem",
    marginTop: "1.5rem",
  },
  alert_appointment_after: {
    backgroundColor: "#eaeaea",
    color: "#000000",
    textAlign: "left",
    textTransform: "uppercase",
    padding: "0.5rem",
    fontSize: "1.1rem",
    // marginTop: '1.5rem',
    marginBottom: "2rem",
    fontWeight: "bold",
  },
  center: {
    textAlign: "center",
    fontFamily: "Arial",
  },
  right: {
    textAlign: "right",
    fontFamily: "Arial",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("lg")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
}));

const BookAppointment = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: "",
    nameError: "",
    age: "",
    ageError: "",
    occupation: "",
    occupationError: "",
    phone_no: "",
    phoneNumberError: "",
    email: "",
    emailError: "",
    description: "",
    descriptionError: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const submit = async () => {
    const errors = changeState(values);
    if (
      !errors.nameError &&
      !errors.ageError &&
      !errors.occupationError &&
      !errors.emailError &&
      !errors.phoneNumberError &&
      !errors.descriptionError
    ) {
      setLoading(true);
      const res = await makeRequest(routes.book_appointment, values);
      if (res.ok) {
        setSubmitted(true);
      } else {
        alert("There was a problem!");
      }
    }
  };

  const changeState = (changeState) => {
    changeState.nameError = validateName(changeState.name);
    changeState.ageError = validateNum(changeState.age, 16, 70);
    changeState.emailError = validateEmail(changeState.email);
    changeState.phoneNumberError = validateMobile(changeState.phone_no);
    changeState.descriptionError = validateExists(changeState.description);
    changeState.occupationError = validateExists(changeState.occupation);

    setValues((prevState) => {
      return {
        ...prevState,
        ...changeState,
      };
    });

    return {
      nameError: changeState.nameError,
      ageError: changeState.ageError,
      emailError: changeState.emailError,
      phoneNumberError: changeState.phoneNumberError,
      descriptionError: changeState.descriptionError,
      occupationError: changeState.occupationError,
    };
  };

  return (
    <>
      <Grid
        container
        style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={Logo}
          alt="logo"
          style={{ width: 120 }}
          className={classes.logo}
        />
      </Grid>
      <Grid
        className={classes.topHeader}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={0} lg={1} sm={0}></Grid>
        <Grid item xs={12} lg={2} sm={3}>
          <img src={ministryLogo} alt="logo" className={classes.logo} />
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          sm={6}
          className={classes.center}
          style={{ fontSize: "1rem", fontFamily: "Arial", fontWeight: 700 }}
        >
          Mental Health &amp; Psychosocial Support <br />
          A Project of Planning, Development &amp; Special Initiatives <br />
          Government of Pakistan
        </Grid>

        <Grid item xs={12} lg={2} sm={3} className={classes.right}>
          <img src={unicef} alt="logo" className={classes.logo} style={{}} />
        </Grid>
        <Grid item xs={0} lg={1} sm={0}></Grid>
      </Grid>
      <div className={classes.paper}>
        {submitted ? (
          <>
            {/* <Typography
						component='h1'
						variant='h5'
						className={`${classes.heading} ${classes.uppercase}`}
					>
						Your appointment has been booked, thank you.
					</Typography> */}
            <Typography
              variant="subtitle1"
              className={classes.alert_appointment_after}
            >
              Your request has been noted. Our team will contact you within
              three working days.
            </Typography>
            <Button
              style={{
                backgroundColor: " #59567f",
                color: "#fafafa",
                fontSize: "0.8rem",
              }}
              component={Link}
              to="/"
            >
              Go back to Home Page
            </Button>
          </>
        ) : (
          <>
            <Typography
              component="h1"
              variant="h5"
              className={`${classes.heading} ${classes.uppercase}`}
              style={{
                color: "#59567f",
                textTransform: "uppercase",
                fontWeight: 900,
              }}
            >
              BOOK YOUR APPOINTMENT
            </Typography>

            <BookAppointmentForm
              values={values}
              setValues={changeState}
              submit={submit}
              loading={loading}
            />
          </>
        )}
      </div>
    </>
  );
};

export default BookAppointment;

import React from 'react';
import { Avatar, Container, Typography } from "@material-ui/core";
import { Helmet } from 'react-helmet-async';
import logo from '../../img/mhpss.png';


const EvidenceBased = ({ classes }) => {

	return (
		<Container component='main' className={classes.criteriaPage}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Reference</title>
				<meta
					name='description'
					content='evidence-based'
				/>
			</Helmet>

			<Avatar
				className={`${classes.avatar} ${classes.pageLogo}`}
				alt='logo'
				src={logo}
			/>

			<Container className={classes.watermark}>

            <Typography
					id='hamdard-force'
					component='h3'
					variant='h5'
					gutterBottom
				>
					References
				</Typography>

				<Typography
					component='div'
					variant='body1'
					className={classes.listBody}
					gutterBottom
				>
					<ol>
						<li>Humayun, A., Haq, I., Khan, F. R., & Nasir, S. (2020). Designing Psychosocial Support for COVID-19 Frontline Responders in Pakistan: A Potentially Scalable Self-Help Plus Blueprint for LMICs. Intervention, 18 (2), 150-158</li>
						<li>World Health Organization. “Mental Health and Psychosocial Support.” Geneva, WHO, 2020. www.emro.who.int/mhps/index.html</li>
						<li>
                        Hayat K, Arshed M, Fiaz I, et al. Impact of COVID-19 on the Mental Health of Healthcare Workers: A Cross-Sectional Study from Pakistan. Front Public Health. 2021;9: 603602. Published 2021 Apr 26. doi:10.3389/fpubh.2021.603602
						</li>
						<li>Inter- agency standing committee, World Health Organization. Basic Psychosocial Skills: A Guide for COVID-19 Responders, Geneva, WHO, 2020 </li>
						<li>World Health Organization and United Nations High Commissioner for Refugees. mhGAP Humanitarian Intervention Guide (mhGAP-HIG): Clinical management of mental, neurological and substance use conditions in humanitarian emergencies. Geneva, WHO, 2015 </li>
						<li>Lorenz L, Bachem RC, Maercker A. The Adjustment Disorder--New Module 20 as a Screening Instrument: Cluster Analysis and Cut-off Values. Int J Occup Environ Med. 2016;7(4):215-220 </li>
						<li>Noone PA. The Holmes-Rahe Stress Inventory. Occup Med (Lond). 2017;67(7):581-582</li>
					</ol>
				</Typography>
			</Container>
		</Container>
	);
};

export default EvidenceBased;

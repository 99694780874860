import React from 'react';
import {
	Button, FormControl,
	MenuItem, TextField, Typography
} from '@material-ui/core';

import { validateNotBlank, validateOptions } from './Validation';


const MentalHealthwDetails = ({
	classes, nextStep,
	detailsState, setDetailsState,
	degrees, degrees_inter,occupation, qualification
}) => {

	const changeState = (changeState) => {

		changeState.primary_educationError = validateOptions(changeState.primary_education, degrees);

		changeState.secondary_educationError = validateOptions(changeState.secondary_education, degrees_inter);

		changeState.occupationError = validateOptions(changeState.occupation, occupation);

		changeState.otheroccupationError = validateNotBlank(changeState.otheroccupation);

		changeState.masters_degreeError = validateNotBlank(changeState.masters_degree);

		changeState.graduation_degreeError = validateNotBlank(changeState.graduation_degree);

		changeState.workstudyNameError = validateNotBlank(changeState.workstudyName);

		setDetailsState(prevState => {
			return {
				...prevState,
				...changeState,
			}
		});

		return {
			workstudyNameError: changeState.workstudyNameError,
			graduation_degreeError: changeState.graduation_degreeError,
			masters_degreeError: changeState.masters_degreeError,
			primary_educationError: changeState.primary_educationError,
			secondary_educationError: changeState.secondary_educationError,
			occupationError: changeState.occupationError,
			otheroccupationError: changeState.otheroccupationError,
		};
	};

	const validateOtherState = errors => {
		return (
			!errors.occupationError &&
			!errors.qualificationError &&
			(
				detailsState.occupation !== 'Others' ||
				!errors.otheroccupationError
			) &&
			(
				detailsState.qualification !== 'Masters' ||
				!errors.masters_degreeError
			) &&
			(
				detailsState.qualification !== 'Graduation' ||
				!errors.graduation_degreeError
			) &&
			(
				detailsState.qualification !== 'O Level/Matric' ||
				!errors.primary_educationError
			) &&
			(
				detailsState.qualification !== 'A Level/FSc/FA/Diploma' ||
				!errors.secondary_educationError
			) &&
			!errors.workstudyNameError
		);
	};

	const validateNextStep = () => {
		if (validateOtherState(changeState(detailsState))) nextStep();
	};

	return (
		<>
			<form className={classes.form} noValidate>
				{
					<>
						<FormControl
							component='fieldset'
							className={classes.formControl}
							fullWidth
							style={{
								backgroundColor: "#EAEAEA",
								border: '2px solid black',
								borderRadius: '7px'
							}}
						>
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
							>
								EDUCATION
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								id='qualification'
								name='qualification'
								required
								fullWidth
								value={detailsState.qualification}
								error={detailsState.qualificationError !== ''}
								helperText={detailsState.qualificationError}
								onChange={e => {
									changeState({
										qualification: e.target.value,
									});
								}}
							>
								{
									qualification.map((degree, i) => {
										return (
											<MenuItem value={degree} key={i}>
												{degree}
											</MenuItem>
										)
									})
								}
							</TextField>

							{
								detailsState.qualification === 'Masters' &&
								<TextField
									className={classes.formField}
									InputProps={{
										inputProps: {
											maxLength: 100,
										}
									}}
									id='masters_degree'
									name='masters_degree'
									variant='outlined'
									margin='normal'
									fullWidth
									required
									value={detailsState.masters_degree}
									error={detailsState.masters_degreeError !== ''}
									helperText={detailsState.masters_degreeError}
									onChange={e => changeState({ masters_degree: e.target.value })}
								/>
							}
							{
								detailsState.qualification === 'Graduation' &&
								<TextField
									className={classes.formField}
									InputProps={{
										inputProps: {
											maxLength: 100,
										}
									}}
									id='other_graduation'
									name='other_graduation'
									autoComplete='other_graduation'
									variant='outlined'
									margin='normal'
									required
									fullWidth
									value={detailsState.graduation_degree}
									error={detailsState.graduation_degreeError !== ''}
									helperText={detailsState.graduation_degreeError}
									onChange={e => changeState({ graduation_degree: e.target.value })}
								/>
							}
							{
								detailsState.qualification === 'O Level/Matric' &&
								<TextField
									className={classes.formField}
									select
									variant='outlined'
									margin='normal'
									id='primary_education'
									name='primary_education'
									required
									fullWidth
									value={detailsState.primary_education}
									error={detailsState.primary_educationError !== ''}
									helperText={detailsState.primary_educationError}
									onChange={e => {
										changeState({
											primary_education: e.target.value
										});
									}}
								>
									{
										degrees.map((degree, i) => {
											return (
												<MenuItem value={degree} key={i}>
													{degree}
												</MenuItem>
											)
										})
									}
								</TextField>
							}

							{
								detailsState.qualification === 'A Level/FSc/FA/Diploma' &&
								<TextField
									className={classes.formField}
									select
									variant='outlined'
									margin='normal'
									id='secondary_education'
									name='secondary_education'
									required
									fullWidth
									value={detailsState.secondary_education}
									error={detailsState.secondary_educationError !== ''}
									helperText={detailsState.secondary_educationError}
									onChange={e => {
										changeState({
											secondary_education: e.target.value
										});
									}}
								>
									{
										degrees_inter.map((degree, i) => {
											return (
												<MenuItem value={degree} key={i}>
													{degree}
												</MenuItem>
											)
										})
									}
								</TextField>

							}

							<Typography
								variant='subtitle1'
								className={classes.formLabel}
							>
								OCCUPATION
							</Typography>

							<TextField
								className={classes.formField}
								select
								variant='outlined'
								margin='normal'
								id='occupation'
								name='occupation'
								required
								fullWidth
								value={detailsState.occupation}
								error={detailsState.occupationError !== ''}
								helperText={detailsState.occupationError}
								onChange={e => {
									changeState({
										occupation: e.target.value,
										showotheroccupation: e.target.value === 'Others'
									});
								}}
							>
								{
									occupation.map((degree, i) => {
										return (
											<MenuItem value={degree} key={i}>
												{degree}
											</MenuItem>
										)
									})
								}

							</TextField>

							{
								detailsState.showotheroccupation &&
								<TextField
									className={classes.formField}
									InputProps={{
										inputProps: {
											maxLength: 100,
										}
									}}
									id='other_occupation'
									name='other_occupation'
									autoComplete='other_occupation'
									variant='outlined'
									margin='normal'
									required
									fullWidth
									value={detailsState.otheroccupation}
									error={detailsState.otheroccupationError !== ''}
									helperText={detailsState.otheroccupationError}
									onChange={e => changeState({ otheroccupation: e.target.value })}
								/>
							}
							<Typography
								variant='subtitle1'
								className={classes.formLabel}
							>
								ORGANISATION
							</Typography>

							<TextField
								className={classes.formField}
								InputProps={{
									inputProps: {
										maxLength: 255,
									}
								}}
								variant='outlined'
								margin='normal'
								fullWidth
								id='workstudy_name'
								name='workstudy_name'
								autoComplete='workstudy'
								error={detailsState.workstudyNameError !== ''}
								helperText={detailsState.workstudyNameError}
								value={detailsState.workstudyName}
								onChange={e => changeState({ workstudyName: e.target.value })}
							/>
						</FormControl>
					</>
				}
			</form>

			<Button
				style={{
					backgroundColor: '#38364c',
					width: '100px',
				}}
				fullWidth
				variant='contained'
				color='primary'
				className={classes.submit}
				onClick={validateNextStep}
			>
				Next
			</Button>

		</>
	);
};

MentalHealthwDetails.defaultProps = {
	degrees: [
		'O Level',
		'Matric'
	],

	degrees_inter: [
		'A Level',
		'FSc',
		'FA',
		'Diploma'
	],
	occupation: [
		'Student',
		'Teacher',
		'Police',
		'Healthcare',
		'Community Member',
		'Others'
	],
	qualification: [
		'O Level/Matric',
		'A Level/FSc/FA/Diploma',
		'Graduation',
		'Masters'
	]

};

export default MentalHealthwDetails;

import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { columns } from "./GridHeadings";
import CasesToolbar from "./CasesToolbar";
import CardView from "./CardView";
import { Helmet } from "react-helmet-async";
const MyCare = ({ rows, onUpdate, onDelete, authData }) => {
  const [isGridView, setIsGridView] = useState(false);
  const toggleView = (datafromchild) => {
    setIsGridView(datafromchild);
  };
  // add onDelete function to be passes in datagrid
  const rowWithfunctions = rows.map((value, index) => {
    return { ...value, delete: (id) => onDelete(id) };
  });
  return (
    <div
      style={{
        height: 400,
        width: "100%",
      }}>
      {/* meta tags */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>MyCare+ Cases</title>
        <meta
          name="description"
          content="Cases added from MyCare+ mobile application"
        />
      </Helmet>
      {/* end meta */}
      <CasesToolbar
        rows={rows}
        isGridView={isGridView}
        settingGridView={toggleView}
      />
      {isGridView ? (
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rowWithfunctions}
            columns={columns}
            checkboxSelection={false}
            pageSize={20}
            disableSelectionOnClick={true}
          />
        </div>
      ) : (
        <CardView
          rows={rows}
          onUpdate={onUpdate}
          onDelete={onDelete}
          authData={authData}
        />
      )}
    </div>
  );
};
export default MyCare;

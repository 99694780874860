import { Button, Container, FormControl, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import watermark from '../img/watermark.png';
import Header from '../components/Header';
import { makeRequest, routes } from './AuthApi';

const useStyle= makeStyles(theme => ({
	main: {
		marginTop: theme.spacing(4)
	},
	heading: {
		textAlign: 'center',
		backgroundColor: '#EAEAEA',
		fontWeight: 'bold',
		fontSize: '1.4rem',
		padding: '5px 0 5px 0',
		marginBottom: theme.spacing(2),
		justifyContent: 'center',
	},
	uppercase: {
		textTransform: 'uppercase'
	},
	watermark: {
		backgroundImage: `url(${watermark})`,
		backgroundRepeat: 'no-repeat',
		backgroundColor: 'transparent',
		backgroundPosition: 'center',
		padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
		backgroundSize: 'contained',
	},
	textField: {
		backgroundColor: '#fafafa',
		border: '2px solid #cec9de',
		borderRadius: '6px'
	},
	root: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '2rem',
		backgroundColor: '#eaeaea',
		border: '2px solid #cec9de',
		borderRadius: '7px',
		paddingBottom: '2rem',
		width: '50%',
		margin: '0 auto'
	},
	label:{
		color: '#07D5C2',
		padding: '0.5rem',
		fontSize: '1rem',
		fontWeight: 'bold',
		textAlign: 'left',
		textTransform: 'uppercase'
	},
	successText: {
		textAlign: 'center',
		marginTop: theme.spacing(2)
	}
}))


const ForgotPassword = () => {
	const classes = useStyle();
	const [error, setError] = useState('');
	const [email, setEmail] = useState('');
	const [success, setSuccess] = useState(false);

	const forgot = async() => {
		const res = await makeRequest(
			routes.forgotpassword, {
				email,
				redirect_url: `${window.location.href.split('/').slice(0, 3).join('/')}/reset_password`
			}, 'POST'
		)
		if (res.ok) {
			setSuccess(true);
		}
		else {
			if (res.status === 404) {
				setError("Email is not registered!")
			}
		}
	}
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Forgot Password</title>
				<meta
					name='forgot password'
					content='Forgot Password'
				/>
			</Helmet>

			<Header />

			<Container
				component='main'
				className={classes.main}
			>
				{
					success ?
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<Typography
								component='h1'
								variant='h5'
								className={`${classes.heading} ${classes.uppercase}`}
							>
								You will receive an email with instructions on how to change
								your password in a few minutes.
							</Typography>
							<Button
								style={{
									backgroundColor: ' #59567f',
									color: '#fafafa',
									fontSize: '0.8rem',
								}}
								component={Link}
								to='/'
							>
								Go back to Home Page
							</Button>
						</div>
					:
						<>
							<Typography
								component='h1'
								variant='h5'
								className={`${classes.heading} ${classes.uppercase}`}
								style={{
									width: '50%',
									margin: '0 auto',
								}}
							>
								Forgot Password
							</Typography>

							<form className={classes.root}>
								<FormControl style={{width: '100%', padding: '0rem'}}>
									<Typography
										variant= 'h6'
										className={classes.label}
									>
										Tell us your email and we'll send you instructions to
										change your password right away
									</Typography>

									<TextField
										InputProps={{
											inputProps: {
												maxLength: 100
											}
										}}
										variant="outlined"
										fullWidth
										required
										autoComplete='email'
										placeholder='Enter your email'
										value={email}
										onChange={e => {
											setEmail(e.target.value);
											setError('');
										}}
										error={error !== ''}
										helperText={error}
										className={classes.textField}
									/>
								</FormControl>
							</form>

							<Button
								style={{
									backgroundColor: 'rgb(56, 54, 76)',
									color: '#fafafa',
									fontSize:'0.875rem',
									width: '11rem',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									margin: '0 auto',
									marginTop: '1rem'
								}}
								onClick={forgot}
							>
								Submit
							</Button>
						</>
				}

			</Container>
		</>

	)
}


export default ForgotPassword

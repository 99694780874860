import React, { useState } from "react";
import FeedbackForm from "./FeedbackForm";
import { Typography, makeStyles, Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { routes, makeRequest } from "./AuthApi";
import {
  validateNum,
  validateEmail,
  validateMobile,
  validateExists,
  validateName,
  validateOptions,
} from "./FormParts/Validation";
import ministryLogo from "../img/ministry-logo.png";
import unicef from "../img/unicef.png";
import Logo from "../img/Logo.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
  },
  paperBackground: {
    backgroundColor: "#fafafa",
    border: "solid black",
    borderWidth: "0.15rem",
    borderRadius: "0.3rem",
  },
  heading: {
    textAlign: "center",
    backgroundColor: "#eaeaea",
    minWidth: "800px",
    fontWeight: "bold",
    fontSize: "1.4rem",
    padding: "5px 0 5px 0",
    marginBottom: theme.spacing(2),
  },
  topHeader: {
    textAlign: "center",
    padding: "10",
    background: "#eaeaea",
    color: "#383838",
    fontSize: "14",
    fontFamily: "Arial",
    fontWeight: "400",
  },
  logo: {
    width: "7rem",
    height: "7rem",
    objectFit: "contain",
    display: "flex",
    margin: "auto",
  },
  center: {
    textAlign: "center",
    fontFamily: "Arial",
  },
  right: {
    textAlign: "right",
    fontFamily: "Arial",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("lg")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
}));

const GiveFeedBack = () => {
  const classes = useStyles();
  const [values, setValues] = useState({
    service: "",
    serviceError: "",
    name: "",
    nameError: "",
    age: "",
    ageError: "",
    occupation: "",
    occupationError: "",
    phone_number: "",
    phoneNumberError: "",
    email: "",
    emailError: "",
    message: "",
    messageError: "",
    service_type: "",
    serviceTypeError: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const submit = async () => {
    const errors = changeState(values);
    if (
      !errors.nameError &&
      !errors.ageError &&
      !errors.occupationError &&
      !errors.emailError &&
      !errors.phoneNumberError &&
      (values.service_type !== "otherServices" || !errors.serviceError) &&
      !errors.messageError &&
      !errors.serviceTypeError
    ) {
      const res = await makeRequest(routes.give_feedback, values);

      if (res.ok) {
        const resJson = await res.json();
        console.error(resJson);
        setSubmitted(true);
      } else {
        alert("There was a problem!");
      }
    }
  };

  const changeState = (changeState) => {
    changeState.nameError = validateName(changeState.name);
    changeState.ageError = validateNum(changeState.age, 16, 70);
    changeState.emailError = validateEmail(changeState.email);
    changeState.phoneNumberError = validateMobile(changeState.phone_number);
    changeState.serviceError = validateExists(changeState.service);
    changeState.messageError = validateExists(changeState.message);
    changeState.occupationError = validateExists(changeState.occupation);
    changeState.serviceTypeError = validateOptions(changeState.service_type, [
      "ourServices",
      "otherServices",
    ]);

    setValues((prevState) => {
      return {
        ...prevState,
        ...changeState,
      };
    });

    return {
      nameError: changeState.nameError,
      ageError: changeState.ageError,
      emailError: changeState.emailError,
      phoneNumberError: changeState.phoneNumberError,
      serviceError: changeState.serviceError,
      messageError: changeState.messageError,
      occupationError: changeState.occupationError,
      serviceTypeError: changeState.serviceTypeError,
    };
  };

  return (
    <>
      <Grid
        container
        style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        direction="row"
        justifyContent="center"
        alignItems="center">
        <img
          src={Logo}
          alt="logo"
          style={{ width: 120 }}
          className={classes.logo}
        />
      </Grid>
      <Grid
        className={classes.topHeader}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs={0} lg={1} sm={0}></Grid>
        <Grid item xs={12} lg={2} sm={3}>
          <img src={ministryLogo} alt="logo" className={classes.logo} />
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          sm={6}
          className={classes.center}
          style={{ fontSize: "1rem", fontFamily: "Arial", fontWeight: 700 }}>
          Mental Health &amp; Psychosocial Support <br />
          A Project of Planning, Development &amp; Special Initiatives <br />
          Government of Pakistan
        </Grid>

        <Grid item xs={12} lg={2} sm={3} className={classes.right}>
          <img src={unicef} alt="logo" className={classes.logo} style={{}} />
        </Grid>
        <Grid item xs={0} lg={1} sm={0}></Grid>
      </Grid>
      <div className={classes.paper}>
        {submitted ? (
          <>
            <Typography
              component="h1"
              variant="h5"
              className={`${classes.heading} ${classes.uppercase}`}>
              Your feedback has been recorded, thank you.
            </Typography>
            <Button
              style={{
                backgroundColor: " #59567f",
                color: "#fafafa",
                fontSize: "0.8rem",
              }}
              component={Link}
              to="/">
              Go back to Home Page
            </Button>
          </>
        ) : (
          <>
            <br />
            <br />
            <br />
            <Typography
              component="h1"
              variant="h5"
              className={`${classes.heading} ${classes.uppercase}`}
              style={{
                color: "#59567f",
                textTransform: "uppercase",
                fontWeight: 900,
              }}>
              Feedback
            </Typography>
            <FeedbackForm values={values} setValues={changeState} />
            <Button
              style={{
                backgroundColor: " #59567f",
                color: "#fafafa",
                fontSize: "0.875rem",
                width: "6rem",
              }}
              onClick={submit}>
              Save
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default GiveFeedBack;

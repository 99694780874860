const validateNotBlank = value => {
	if (value === undefined) return ''
	return value ? '' : 'Cannot be blank'
};

const validateLength = (value, minLength, maxLength) => {
	if (value === undefined) return '';
	const blankMessage = validateNotBlank(value);
	return (
		blankMessage ?
			blankMessage
			:
			value.length < minLength ?
				`is too short (minimum is ${minLength} characters)`
				:
				value.length > maxLength ?
					`is too long (maximum is ${maxLength} characters)`
					:
					''
	);
};

const validateName = value => {
	if (value === undefined) return ''

	if (value) {
		return value.length >= 4 ? '' : 'Name must be at least 4 characters';
	}
	return 'Cannot be blank';
};

const validateOptions = (value, options) => {
	if (value === undefined) return ''
	return options.includes(value) ? '' : 'Please select a valid option';
}

const validateNum = (value, min, max) => {
	if (value === undefined) return ''
	if (!value) return 'Cannot be Blank';
	if (!/\d/.test(value)) return 'Must be a number';
	const intValue = parseInt(value);
	if (intValue < min || intValue > max) return `Value must be between ${min}-${max}`;
	return ''
};

const validateEmail = (value) => {
	if (value === undefined) return ''

	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

	return value && emailRegex.test(value) ? '' : 'Please enter an email address';
};

const validateMobile = (value) => {
	if (value === undefined) return ''

	const mobileRegex = /03[0-9]{9}/;
	if (value && mobileRegex.test(value)) {
		return '';
	}
	else {
		if (value.length === 11) return 'Your mobile number should start with 03';
		return 'Please enter a valid mobile number';
	}
}

const validateExists = (value) => {
	return value !== undefined && (value === '' || value === null) ? 'Cannot be blank' : '';
};

const validateAtLeastOne = (...values) => {
	return values.some(value => value) ? '' : 'At least one should be selected';
};

export {
	validateNotBlank,
	validateOptions,
	validateNum,
	validateEmail,
	validateMobile,
	validateExists,
	validateName,
	validateLength,
	validateAtLeastOne
};

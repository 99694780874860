import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import newsLetter1 from "./newsletter/Newsletter 001.png"
import newsLetter2 from "./newsletter/Newsletter 002.png"
import newsLetter3 from "./newsletter/Newsletter 003.png"
import newsLetter4 from "./newsletter/Newsletter 004.png"
import newsLetter5 from "./newsletter/Newsletter 005.png"
import newsLetter6 from "./newsletter/Newsletter 006.png"
import newsLetter7 from "./newsletter/Newsletter 007.png"
import newsLetter8 from "./newsletter/Newsletter 008.png"
import newsLetter9 from "./newsletter/Newsletter 009.png"
import newsLetter10 from "./newsletter/Newsletter 010.png"
import newsLetter11 from "./newsletter/Newsletter 011.png"
import newsLetter12 from "./newsletter/Newsletter 012.png"
import newsLetter13 from "./newsletter/Newsletter 013.png"
import newsLetter14 from "./newsletter/Newsletter 014.png"
import newsLetter15 from "./newsletter/Newsletter 015.png"
import newsLetter16 from "./newsletter/Newsletter 016.png"
import newsLetter17 from "./newsletter/Newsletter 017.png"
import newsLetter18 from "./newsletter/Newsletter 018.png"
import newsLetter19 from "./newsletter/Newsletter 019.png"
import newsLetter20 from "./newsletter/Newsletter 020.png"
import newsLetter21 from "./newsletter/Newsletter 021.png"
import newsLetter22 from "./newsletter/Newsletter 022.png"
import newsLetter23 from "./newsletter/Newsletter 023.png"
import newsLetter24 from "./newsletter/Newsletter 024.png"
import newsLetter25 from "./newsletter/Newsletter 025.png"
import newsLetter26 from "./newsletter/Newsletter 026.png"
import newsLetter27 from "./newsletter/Newsletter 027.png"
import newsLetter28 from "./newsletter/Newsletter 028.png"
import newsLetter29 from "./newsletter/Newsletter 029.png"
import newsLetter30 from "./newsletter/Newsletter 030.png"
import newsLetter31 from "./newsletter/Newsletter 031.png"

const tutorialSteps = [
	{
		imgPath:
			newsLetter1,
	},
	{
		imgPath: newsLetter2
	},
	{
		imgPath: newsLetter3
	},
	{
		imgPath: newsLetter4
	},
	{
		imgPath: newsLetter5
	},
	{
		imgPath: newsLetter6
	},
	{
		imgPath: newsLetter7
	},
	{
		imgPath: newsLetter8
	},
	{
		imgPath: newsLetter9
	},
	{
		imgPath: newsLetter10
	},
	{
		imgPath: newsLetter11
	},
	{
		imgPath: newsLetter12
	},
	{
		imgPath: newsLetter13
	},
	{
		imgPath: newsLetter14
	},
	{
		imgPath: newsLetter15
	},
	{
		imgPath: newsLetter16
	},
	{
		imgPath: newsLetter17
	},
	{
		imgPath: newsLetter18
	},
	{
		imgPath: newsLetter19
	},
	{
		imgPath: newsLetter20
	},
	{
		imgPath: newsLetter21
	},
	{
		imgPath: newsLetter22
	},
	{
		imgPath: newsLetter23
	},
	{
		imgPath: newsLetter24
	},
	{
		imgPath: newsLetter25
	},
	{
		imgPath: newsLetter26
	},
	{
		imgPath: newsLetter27
	},
	{
		imgPath: newsLetter28
	},
	{
		imgPath: newsLetter29
	},
	{
		imgPath: newsLetter30
	},
	{
		imgPath: newsLetter31
	},
]

const useStyles = makeStyles(theme => ({
	img: {
		height: 455,
		width: '100%',
		cursor: 'zoom-in'
	}
}));

const SwipeableTextMobileStepper = () => {
	const classes = useStyles();

	return (

		<Carousel
			autoPlay={false}
			animation="slide"
			navButtonsAlwaysVisible={true}
			fullHeightHover={true}
			NavButton={({onClick, className, style, next, prev}) => {
				return (
					next &&
					<Button onClick={onClick} className={className} style={style}>
						<NavigateNextIcon  />
					</Button>
				)
			}}
		>
			{
				tutorialSteps.map(step => (
					<img
						className={classes.img}
						key={step.label}
						src={step.imgPath}
						alt={step.label}
						onClick={() => {
							window.open(step.imgPath, '_blank', 'noopener,noreferrer');
						}}
					/>
				))
			}
		</Carousel>
	);
}

export default SwipeableTextMobileStepper;

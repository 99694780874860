import { Box, Button, Modal, TextField, Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { useState } from "react";
import { addeditOutcome } from "./AddEditOutcome";
import { CaseCardStyle } from "./CaseCardStyle";

const AddEditOutcomeModal = ({ onUpdate, caseData, isModal, isSetModal }) => {
  const [selectedItem, setSelectedItem] = useState(caseData);
  const [newOutcomeValue, setNewOutcomeValue] = useState(caseData.outcome);
  const handleClose = () => {
    setSelectedItem({});
    setNewOutcomeValue("");
    isSetModal(!isModal);
  };
  return (
    <Modal
      open={isModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={CaseCardStyle.modalBox}>
        <Typography
          id="modal-modal-title"
          style={{
            fontWeight: "bold",
            color: "#5a2b71",
            fontSize: "1.3rem",
            display: "flex",
            justifyContent: "space-between",
          }}>
          {selectedItem.outcome === "N/A " ? "Add" : "Update"} Outcome
          <CancelIcon
            onClick={handleClose}
            style={{ color: "#5c2b6d", cursor: "pointer", marginTop: "8px" }}
            backgroundColor="#5c2b6d"
            titleAccess="Close"
          />
        </Typography>
        <Typography
          style={{
            marginBottom: "10px",
            color: "#5a2b71",
            fontSize: "0.8rem",
          }}>
          MHPSS Case ID: <u>{selectedItem.id}</u>
        </Typography>
        <Typography></Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <TextField
            value={newOutcomeValue !== "N/A " ? newOutcomeValue : ""}
            multiline
            minRows={7}
            maxRows={7}
            required
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setNewOutcomeValue(e.target.value);
            }}
          />
          {(newOutcomeValue === "" || newOutcomeValue === null) && (
            <Typography
              style={{
                marginTop: "10px",
                color: "#5a2b71",
                fontSize: "0.8rem",
              }}>
              * The outcome should not be blank
            </Typography>
          )}
          <Button
            style={CaseCardStyle.AddEditButtonStyleInsideModal}
            onClick={() => {
              if (newOutcomeValue !== "N/A " && newOutcomeValue !== "")
                addeditOutcome(
                  newOutcomeValue,
                  selectedItem,
                  setSelectedItem,
                  setNewOutcomeValue,
                  isSetModal,
                  onUpdate
                );
            }}>
            {selectedItem.outcome === "N/A " ? "Add" : "Update"}
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
};
export default AddEditOutcomeModal;

import { DataGrid } from "@mui/x-data-grid";
import HamdardToolbar from "./toolbars/HamdardToolbar";

const MHWS = ({ rows, rowCount, changePage }) => {
  const columns = [
    {
      field: "created_at",
      headerName: "Signup Date and Time",
      width: 220,
    },
    {
      field: "name",
      headerName: "Name",
      width: 250,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email ID",
      width: 250,
      editable: true,
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 110,
      editable: true,
    },
    {
      field: "gender",
      headerName: "Gender",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
    },
    {
      field: "phone_no",
      headerName: "Mobile No.",
      width: 160,
      editable: false,
    },
    {
      field: "primary_education",
      headerName: "Primary Education",
      width: 200,
      editable: false,
    },
    {
      field: "secondary_education",
      headerName: "Secondary Education",
      width: 220,
      editable: false,
    },
    {
      field: "graduation_degree",
      headerName: "Graduation",
      width: 220,
      editable: false,
    },
    {
      field: "masters_degree",
      headerName: "Masters",
      width: 220,
      editable: false,
    },
    {
      field: "profession",
      headerName: "Occupation",
      width: 220,
      editable: false,
    },
    {
      field: "study_place",
      headerName: "Study Place/Work Place",
      width: 300,
      editable: false,
    },
  ];
  return (
    <div
      style={{
        height: 400,
        width: "100%",
        marginTop: "2rem",
        marginBottom: "2rem",
      }}>
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection={false}
        pageSize={20}
        rowsPerPageOptions={[5]}
        rowCount={rowCount}
        paginationMode="server"
        isRowSelectable={false}
        onPageChange={(n) => changePage(n + 1)}
        components={{
          Toolbar: HamdardToolbar,
        }}
        GridExportCsvOptions={(document.title = "MHWS Data")}
        disableSelectionOnClick={true}
      />
    </div>
  );
};

export default MHWS;

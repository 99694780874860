import { Container, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { makeAuthRequest, routes } from '../../Auth/AuthApi';
import hamdardForce from "../../img/hamdard-force.png";
import Header from '../Header';
import LanguageConfirmation from './LanguageConfirmation';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';


const useStyles = makeStyles(theme => ({
	root: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '2rem',
		backgroundColor: '#eaeaea',
		border: '2px solid #cec9de',
		borderRadius: '7px',
		paddingBottom: '2rem'
	},
	textFeild: {
		backgroundColor: '#fafafa',
		border: '2px solid #cec9de',
		borderRadius: '6px'
	},
	label: {
		color: '#07D5C2',
		padding: '0.5rem',
		fontSize: '1rem',
		fontWeight: 'bold',
		textAlign: 'left',
		textTransform: 'uppercase'
	},
	checkboxes: {
		display: 'flex',
		marginTop: theme.spacing(1),
		flexWrap: 'wrap',
	},
	info: {
		color: 'black',
		fontSize: '0.875rem',
	},
	checkboxContainer: {
		backgroundColor: 'white',
		borderRadius: '7px',
		border: `1px solid #cec9de`,
		padding: theme.spacing(2),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		alignItems: 'center'
	},
	topContainer: {
		backgroundColor: 'white',
		borderRadius: '7px',
		border: `1px solid #cec9de`,
		padding: theme.spacing(2),
		marginBottom: theme.spacing(1),
		alignItems: 'center'
	},
	formLayout: {
		[theme.breakpoints.down('lg')]: {
			width: '68%',

		},
		[theme.breakpoints.down('md')]: {
			width: '75%'
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	radioLayout: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		[theme.breakpoints.down('xs')]: {
			flexWrap: 'wrap',
		}
	},
	radioButton: {
		marginLeft: '11rem',
		[theme.breakpoints.down('md')]: {
			marginLeft: '8rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '8rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginLeft: '-11px',
		}
	},
	languageTitle: {
		width: '68%',
		[theme.breakpoints.down('md')]:
		{
			width: '75%'
		},
		[theme.breakpoints.down('sm')]:
		{
			width: '75%'
		},
		[theme.breakpoints.down('xs')]:
		{
			width: '100%'
		},
		marginTop: theme.spacing(2)
	},
	progressBar: {
		height: '2rem',
		color: ' #a1035a',
	},
	heading: {
		textAlign: 'center',
		backgroundColor: '#eaeaea',
		minWidth: '800px',
		fontWeight: 'bold',
		fontSize: '1.4rem',
		padding: '5px 0 5px 0',
		marginBottom: theme.spacing(2)
	},
	uppercase: {
		textTransform: 'uppercase'
	},
	colorPrimary: {
		backgroundColor: '#f5f5f5',
	},
	barColorPrimary: {
		backgroundColor: '#a1035a'
	},
	instructionHeading: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		display: 'block'
	},
}));

const Home = ({ setAuth, authData, courseData, setCourseData, logOut }) => {
	const history = useHistory();
	const classes = useStyles();
	const [casesCount, setCasesCount] = useState(0);
	const [langError, setLangError] = useState('');
	const [langData, setLangData] = useState({
		changed: '',
		showConfirmation: false
	});

	const download = async () =>{
		if (authData) {
			const res = await makeAuthRequest(
				routes.certificate, null, 'GET', authData
			)

			if (res.ok) {
				const blob = await res.blob();
				const file = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = file
				link.download = 'certificate.pdf'

				// we need to append the element to the dom -> otherwise it will not work in firefox
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
			else {
				alert('There is a error');
			}
		}
	}

	const register = async () => {
		if (courseData.language) {
			const res = await makeAuthRequest(
				routes.language_select, { language: courseData.language }, 'POST', authData
			)
			const resJson = await res.json()

			if (res.ok) {
				await fetchData();
			}
			else {
				alert('There was a problem!')
				console.error(resJson);
			}
		}
		else {
			setLangError('Please select a language!')
		}
	};


	const changeLanguage = async () => {
		try {
			const res = await makeAuthRequest(
				routes.change_language, {}, 'POST', authData
			)

			if (res.ok) {
				await fetchData();

				setLangData(() => ({
					changed: 'success',
					showConfirmation: false
				}));
			}
			else {
				console.error(res);
				setLangData({
					changed: 'error',
					showConfirmation: false
				});
			}
		}
		catch (e) {
			console.error(e);
			setLangData({
				changed: 'error',
				showConfirmation: false
			});
		}
	};

	const changeLanguageConfirmation = () => {
		setLangData(prev => {
			return {
				...prev,
				showConfirmation: !prev.showConfirmation
			}
		})
	};

	const getCasesCount = useCallback(async () => {
		try {
			const res = await makeAuthRequest(routes.get_cases, null, "GET", authData);

			const resJson = await res.json();

		if (res.ok) {
				console.error(resJson)
				setCasesCount(resJson.data.length);
			}
			else {
				console.error(resJson);
			}
		}
		catch (e) {
			console.error(e);
		}
	}, [authData]);

	const fetchData = useCallback(async () => {
		try {
			const res = await makeAuthRequest(routes.hamdard_reload, null, "GET", authData);
			const resJson = await res.json();
			if (res.ok) {
				setAuth(authData, resJson);
				getCasesCount();
			}
			else {
				if (res.status === 401) {
					logOut();
				}
				else {
					console.error(resJson);
					alert('error');
				}
			}
		}
		catch (e) {
			console.error(e);
		}
	}, [authData, setAuth, logOut, getCasesCount]);

	useEffect(() => {
		if (!authData || Object.keys(authData).length === 0) {
			history.push('/hamdard/login');
		}
		else {
			fetchData();
		}
	}, [history, fetchData, authData]);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Hamdard Home</title>
				<meta
					name='description'
					content='Hamdard Force homepage'
				/>
			</Helmet>

			<Header logOut={logOut} />

			<LanguageConfirmation
				open={langData.showConfirmation}
				changeLanguage={changeLanguage}
				changeLanguageConfirmation={changeLanguageConfirmation}
				language={courseData?.language}
			/>

			<Container className={classes.formLayout}>
				<Grid
					container
					style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}
					direction="row"
					justifyContent="center"
					alignItems="center"
				>
					<Typography
						component='h1'
						variant='h5'
						className={`${classes.heading} ${classes.uppercase} ${classes.languageTitle}`}
						style={{
							color: '#451062',
							textTransform: 'uppercase',
							fontWeight: 900,
							minWidth: '100%'
						}}
					>
						Welcome to hamdard force
					</Typography>

					<Grid
						item
						xs={12}
						lg={5}
						sm={6}
						style={{
							display: 'flex',
							justifyContent: 'center'
						}}
					>
						<img
							src={hamdardForce}
							alt="logo"
						/>
					</Grid>

					<Container
						style={{
							paddingBottom: '2rem',
							paddingTop: '2rem',
							paddingLeft: '3rem',
							backgroundColor: '#eaeaea',
							border: '2px solid #cec9de',
							borderRadius: '7px'
						}}
					>
						<Typography
							className={classes.info}
						>
							The Hamdard Force is a part of MHPSS initiative to train frontline
							responders, teachers, youth and volunteers to provide basic emotional
							support and refer people with mental healthcare needs to MHPSS counsellors.
						</Typography>

						<Typography
							variant='h6'
							style={{
								color: '#451062'
							}}
							className={classes.instructionHeading}
							component={Link}
							href='https://www.youtube.com/watch?v=yhhviP5KiBo'
						>
							To join the Hamdard Force
						</Typography>

						<Typography
							variant='subtitle2'
							style={{
								color: '#a1035a'
							}}
						>
							Step 1 - Register
						</Typography>

						<Typography>
							Go to our website www.mhpss.pk and register in Hamdard Force and save a
							PASSWORD.
						</Typography>

						<Typography
							variant='subtitle2'
							style={{
								color: '#a1035a'
							}}
						>
							Step 2 Complete your course
						</Typography>

						<Typography>
							Go to website, by using your phone number and password,
							LOG IN to our website (button on top right).
							Select the language of the course.
							Again, use your mobile number and password to LOG IN to the course.
							Complete the online training course، which can take up to two hours.
						</Typography>

						<Typography
							variant='subtitle2'
							style={{
								color: '#a1035a'
							}}
						>
							Step 3 Download Hamdard Force App
						</Typography>

						<Typography>
							Download the Hamdard Force mobile application (from the Play Store or App Store).
						</Typography>

						<Typography
							variant='subtitle2'
							style={{
								color: '#a1035a'
							}}
						>
							Step 4 Help other people
						</Typography>

						<Typography>
							Identify people who might need help for psychological or mental health
							problems.
							With their permission, send their contact details through your Hamdard
							Force application.
							Our team will contact them for further advice.
						</Typography>

						<Typography
							variant='subtitle2'
							style={{
								color: '#a1035a'
							}}
						>
							Step 5 Download your certificate
						</Typography>

						<Typography>
							After you have referred ten people who need help, LOG IN on the website.
							Download your Hamdard Force certificate.
						</Typography>

						<Typography
							variant='h6'
							style={{
								color: '#451062',
								fontFamily: 'Noto Nastaliq Urdu',
								lineHeight: 'initial'
							}}
							className={classes.instructionHeading}
							component={Link}
							href='https://www.youtube.com/watch?v=ug_LwEfixtA'
							dir='rtl'
						>
							ہمدرد فورس کا کارکن بننے کا طریقہ
						</Typography>

						<Typography
							variant='subtitle2'
							style={{
								color: '#a1035a',
								fontFamily: 'Noto Nastaliq Urdu',
								lineHeight: 'initial'
							}}
							dir='rtl'
						>
							مرحلہ ۱۔ رجسٹر کریں
						</Typography>

						<Typography
							style={{
								fontFamily: 'Noto Nastaliq Urdu',
								lineHeight: 'initial'
							}}
							dir='rtl'
						>
							ہماری ویب سائٹ پر جا کہ ہمدرد فورس کیلئے رجسٹر کریں
							<br />
							<span dir='ltr'>WWW.MHPSS.PK</span>
						</Typography>

						<Typography
							variant='subtitle2'
							style={{
								color: '#a1035a',
								fontFamily: 'Noto Nastaliq Urdu',
								lineHeight: 'initial'
							}}
							dir='rtl'
						>
							مرحلہ۲۔ کورس کریں
						</Typography>

						<Typography
							style={{
								fontFamily: 'Noto Nastaliq Urdu',
								lineHeight: 'initial'
							}}
							dir='rtl'
						>
							اپنے فون نمبر اور پاس ورڈ کے ذریعے ویب سائٹ پر لاگ اِن کریں۔
							کورس کرنے کیلئے زبان کا انتخاب کریں۔
							دوبارہ اپنے فون نمبر اور پاس ورڈ کے ذریعے کورس کیلئے لاگ اِن کریں۔
							آن لائن تربیتی کورس مکمل کریں۔ اس میں دو گھنٹے کا وقت لگ سکتا ہے۔
						</Typography>

						<Typography
							variant='subtitle2'
							style={{
								color: '#a1035a',
								fontFamily: 'Noto Nastaliq Urdu',
								lineHeight: 'initial'
							}}
							dir='rtl'
						>
							مرحلہ۳۔ ہمدرد فورس کی ایپ حاصل کریں
						</Typography>

						<Typography
							style={{
								fontFamily: 'Noto Nastaliq Urdu',
								lineHeight: 'initial'
							}}
							dir='rtl'
						>
							کورس مکمل کرنے کے بعد (پلےسٹور یا ایپ سٹور سے) ہمدرد فورس کی ایپ حاصل کریں۔
						</Typography>

						<Typography
							variant='subtitle2'
							style={{
								color: '#a1035a',
								fontFamily: 'Noto Nastaliq Urdu',
								lineHeight: 'initial'
							}}
							dir='rtl'
						>
							مرحلہ۴۔ ضرورت مند افراد کی مدد کریں
						</Typography>

						<Typography
							style={{
								fontFamily: 'Noto Nastaliq Urdu',
								lineHeight: 'initial'
							}}
							dir='rtl'
						>
							اپنے ارد گرد اُن افراد کی نشاندہی کریں جنہیں ذہنی مسائل یا الجھنوں کیلئے مدد کی ضرورت ہو۔
							اُن کی اجازت سے، ایپ کے ذریعے اُن کی تفصیلات ہماری ٹیم کو بھیجیں۔
							اس کے بعد ہماری ٹیم اُن سے رابطہ کر کہ اُن کی رہنمائی کرے گی۔
						</Typography>

						<Typography
							variant='subtitle2'
							style={{
								color: '#a1035a',
								fontFamily: 'Noto Nastaliq Urdu',
								lineHeight: 'initial'
							}}
							dir='rtl'
						>
							مرحلہ ۵۔ سرٹیفیکیٹ حاصل کریں
						</Typography>


						<Typography
							style={{
								fontFamily: 'Noto Nastaliq Urdu',
								lineHeight: 'initial'
							}}
							dir='rtl'
						>
							جب آپ دس ضرورت مند افراد کا رابطہ ہماری ٹیم سے کروا دیں تو ویب سائٹ پر لاگ اِن کریں۔
							اپنا ہمدرد فورس کا سرٹیفیکیٹ حاصل کر لیں۔
						</Typography>
					</Container>
				</Grid>
			</Container>

			<Container
				component='main'
				className={classes.formLayout}
				style={{ paddingBottom: '2rem' }}
			>
				{!courseData?.registered ?
					<Step1
						classes={classes}
						courseData={courseData}
						setCourseData={setCourseData}
						register={register}
						langError={langError}
					/>
					:
					parseInt(courseData?.progress) !== 100 ?
						<Step2
							classes={classes}
							courseData={courseData}
							langData={langData}
							changeLanguageConfirmation={changeLanguageConfirmation}
						/>
						:
						casesCount >= 10 ?
							<Step4
								classes={classes}
								download={download}
							/>
							:
							<Step3
								classes={classes}
							/>
				}
			</Container>
		</>
	)
}

export default Home;

import { Box, Button, Divider, Grid, makeStyles } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { makeRequest, routes } from "../Auth/AuthApi";
import SwipeableTextMobileStepper from "../carousel";
import DownClub from "../img/Down-club.png";
import GDP from "../img/GDP.png";
import ict from "../img/ict.png";
import Logo from "../img/Logo.png";
import ministryLogo from "../img/ministry-logo.png";
import ministry from "../img/ministry.png";
import NIC from "../img/NIC.png";
import nust from "../img/nust.png";
import pehliKiranSchool from "../img/Pehli-kiran-school.png";
import ministry3 from "../img/Poverty-Alleviation.png";
import rozan from "../img/rozan.png";
import unicef from "../img/unicef.png";

const useStyles = makeStyles((theme) => ({
  alignLogos: {
    display: "flex",
    alignItems: "center",
  },
  horizontal: {
    display: "flex",
    flexDirection: "row",
  },
  circleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "50",
    rgb: "245, 245, 245",
  },
  brandContainer: {
    height: 65,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  right: {
    textAlign: "right",
    fontFamily: "Arial",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("lg")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  center: {
    textAlign: "center",
    fontFamily: "Arial",
  },
  logo: {
    width: "7rem",
    height: "7rem",
    objectFit: "contain",
    display: "flex",
    margin: "auto",
  },
  topHeader: {
    textAlign: "center",
    padding: "10",
    background: "#F5F5F5",
    color: "#383838",
    fontSize: "14",
    fontFamily: "Arial",
    fontWeight: "400",
  },
  navbar: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    background: "#F5F5F5",
    fontFamily: "Arial",
    borderBottom: "2px solid #747272",
    height: "4rem",
  },
  navLink: {
    fontSize: 14,
    fontFamily: "Arial",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  typography: {
    textAlign: "center",
    fontSize: "1rem",
    fontFamily: "Arial",
    fontWeight: 400,
    padding: "2rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "3rem",
    },
  },
  brand: {
    width: 65,
    maxWidth: 90,
    textAlign: "center",
    fontFamily: "Arial",
    alignItems: "bottom",
  },
  brandRectangular: {
    width: 120,
    textAlign: "center",
    fontFamily: "Arial",
    alignItems: "bottom",
  },
  footer: {
    width: 65,
    maxWidth: 90,
    textAlign: "left",
    fontFamily: "Arial",
  },
  map: {
    maxWidth: 460,
    marginLeft: "9.5rem",
    width: "100%",
    height: "auto",
    objectFit: "cover",
  },
  verticalcenter: {
    margin: 0,
    position: "fixed",
    top: "50vh",
  },
  footerIcon: {
    background: "#4c096f",
    color: "#FFFFFF",
    padding: 5,
    marginRight: "0px",
    borderRadius: "100%",
    fontSize: `${theme.spacing(2.8)}px !important`,
  },
  footerButton: {
    backgroundColor: "rgb(245, 245, 245)",
    fontSize: 9.5,
    fontFamily: "Arial",
    margin: theme.spacing(1),
    borderRadius: "10px",
    height: theme.spacing(4),
    width: "100px",
    textTransform: "capitalize",
  },
  footer_spacing: {
    width: "100%",
    display: "inherit",
    justifyContent: "flex-start",
  },
  feedback: {
    width: "100%",
    display: "center",
    alignItems: "center",
    justifyContent: "left",
  },
  CirclesResponsive: {
    [theme.breakpoints.down("xs")]: {
      width: "20%",
      display: "grid",
      justifyContent: "center",
    },
  },
  bottomButtons: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  bottomButtonsText: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0.5rem",
    },
  },
  MHRButton: {
    fontSize: 12,
    fontFamily: "Arial",
    height: 30,
    marginRight: 27,
    textTransform: "uppercase",
    position: "absolute",
    right: "5rem",
    fontWeight: "bold",
    lineHeight: "1rem",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      left: "0.5rem",
      // top: '5rem',
      // right: '5rem',
      marginTop: "1rem",
      marginBottom: "1rem",
      height: 65,
      width: "25%",
    },
    // [theme.breakpoints.down('xs')]: {
    // 	position: 'absolute',
    // 	marginLeft: '7.5rem',
    // 	marginTop: '1.5rem',
    // 	marginBottom: '1.5rem',
    // 	height: 50,
    // },
  },
}));

const Root = () => {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [mhwCount, setMhwCount] = useState(0);
  const [trainedCount, setTrainedCount] = useState(0);

  useEffect(() => {
    const getCount = async () => {
      try {
        const res = await makeRequest(routes.count_mhw, null, "GET");

        const resJson = await res.json();

        if (res.ok) {
          setMhwCount(resJson.count);
        } else {
          console.error(resJson);
        }
      } catch (e) {
        console.error(e);
      }
    };

    getCount();
  }, [setMhwCount]);

  useEffect(() => {
    const getCount = async () => {
      try {
        const res = await makeRequest(routes.mhw_trained_count, null, "GET");

        const resJson = await res.json();

        if (res.ok) {
          setTrainedCount(resJson.count);
        } else {
          console.error(resJson);
        }
      } catch (e) {
        console.error(e);
      }
    };

    getCount();
  }, [setTrainedCount]);

  return (
    <div className="Root" style={{ overflowX: "hidden" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mental Health and Psychosocial Support (MHPSS)</title>
        <meta
          name="description"
          content="Mental Health and Psychosocial Support (MHPSS)"
        />
      </Helmet>

      <Box className={classes.verticalcenter} style={{ zIndex: 1 }}>
        <Button
          size="medium"
          variant="outlined"
          style={{
            background: "#cab6cf",
            height: "3rem",
            width: "9rem",
            fontSize: "0.8rem",
            textTransform: "initial",
            fontFamily: "Arial",
            textAlign: "center",
            paddingLeft: "1px",
            paddingRight: "1px",
            borderRadius: "0px 4px 4px 0px",
            lineHeight: 1.5,
            pointerEvents: "none",
          }}

          /*href="https://registration.mhpss.pk/sign_up/mentalhealthworker"*/
        >
          Hamdard Force Registration
        </Button>

        <br />
        <br />

        <Button
          size="medium"
          classes={{
            root: classes.feedback,
          }}
          variant="outlined"
          style={{
            background: "#cab6cf",
            height: "3rem",
            width: "9rem",
            fontSize: "0.8rem",
            fontFamily: "Arial",
            textTransform: "capitalize",
            paddingLeft: "0.3rem",
            textAlign: "center",
            borderRadius: "0px 4px 4px 0px",
            lineHeight: 1.5,
            pointerEvents: "none",
          }}
          /*component={Link}
          to="/frontline_workers"*/
        >
          Frontline Responders
        </Button>

        <br />
        <br />

        <Button
          size="medium"
          variant="outlined"
          style={{
            background: "#cab6cf",
            height: "3rem",
            width: "9rem",
            fontSize: "0.8rem",
            fontFamily: "Arial",
            textAlign: "center",
            paddingLeft: "0.9rem",
            textTransform: "initial",
            borderRadius: "0px 4px 4px 0px",
            lineHeight: 1.5,
            pointerEvents: "none",
          }}
          /*component={Link}
          to="/appointment"*/
        >
          Book an Appointment
        </Button>
        <br />
        <br />
        <Button
          size="medium"
          classes={{
            root: classes.feedback,
          }}
          variant="outlined"
          style={{
            background: "#cab6cf",
            height: "3rem",
            width: "9rem",
            fontSize: "0.8rem",
            fontFamily: "Arial",
            textTransform: "capitalize",
            paddingLeft: "1.2rem",
            textAlign: "left",
            borderRadius: "0px 4px 4px 0px",
            lineHeight: 1.5,
            pointerEvents: "none",
          }}
          /*component={Link}
					to='/feedback'*/
        >
          Give Feedback
        </Button>
      </Box>

      <Grid
        className={classes.topHeader}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={1} lg={1} sm={1}></Grid>
        <Grid item xs={12} lg={2} sm={3}>
          <img src={ministryLogo} alt="logo" className={classes.logo} />
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          sm={6}
          className={classes.center}
          style={{ fontSize: "1rem", fontFamily: "Arial", fontWeight: 700 }}
        >
          A PROJECT OF <br /> MINISTRY OF PLANNING, DEVELOPMENT &amp; SPECIAL
          INITIATIVES GOVERNMENT OF PAKISTAN
        </Grid>

        <Grid item xs={12} lg={2} sm={3} className={classes.right}>
          <img src={unicef} alt="logo" className={classes.logo} style={{}} />
        </Grid>
        <Grid item xs={1} lg={1} sm={1}></Grid>
      </Grid>

      <Grid
        container
        style={{ height: 85, position: "relative" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={Logo}
          alt="logo"
          style={{ width: 120 }}
          className={classes.logo}
        />
        <Button
          size="medium"
          style={{
            background: "#cab6cf",
            pointerEvents: "none",
          }}
          /*component={Link}
          to="/mapping_resources"*/
          className={classes.MHRButton}
        >
          Mental Health Resources in Pakistan
        </Button>
        <Button
          size="medium"
          style={{
            fontSize: 12,
            background: "#cab6cf",
            fontFamily: "Arial",
            height: 30,
            marginRight: 27,
            textTransform: "uppercase",
            position: "absolute",
            right: "0.5rem",
            fontWeight: "bold",
          }}
          component={Link}
          to="/hamdard/login"
        >
          Log in
        </Button>
      </Grid>

      <Grid
        className={classes.typography}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          lg={12}
          sm={12}
          style={{
            fontFamily: "Arial",
          }}
        >
          Mental Health and Psychosocial Support (MHPSS) is an evidence-driven
          model which is right-based, scalable and sustainable.
          <br />
          A pilot project is being implemented in Islamabad Capital Territory to
          launch a helpline for MHPSS through intersectoral collaboration.
          <br />
          <a
            rel="noopener noreferrer"
            href="https://www.dawn.com/news/1635182/mental-health-goals"
            target="_blank"
          >
            Read more...
          </a>
        </Grid>
      </Grid>

      <Divider variant="middle" style={{ marginBottom: 4 }} />

      <Grid container direction="row" alignItems="center">
        {step === 1 && (
          <>
            <Grid item xs={12} lg={1} sm={1} className={classes.left}>
              <Box
                style={{
                  height: 65,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  color: "#4c096f",
                  fontWeight: 500,
                  fontSize: 13,
                  fontFamily: "Arial",
                  marginLeft: "1.5rem",
                }}
              >
                Partners
                <br /> forum
              </Box>
              <Box
                style={{ background: "#F5F5F5", height: 55, padding: 3 }}
              ></Box>
            </Grid>
            <Grid item xs={4} lg={2} sm={2} className={classes.center}>
              <Box className={classes.brandContainer}>
                <img src={ministryLogo} alt="logo" className={classes.brand} />
              </Box>
              <Box
                style={{
                  background: "#F5F5F5",
                  height: 55,
                  fontWeight: 500,
                  fontSize: 10,
                  fontFamily: "Arial",
                  padding: 3,
                }}
              >
                Ministry of National Health
                <br />
                Service, Regulations &amp; Coordination
              </Box>
            </Grid>
            <Grid item xs={4} lg={2} sm={3} className={classes.center}>
              <Box className={classes.brandContainer}>
                <img src={ministry} alt="logo" className={classes.brand} />
              </Box>
              <Box
                style={{
                  background: "#F5F5F5",
                  height: 55,
                  fontWeight: 500,
                  fontSize: 10,
                  fontFamily: "Arial",
                  paddingLeft: 20,
                  paddingRight: 20,
                  padding: 3,
                }}
              >
                Federal Directorate
                <br />
                of Education
              </Box>
            </Grid>

            <Grid
              item
              xs={4}
              lg={2}
              sm={3}
              spacing={6}
              className={classes.center}
            >
              <Box className={classes.brandContainer}>
                <img src={ministry3} alt="logo" className={classes.brand} />
              </Box>
              <Box
                style={{
                  background: "#F5F5F5",
                  height: 55,
                  fontWeight: 500,
                  fontSize: 10,
                  fontFamily: "Arial",
                  padding: 3,
                }}
              >
                Poverty Alleviation and
                <br /> Social Safety Division
              </Box>
            </Grid>

            <Grid item xs={4} lg={2} sm={3} className={classes.center}>
              <Box className={classes.brandContainer}>
                <img
                  src={ict}
                  alt="logo"
                  className={classes.brandRectangular}
                />
              </Box>
              <Box
                style={{
                  background: "#F5F5F5",
                  height: 55,
                  fontWeight: 500,
                  fontSize: 10,
                  fontFamily: "Arial",
                  padding: 3,
                }}
              >
                Islamabad Capital
                <br />
                Territory Administration
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              display={{ xs: "block", sm: "none" }}
              component={Box}
            >
              <Box className={classes.brandContainer}>
                <img alt="" className={classes.brandRectangular} />
              </Box>
              <Box
                style={{
                  background: "#F5F5F5",
                  height: 55,
                  fontWeight: 500,
                  fontSize: 10,
                  fontFamily: "Arial",
                  padding: 3,
                }}
              ></Box>
            </Grid>
            <Grid
              item
              xs={4}
              lg={2}
              sm={3}
              spacing={6}
              className={classes.center}
            >
              <Box className={classes.brandContainer}>
                <img
                  src={nust}
                  alt="logo"
                  className={classes.brandRectangular}
                />
              </Box>
              <Box
                style={{
                  background: "#F5F5F5",
                  height: 55,
                  fontWeight: 500,
                  fontSize: 10,
                  fontFamily: "Arial",
                  padding: 3,
                }}
              >
                National University of
                <br />
                Science & Technology
              </Box>
            </Grid>

            {/* Right Arrow button */}

            <Grid item xs={2} lg={1} sm={9}>
              <Box className={classes.brandContainer}>
                <Button
                  onClick={(e) => setStep((prev) => Math.min(prev + 1, 2))}
                >
                  <KeyboardArrowRightIcon />
                </Button>
              </Box>
              <Box
                style={{
                  background: "#F5F5F5",
                  height: 55,
                  fontWeight: 500,
                  fontSize: 10,
                  fontFamily: "Arial",
                  padding: 3,
                }}
              ></Box>
            </Grid>

            {/* Right Arrow button */}
          </>
        )}

        {/* Right Arrow button */}
        {/* <Grid item xs={2} lg={1} sm={0} >
					<Box className={classes.brandContainer}>
						<Button>
							{
							<KeyboardArrowRightIcon
								onClick={e => setStep(prev => Math.min(prev + 1, 3))}
							/>

							}
						</Button>
					</Box>
					<Box style={{ background: "#F5F5F5", height: 55, fontWeight: 500, fontSize: 10, fontFamily: 'Arial', padding: 3 }}>
					</Box>
					</Grid> */}
        {/* Right Arrow button */}

        {/* Left Arrow button */}

        {/* <Grid item xs={2} lg={1} sm={0} >
					<Box className={classes.brandContainer}>
						<Button>
							{
							<KeyboardArrowLeftIcon
								onClick={e => setStep(prev => Math.min(prev + 1, 3))}
							/>

							}
						</Button>
					</Box>
					<Box style={{ background: "#F5F5F5", height: 55, fontWeight: 500, fontSize: 10, fontFamily: 'Arial', padding: 3 }}>
					</Box>
				</Grid> */}

        {/*Left Arrow button */}

        {step === 2 && (
          <>
            <Grid item xs={12} lg={1} sm={1} className={classes.left}>
              <Box
                style={{
                  height: 65,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  color: "#4c096f",
                  fontWeight: 500,
                  fontSize: 13,
                  fontFamily: "Arial",
                  marginLeft: "1.5rem",
                }}
              >
                Partners
                <br /> forum
              </Box>
              <Box
                style={{ background: "#F5F5F5", height: 55, padding: 3 }}
              ></Box>
            </Grid>

            <Grid
              item
              xs={4}
              lg={2}
              sm={3}
              spacing={6}
              className={classes.center}
            >
              <Box className={classes.brandContainer}>
                <img
                  src={pehliKiranSchool}
                  alt="logo"
                  className={classes.brand}
                />
              </Box>
              <Box
                style={{
                  background: "#F5F5F5",
                  height: 55,
                  fontWeight: 500,
                  fontSize: 10,
                  fontFamily: "Arial",
                  padding: 3,
                }}
              >
                Pehli Kiran School
              </Box>
            </Grid>

            <Grid
              item
              xs={4}
              lg={2}
              sm={3}
              spacing={6}
              className={classes.center}
            >
              <Box className={classes.brandContainer}>
                <img src={rozan} alt="logo" className={classes.brand} />
              </Box>
              <Box
                style={{
                  background: "#F5F5F5",
                  height: 55,
                  fontWeight: 500,
                  fontSize: 10,
                  fontFamily: "Arial",
                  padding: 3,
                }}
              >
                Rozan
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              lg={2}
              sm={5}
              spacing={6}
              className={classes.center}
            >
              <Box className={classes.brandContainer}>
                <img src={NIC} alt="logo" className={classes.brand} />
              </Box>
              <Box
                style={{
                  background: "#F5F5F5",
                  height: 55,
                  fontWeight: 500,
                  fontSize: 10,
                  fontFamily: "Arial",
                  padding: 3,
                }}
              >
                Saving 9
              </Box>
            </Grid>

            <Grid
              item
              xs={4}
              lg={2}
              sm={3}
              spacing={6}
              className={classes.center}
            >
              <Box className={classes.brandContainer}>
                <img src={DownClub} alt="logo" className={classes.brand} />
              </Box>
              <Box
                style={{
                  background: "#F5F5F5",
                  height: 55,
                  fontWeight: 500,
                  fontSize: 10,
                  fontFamily: "Arial",
                  padding: 3,
                }}
              >
                Down Syndrome Club
              </Box>
            </Grid>

            <Grid
              item
              xs={4}
              lg={2}
              sm={3}
              spacing={6}
              className={classes.center}
            >
              <Box className={classes.brandContainer}>
                <img src={GDP} alt="logo" className={classes.brand} />
              </Box>
              <Box
                style={{
                  background: "#F5F5F5",
                  height: 55,
                  fontWeight: 500,
                  fontSize: 10,
                  fontFamily: "Arial",
                  padding: 3,
                }}
              >
                Group Development <br />
                Pakistan
              </Box>
            </Grid>

            <Grid item xs={4} lg={1} sm={6}>
              <Box className={classes.brandContainer}>
                <Button
                  onClick={(e) => setStep((prev) => Math.max(prev - 1, 1))}
                >
                  <KeyboardArrowLeftIcon />
                </Button>
              </Box>
              <Box
                style={{
                  background: "#F5F5F5",
                  height: 55,
                  fontWeight: 500,
                  fontSize: 10,
                  fontFamily: "Arial",
                  padding: 3,
                }}
              />
            </Grid>
          </>
        )}
      </Grid>

      {/* Carousel */}
      <Grid container style={{ marginBottom: 20, marginTop: 20 }}>
        <Grid item xs={12} lg={3} sm={12}></Grid>
        <Grid item xs={12} lg={6} sm={12}>
          <Box
            style={{
              height: 40,
              textAlign: "center",
              color: "#4c096f",
              fontWeight: 500,
              fontSize: 16,
            }}
          >
            Newsletters
          </Box>

          <Box
            style={{
              border: "1px solid #A7A7A7",
              borderRadius: "10px",
              padding: 30,
              marginLeft: 12,
              marginRight: 12,
            }}
          >
            <SwipeableTextMobileStepper />
          </Box>
        </Grid>
      </Grid>

      <Divider
        variant="middle"
        style={{ marginBottom: 4, background: "#4c096f" }}
      />

      <Grid
        container
        justifyContent="center"
        direction="row"
        alignItems="center"
        item
        xs={6}
        lg={6}
        sm={6}
        className={classes.CirclesResponsive}
        style={{ display: "grid", marginLeft: "auto", marginRight: "auto" }}
      >
        <Box
          style={{
            width: 100,
            display: "flex",
            marginLeft: "auto",
            justifyContent: "center",
          }}
        >
          <Box paddingBottom={12} />
          <Box
            className={classes.CirclesResponsive}
            style={{ margin: "2.5rem" }}
          >
            <Box style={{ marginLeft: 8 }}>
              <Box
                border={1}
                borderRadius={100}
                padding={2}
                style={{
                  color: "silver",
                  marginRight: "1rem",
                  fontSize: 30,
                  fontFamily: "Arial",
                  width: 75,
                  height: 75,
                  display: " flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "1rem",
                }}
              >
                <span style={{ color: "#4c096f" }}>{mhwCount}</span>
              </Box>
            </Box>
            <Box
              style={{
                color: "#4c096f",
                fontSize: "1rem",
                textAlign: "center",
                fontFamily: "Arial",
                fontWeight: "600",
              }}
              className={classes.bottomButtonsText}
            >
              Registered{" "}
              <span style={{ whiteSpace: "nowrap", marginLeft: "0.4rem" }}>
                Hamdard <span className={classes.bottomButtons}>Force</span>
              </span>
            </Box>
          </Box>

          <Box paddingBottom={4} />
          <Box
            className={classes.CirclesResponsive}
            style={{ margin: "2.5rem" }}
          >
            <Box style={{ marginLeft: 8 }}>
              <Box
                border={1}
                borderRadius={100}
                padding={2}
                style={{
                  color: "silver",
                  marginRight: "1rem",
                  fontSize: 30,
                  fontFamily: "Arial",
                  width: 75,
                  height: 75,
                  display: " flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "1rem",
                }}
              >
                <span style={{ color: "#4c096f" }}>{trainedCount}</span>
              </Box>
            </Box>
            <Box
              style={{
                color: "#4c096f",
                fontSize: "1rem",
                textAlign: "center",
                fontFamily: "Arial",
                fontWeight: "600",
              }}
              className={classes.bottomButtonsText}
            >
              Trained{" "}
              <span style={{ whiteSpace: "nowrap", marginLeft: "0.4rem" }}>
                Hamdard <span className={classes.bottomButtons}>Force</span>
              </span>
            </Box>
          </Box>

          <Box paddingBottom={4} />

          <Box
            className={classes.CirclesResponsive}
            style={{ margin: "2.5rem" }}
          >
            <Box style={{ marginLeft: 8 }}>
              <Box
                border={1}
                borderRadius={100}
                padding={2}
                style={{
                  color: "silver",
                  marginRight: "0.5rem",
                  fontSize: 30,
                  fontFamily: "Arial",
                  width: 75,
                  height: 75,
                  display: " flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span style={{ color: "#4c096f" }}>0</span>
              </Box>
            </Box>
            <Box
              style={{
                color: "#4c096f",
                fontSize: "1rem",
                textAlign: "center",
                fontFamily: "Arial",
                fontWeight: "600",
              }}
            >
              Coming Soon
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid
        alignContent="center"
        alignItems="center"
        container
        style={{
          padding: 8,
          alignItems: "left",
          background: "#F5F5F5",
          marginTop: 12,
          paddingTop: 12,
        }}
        spacing={0}
        className={classes.center}
      >
        <Grid item xs={12} lg={4} sm={4} className={classes.left}>
          <Box>
            <img
              src={Logo}
              alt="logo"
              className={classes.footer}
              style={{
                display: "flex",
                paddingLeft: 30,
                width: "10rem",
                height: "2rem",
              }}
            />
          </Box>
          <Box
            style={{
              fontSize: 13,
              fontFamily: "Arial",
              display: "flex",
              textAlign: "left",
              marginTop: 10,
              paddingLeft: 30,
            }}
          >
            Mental Health Coordination Unit
            <br />
            Ministry of Planning, Development and Special Initiatives
            <br />
            Government of Pakistan
          </Box>
        </Grid>

        <Grid
          className={classes.right}
          item
          xs={8}
          lg={8}
          sm={6}
          style={{
            paddingLeft: 70,
          }}
        >
          <Button
            style={{ textTransform: "capatalize" }}
            size="medium"
            variant="outlined"
            classes={{
              root: `${classes.button} ${classes.footerButton}`,
              label: classes.footer_spacing,
            }}
            startIcon={
              <EmailIcon
                className={classes.footerIcon}
                style={{ marginLeft: "0.6rem", fullWidth: true }}
              />
            }
            href="mailto:mhpss2021@gmail.com"
          >
            Email
          </Button>
          <Button
            style={{ textTransform: "capatalize" }}
            size="medium"
            variant="outlined"
            classes={{
              root: `${classes.button} ${classes.footerButton}`,
              label: classes.footer_spacing,
            }}
            startIcon={
              <TwitterIcon
                className={classes.footerIcon}
                style={{ marginLeft: "0.6rem", fullWidth: true }}
              />
            }
            href="https://twitter.com/MHPSS_PK"
            target="_blank"
          >
            Twitter
          </Button>
          <Button
            style={{ textTransform: "capatalize" }}
            size="medium"
            variant="outlined"
            classes={{
              root: `${classes.button} ${classes.footerButton}`,
              label: classes.footer_spacing,
            }}
            startIcon={
              <FacebookIcon
                className={classes.footerIcon}
                style={{ marginLeft: "0.6rem", fullWidth: true }}
              />
            }
            href="https://www.facebook.com/MHPSSPakistan/"
            target="_blank"
          >
            Facebook
          </Button>

          <Button
            style={{ textTransform: "capatalize" }}
            size="medium"
            variant="outlined"
            classes={{
              root: `${classes.button} ${classes.footerButton}`,
              label: classes.footer_spacing,
            }}
            startIcon={
              <LinkedInIcon
                className={classes.footerIcon}
                style={{ marginLeft: "0.6rem", fullWidth: true }}
              />
            }
            href="https://www.linkedin.com/company/mhpss"
            target="_blank"
          >
            LinkedIn
          </Button>

          <Button
            style={{ textTransform: "capatalize" }}
            size="medium"
            variant="outlined"
            classes={{
              root: `${classes.button} ${classes.footerButton}`,
              label: classes.footer_spacing,
            }}
            startIcon={
              <YouTubeIcon
                className={classes.footerIcon}
                style={{ marginLeft: "0.6rem", fullWidth: true }}
              />
            }
            href="https://www.youtube.com/channel/UCJmAjKKjZVAZx2NczsdTV9Q"
            target="_blank"
          >
            Youtube
          </Button>
        </Grid>
      </Grid>

      <Grid className={classes.right} item xs={12} lg={12} sm={12}>
        <Box
          style={{
            color: "#4c096f",
            fontSize: "0.75rem",
            fontFamily: "Arial",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          © 2021 MHPSS. All rights reserved.
        </Box>
      </Grid>
    </div>
  );
};

export default Root;

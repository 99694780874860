import { columns } from "./GridHeadings";
import XLSX from "xlsx";
export const handleExport = (rows) => {
  const source = rows.map((cases) => cases.source);
  const exactSource = source.every((str, index, array) => str === array[0]);
  const headerNames = columns.map((col) => col.headerName);
  const worksheet = XLSX.utils.json_to_sheet(rows);
  XLSX.utils.sheet_add_aoa(worksheet, [headerNames], {
    origin: "A1",
  });
  const workbook = XLSX.utils.book_new();
  const fileName = exactSource
    ? `${source[0]} Data.xlsx`
    : "All Cases Data.xlsx";
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet");
  XLSX.writeFile(workbook, fileName, { compression: true });
};

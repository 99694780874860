import { Button, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';

const Step1 = ({ classes, nextStep, state, changeState, areaOptions }) => {
	return (
		<>
			<form className={classes.form}>
				<FormControl
					component='fieldset'
					className={classes.formControl}
					fullWidth
					style={{
						backgroundColor: "#EAEAEA",
						border: '2px solid #cec9de',
						borderRadius: '7px',
					}}
				>

					<RadioGroup
						row
						style={{
							marginTop: '0.5rem',
							backgroundColor: 'white',
							width: '100%',
							borderRadius: '5px',
							display: 'flex',
							marginBottom: '0.5rem',
							padding: '0.5rem',
						}}
					>
						<FormControlLabel
							value="PSYCHIATRY"
							control={
								<Radio
									checked={state.type === 'PSYCHIATRY'}
									onChange={e => changeState({ type: e.currentTarget.value })}
									color='primary'
								/>
							}
							label="PSYCHIATRY"
							classes={{
								label: clsx(classes.uppercase, classes.bold)
							}}
						/>

						<FormControlLabel
							value="PSYCHOLOGY/MENTAL HEALTH"
							control={
								<Radio
									checked={state.type === 'PSYCHOLOGY/MENTAL HEALTH'}
									onChange={e => changeState({ type: e.currentTarget.value })}
									color='primary'
								/>
							}
							label="PSYCHOLOGY / MENTAL HEALTH"
							classes={{
								label: clsx(classes.uppercase, classes.bold)
							}}
						/>
					</RadioGroup>
					{
						state.typeError &&
						<Grid item lg={12}>
							<Typography color='error'>
								{state.typeError}
							</Typography>
						</Grid>
					}
				</FormControl>
			</form>

			<Typography
				component='h1'
				variant='h5'
				className={`${classes.heading} ${classes.uppercase}`}
				style={{
					color: '#59567f',
					textTransform: 'uppercase',
					fontWeight: 900,
				}}
			>
				Organization Details
			</Typography>

			<form className={classes.form} >

				<FormControl
					component='fieldset'
					className={classes.formControl}
					fullWidth
					style={{
						backgroundColor: "#EAEAEA",
						border: '2px solid #cec9de',
						borderRadius: '7px',
					}}
				>

					<Typography
						variant='subtitle1'
						className={classes.formLabel}
						style={{
							fontFamily: 'Arial',
							textTransform: 'uppercase',
							fontSize: '1rem',
							fontWeight: 550
						}}
					>
						Name of Organization
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 100,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='organizationName'
						name='organizationName'
						autoComplete='organizationName'
						value={state.organizationName}
						onChange={e => changeState({ organizationName: e.target.value })}
						error={state.organizationNameError !== ''}
						helperText={state.organizationNameError}
					/>

					<Typography
						variant='subtitle1'
						className={classes.formLabel}
						style={{
							fontFamily: 'Arial',
							textTransform: 'uppercase',
							fontSize: '1rem',
							fontWeight: 550
						}}
					>
						Address of Organization
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 100,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='address'
						name='address'
						autoComplete='address'
						value={state.address}
						onChange={e => changeState({ address: e.target.value })}
						error={state.addressError !== ''}
						helperText={state.addressError}
					/>

					<Typography
						variant='subtitle1'
						className={classes.formLabel}
						style={{
							fontFamily: 'Arial',
							textTransform: 'uppercase',
							fontSize: '1rem',
							fontWeight: 550
						}}
					>
						City
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 100,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='city'
						name='city'
						autoComplete='city'
						value={state.city}
						onChange={e => changeState({ city: e.target.value })}
						error={state.cityError !== ''}
						helperText={state.cityError}
					/>

					<Typography
						variant='subtitle1'
						className={classes.formLabel}
						style={{
							fontFamily: 'Arial',
							textTransform: 'uppercase',
							fontSize: '1rem',
							fontWeight: 550
						}}
					>
						district
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 100,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='district'
						name='district'
						value={state.district}
						onChange={e => changeState({ district: e.target.value })}
						error={state.districtError !== ''}
						helperText={state.districtError}
					/>

					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						Area *
					</Typography>

					<TextField
						className={classes.formField}
						select
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='area'
						name='area'
						value={state.area}
						onChange={e => changeState({ area: e.target.value })}
						error={state.areaError !== ''}
						helperText={state.areaError}
					>
						{

								<MenuItem  disabled selected>
									Please Select Your Area
								</MenuItem>

						}
						{
							areaOptions.map((option, i) => (
								<MenuItem key={`area-${i}`} value={option}>
									{option}
								</MenuItem>
							))
						}
					</TextField>

					<Typography
						variant='subtitle1'
						className={classes.formLabel}
						style={{
							fontFamily: 'Arial',
							textTransform: 'uppercase',
							fontSize: '1rem',
							fontWeight: 550
						}}
					>
						Name of Focal Person
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 100,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='name'
						name='name'
						autoComplete='name'
						value={state.personName}
						onChange={e => changeState({ personName: e.target.value })}
						error={state.personNameError !== ''}
						helperText={state.personNameError}
					/>


					<Typography
						variant='subtitle1'
						className={classes.formLabel}
						style={{
							fontFamily: 'Arial',
							textTransform: 'uppercase',
							fontSize: '1rem',
							fontWeight: 550
						}}
					>
						Email address of Focal Person
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 100,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='email'
						name='email'
						autoComplete='email'
						value={state.email}
						onChange={e => changeState({ email: e.target.value })}
						error={state.emailError !== ''}
						helperText={state.emailError}
					/>

					<Typography
						variant='subtitle1'
						className={classes.formLabel}
						style={{
							fontFamily: 'Arial',
							textTransform: 'uppercase',
							fontSize: '1rem',
							fontWeight: 550
						}}
					>
						Mobile number of Focal Person
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 11,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						placeholder='03xx xxxxxxx'
						id='mobile'
						name='mobile'
						autoComplete='mobile'
						value={state.mobile}
						onChange={e => changeState({ mobile: e.target.value })}
						error={state.mobileError !== ''}
						helperText={state.mobileError}
					/>

				</FormControl>
			</form>

			<Typography
				variant='subtitle1'
				style={{
					color: '#38364c',
					fontSize: '1rem',
					fontWeight: 'bold',
					alignSelf: 'flex-start',
					marginLeft: '2rem'
				}}
			>
				* MUST BE COMPLETED
			</Typography>

			<Button
				style={{
					backgroundColor: '#59567f',
					width: '100px',
					display: 'flex',
					marginLeft: 'auto',
					marginRight: 'auto',
					fontWeight: 900
				}}
				variant='contained'
				color='primary'
				className={classes.submit}
				onClick={nextStep}
			>
				Next
			</Button>
		</>
	)
}

export default Step1;

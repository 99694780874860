import React from "react";
import Button from "@material-ui/core/Button";

import ConfirmModel from "./models/ConfirmModel";

const Model = ({
  formData,
  handleSubmit,
  setErrors,
  buttonText,
  validateNumber,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = async () => {
    const data = formData();
    if (data.numberError || data.passwordError || data.reasonError) {
      return;
    }
    if (open) {
      setOpen(false);
    } else {
      try {
        const res = await validateNumber(data.mobileNumber);
        if (res) {
          if ("error" in res && res.error !== "") {
            setOpen(true);
          }
          if (!res.error) {
            setErrors(
              "Sorry, no account is found with this mobile number. Please enter a valid mobile number."
            );
          } else {
            setErrors("");
          }
        } else {
          throw Error("Internal Server Error");
        }
      } catch (error) {
        console.log(error);
        setErrors(error.message);
        setOpen(false);
      }
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: " #59567f",
          color: "#fafafa",
          fontSize: "0.875rem",
        }}
        onClick={handleClick}
      >
        {buttonText}
      </Button>
      <ConfirmModel
        open={open}
        handleClick={handleClick}
        handleSubmit={() => {
          setOpen((open) => !open);
          handleSubmit();
        }}
      />
    </div>
  );
};

export default Model;

import { Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useState } from "react";
import CaseCard from "./CaseCard";
const CardView = ({ rows, onUpdate, onDelete, authData }) => {
  const [page, setPage] = useState(1);
  const cardsPerPage = 6;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <Grid container spacing={2}>
        {rows
          .slice((page - 1) * cardsPerPage, page * cardsPerPage)
          .map((row) => (
            <CaseCard
              key={row.id}
              caseData={row}
              onUpdate={onUpdate}
              onDelete={onDelete}
            />
          ))}
      </Grid>
      <Pagination
        count={Math.ceil(rows.length / cardsPerPage)}
        page={page}
        onChange={handleChangePage}
        style={{ marginTop: "5px", marginBottom: "20px" }}
      />
    </>
  );
};
export default CardView;

import { Container, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import clsx from 'clsx';
import Header from '../components/Header';
import Individual from './Individual/Individual';
import Organization from './Organization/Organization';


const MappingForms = ({ classes }) => {
	const [type, setType] = useState(null);
	const [step, setStep] = useState(1);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Mapping Form</title>
				<meta
					name='description'
					content='Mental Health and Psychosocial Support (MHPSS)'
				/>
			</Helmet>

			<Header />
			<Container component='main' maxWidth='md'>
				{
					step === 1 && (
						<Typography
							component='h1'
							variant='h5'
							className={`${classes.heading} ${classes.uppercase}`}
							style={{
								color: '#59567f',
								textTransform: 'uppercase',
								fontWeight: 900,
								marginTop: '2rem'
							}}
						>
							Mapping of Mental Health Resources
						</Typography>
					)
				}

				{
					step === 1 && (
						<form className={classes.form}>
							<FormControl
								component='fieldset'
								className={classes.formControl}
								fullWidth
								style={{
									backgroundColor: "#EAEAEA",
									border: '2px solid #cec9de',
									borderRadius: '7px',
									fontWeight: 'bold'
								}}
							>
								<Typography
									variant='subtitle1'
									className={classes.mapFormLabel}
								>
									Register as
								</Typography>

								<RadioGroup
									row
									style={{
										marginTop: '0.5rem',
										backgroundColor: 'white',
										width: '100%',
										borderRadius: '5px',
										display: 'flex',
										marginBottom: '0.5rem',
										padding: '0.5rem',
										fontWeight: 'bold'
									}}
								>
									<FormControlLabel
										value="individual"
										control={
											<Radio
												checked={type === 'individual'}
												onChange={e => setType(e.currentTarget.value)}
												color='primary'
											/>
										}
										label="INDIVIDUAL"
										classes={{
											label: clsx(classes.uppercase, classes.bold)
										}}
									/>

									<FormControlLabel
										value="organization"
										control={
											<Radio
												checked={type === 'organization'}
												onChange={e => setType(e.currentTarget.value)}
												color='primary'
											/>
										}
										label="ORGANIZATION"
										classes={{
											label: clsx(classes.uppercase, classes.bold)
										}}
									/>
								</RadioGroup>
							</FormControl>
						</form>
					)
				}

				{
					type && (
						<Typography
							component='h1'
							variant='h5'
							className={`${classes.heading} ${classes.uppercase}`}
							style={{
								color: '#07D5C2',
								textTransform: 'uppercase',
								fontWeight: 600,
								fontSize: '1.3rem',
								margin: '2rem 0'
							}}
						>
							Step {step} of {type === 'individual' ? 3 : 2}
						</Typography>
					)
				}

				{
					type === 'organization' && (
						<Organization
							classes={classes}
							step={step}
							setStep={setStep}
						/>
					)
				}

				{
					type === 'individual' && (
						<Individual
							classes={classes}
							step={step}
							setStep={setStep}
						/>
					)
				}
			</Container>

		</>
	);
};

export default MappingForms;

import React, { useState } from "react";
import GuestLayout from "../layout/GuestLayout";
import { Typography, makeStyles, useTheme } from "@material-ui/core";
import DeleteForm from "../Auth/deleteform/DeleteForm";
import { validateNotBlank, validateMobile } from "../Auth/FormParts/Validation";
import Model from "../Auth/deleteform/Model";
import Success from "../Auth/deleteform/Success";
import { makeRequest, routes } from "../Auth/AuthApi";
const useStyle = makeStyles((theme) => ({
  title: {
    fontSize: "25px",
    color: "#352676",
    letterSpacing: "1px",
    fontWeight: "bolder",
  },
  heading: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "#eaeaea",
    minWidth: "800px",
    fontWeight: "bold",
    fontSize: "1.4rem",
    padding: "10px 0",
    marginBottom: theme.spacing(2),
  },
  subHeading: {
    width: "90%",
    textAlign: "center",
    fontSize: "14px",
    color: "#5D616D",
  },
  note: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "520px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    fontSize: "14px",
    color: "#5D616D",
    marginBottom: "2rem",
  },
  error: {
    color: "#f50057",
    fontWeight: "600",
    marginBottom: "10px",
  },
}));

const DeleteRequest = () => {
  const theme = useTheme();
  const classes = useStyle(theme);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState("");
  const [values, setValues] = useState({
    mobileNumber: "",
    password: "",
    reason_for_deletion: "",
  });

  const handleDeleteRequest = async () => {
    try {
      const response = await makeRequest(
        `${routes.delete_request}`,
        {
          reason: values.reason_for_deletion,
          username: values.mobileNumber,
          password: values.password,
        },
        "POST"
      );
      const jsonres = await response.json();
      if (response.ok) {
        setIsSuccess(true);
      } else {
        throw new Error(jsonres.data.error);
      }
    } catch (error) {
      console.error("DELETE USER REQUEST ERROR: ", error);
      setErrors(error.message);
    }
  };

  const validateNumber = async (number) => {
    try {
      const res = await fetch(`${routes.phone_exist}?phone_no=${number}`);
      const json = await res.json();
      setErrors(json.data?.error || "");
      return json.data;
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeState = (changeState) => {
    changeState.numberError = validateMobile(changeState.mobileNumber);
    changeState.passwordError = validateNotBlank(changeState.password);
    changeState.reasonError = validateNotBlank(changeState.reason_for_deletion);

    setValues((prevState) => {
      return {
        ...prevState,
        ...changeState,
      };
    });

    return {
      ...values,
      numberError: changeState.numberError,
      passwordError: changeState.passwordError,
      reasonError: changeState.reasonError,
    };
  };
  return (
    <>
      <GuestLayout>
        {/* page heading */}
        <Typography
          component="h1"
          variant="h5"
          className={classes.heading}
          style={{
            color: "#352676",
            textTransform: "uppercase",
            fontWeight: 900,
          }}
        >
          {isSuccess ? "Request submitted" : "Request to delete my data"}
        </Typography>
        {/* subheading */}
        {isSuccess ? (
          <Success />
        ) : (
          <>
            <Typography component="p" className={classes.subHeading}>
              Thank you for reaching out to us regarding your data deletion
              request. Please fill out the form below to submit your request.
            </Typography>
            <DeleteForm values={values} setValues={handleChangeState} />
            <Typography component="p" className={classes.note}>
              {errors && <div className={classes.error}>{errors}</div>}
              <strong>Note: </strong>Once you submit your request, it will be
              processed by our admin team. If you are registered as part of our
              mental health team (trained as Hamdard Force or in mhGAP) and you
              have submitted case(s), please note that the case data will not be
              deleted.
            </Typography>
            <Model
              validateNumber={validateNumber}
              setErrors={setErrors}
              formData={() => handleChangeState(values)}
              handleSubmit={handleDeleteRequest}
              buttonText={"Submit Request"}
            />
          </>
        )}
      </GuestLayout>
    </>
  );
};

export default DeleteRequest;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmModel = ({ open, handleClick, handleSubmit }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClick}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Are you sure you want to delete your data from MHPSS portal?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick} color="primary">
          No, I am not sure
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          style={{
            backgroundColor: " #59567f",
            color: "#fafafa",
            fontSize: "0.875rem",
          }}
        >
          Yes, I am sure
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModel;

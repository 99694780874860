import { routes, makeAuthRequest } from '../Auth/AuthApi';
import dayjs from 'dayjs';

const saveAuthData = (authData) => {
	localStorage.setItem('lastLoginAt', new Date());
	localStorage.setItem('authData', JSON.stringify(authData));
};

const getAuthData = () => {
	const currentTime = dayjs();
	const authData = JSON.parse(localStorage.getItem('authData'));

	// Expire the token after 2 hours
	if (dayjs(localStorage.getItem('lastLoginAt')).add(2, 'hour') <= currentTime) {
		makeAuthRequest(routes.log_out, {}, 'DELETE', authData);
		localStorage.clear();
		return null;
	}
	else {
		return authData
	}
}

const saveCourseData = (courseData) => {
	localStorage.setItem('courseData', JSON.stringify(courseData));
};

const getCourseData = () => {
	return JSON.parse(localStorage.getItem('courseData'))
};

export {
	saveAuthData,
	getAuthData,
	saveCourseData,
	getCourseData
};

export const CaseCardStyle = {
  cardStyles: {
    backgroundColor: "#eaeaea",
    display: "flex",
    height: "100%",
  },
  AddEditButtonStyle: {
    backgroundColor: "#5b2b6f",
    color: "white",
  },
  AddEditButtonStyleInsideModal: {
    width: "100%",
    backgroundColor: "#5b2b6f",
    color: "white",
    marginTop: "10px",
    borderRadius: "8px",
    fontWeight: "bold",
    fontSize: "1rem",
  },
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "300px",
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  },
};

import React from 'react'
import {Container, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';
import watermark from '../img/watermark.png';

const useStyle= makeStyles(theme => ({
    heading: {
		textAlign: 'center',
		backgroundColor: '#EAEAEA',
		fontWeight: 'bold',
		fontSize: '1.4rem',
		padding: '5px 0 5px 0',
		marginBottom: theme.spacing(2),
        justifyContent: 'center',
	},
    uppercase: {
		textTransform: 'uppercase'
	},
    watermark: {
		backgroundImage: `url(${watermark})`,
		backgroundRepeat: 'no-repeat',
		backgroundColor: 'transparent',
		backgroundPosition: 'center',
		padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
	},
}))
const Team = () => {
    const classes= useStyle();
    return (
        <div>
            <Helmet>
				<meta charSet="utf-8" />
				<title>Team</title>
				<meta
					name='description'
					content='Team Description'
				/>
			</Helmet>
            <Container className={classes.watermark}>
            <Container style={{alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: '2rem',
                                width: '13rem'
                            }}>
                    <Typography
						component='h1'
						variant='h5'
						className={`${classes.heading} ${classes.uppercase}`}
					>
						Team
					</Typography>
            </Container>
            <ol style={{marginLeft: '4.25rem'}}>
                <li>
                <Typography style={{paddingLeft: '1rem'}}>
                <Typography style={{fontWeight: 'bold'}}>
                    Dr Asma Humayun <br/>
                    </Typography>
                    MBBS, MRCPsych, MMedSci (UK) <br/>
                    National Technical Advisor <br/>
                    Mental Health Coordination Unit <br/>
                    Ministry of Planning, Development and Special Initiatives <br/>
                    Islamabad <br/>
                    </Typography>
                    </li>
                    <br/>

                <li>
                <Typography style={{paddingLeft: '1rem'}}>
                <Typography style={{fontWeight: 'bold'}}>
                    Dr Israr ul Haq <br/>
                    </Typography>
                    MBBS, FCPS <br/>
                    Assistant Professor of Psychiatry <br/>
                    Fazaia Medical College <br/>
                    Islamabad <br/>
                    </Typography>
                    </li>
                    <br/>

                <li>
                <Typography style={{paddingLeft: '1rem'}}>
                <Typography style={{fontWeight: 'bold'}}>
                    Sarah Nasir <br/>
                    </Typography>
                    MS Clinical Psychology <br/>
                    Mental Health Consultant <br/>
                    Mental Health Coordination Unit <br/>
                    Ministry of Planning, Development and Special Initiatives <br/>
                    Islamabad <br/>
                    </Typography>
                    </li>
                    <br/>

                <li>
                <Typography style={{paddingLeft: '1rem'}}>
                <Typography style={{fontWeight: 'bold'}}>
                    Dr Faisal Rashid <br/>
                    </Typography>
                    MBBS, FCPS <br/>
                    Associate Professor of Psychiatry <br/>
                    Al Nafees Medical College and Hospital <br/>
                    Isra University <br/>
                    Islamabad <br/>
                    </Typography>
                    </li>
                    <br/>

            </ol>
            </Container>
        </div>
    )
}

export default Team

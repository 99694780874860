import React from 'react';
import { Avatar, Container, Typography } from "@material-ui/core";
import { Helmet } from 'react-helmet-async';
import logo from '../../img/mhpss.png';


const Counsellors = ({ classes }) => {

	return (
		<Container component='main' className={classes.criteriaPage}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Inclusion Criteria for Counsellors</title>
				<meta
					name='description'
					content='Inclusion Criteria for Counsellors'
				/>
			</Helmet>

			<Avatar
				className={`${classes.avatar} ${classes.pageLogo}`}
				alt='logo'
				src={logo}
			/>

			<Container className={classes.watermark}>
				<Typography
					id='counsellors'
					component='h3'
					variant='h5'
					gutterBottom
				>
					Tier 2: Counsellors
				</Typography>

				<Typography
					component='div'
					variant='body1'
					className={classes.listBody}
					gutterBottom
				>
					<ol>
						<li>
							Fresh graduates of Clinical Psychology programs (ADCP, MS, or higher)
						</li>
						<li>
							Should be motivated to contribute towards MHPSS project voluntarily
						</li>
						<li>
							Should have effective communication skills
						</li>
						<li>
							Should be willing to seek training and supervision
						</li>
						<li>Must uphold high ethical standards in their own practice</li>
						<li>Must have good standing in their professional circle</li>
						<li>Should be interested in continuous professional development</li>
						<li>Two references needed</li>
						<li>Should be based in Islamabad</li>
					</ol>
				</Typography>
			</Container>
		</Container>
	);
};

export default Counsellors;

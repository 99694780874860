import { Button, ButtonGroup, Container, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const Signup = ({ classes }) => {
  return (
    <Container component="main" maxWidth="sm">
      <div
        className={`${classes.paper} ${classes.paperBackground}`}
        style={{ marginBottom: "1rem" }}
      >
        <Typography
          variant="h6"
          align="center"
          style={{ marginBottom: "1rem" }}
        >
          Please select one of these to continue:
        </Typography>
        <ButtonGroup
          orientation="vertical"
          variant="contained"
          color="primary"
          className={classes.links}
          style={{ boxShadow: "none" }}
        >
          <Button
            style={{
              backgroundColor: "#eaeaea",
              marginBottom: "2rem",
              color: "black",
              fontWeight: 900,
              fontSize: "large",
              border: "2px solid #c4c4c4",
              borderRadius: "2px",
            }}
            component={RouterLink}
            className={classes.uppercase}
            to="/sign_up/doctor"
          >
            Medical Doctor
          </Button>

          <Button
            style={{
              backgroundColor: "#eaeaea",
              marginBottom: "2rem",
              color: "black",
              fontWeight: 900,
              fontSize: "large",
              border: "2px solid #c4c4c4",
              borderRadius: "2px",
            }}
            component={RouterLink}
            className={classes.uppercase}
            to="/sign_up/clinical_psychologist"
          >
            Psychologist
          </Button>

          <Button
            style={{
              backgroundColor: "#eaeaea",
              color: "black",
              fontWeight: 900,
              fontSize: "large",
              border: "2px solid #c4c4c4",
              borderRadius: "2px",
            }}
            component={RouterLink}
            className={classes.uppercase}
            to="/sign_up/psychiatrist"
          >
            Psychiatrist
          </Button>
        </ButtonGroup>
      </div>
    </Container>
  );
};

export default Signup;

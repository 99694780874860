import { Typography } from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { makeRequest, routes } from '../../Auth/AuthApi';
import {
	validateAtLeastOne, validateEmail, validateExists, validateMobile, validateName, validateNum, validateOptions
} from '../../Auth/FormParts/Validation';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';


const Individual = ({
	classes,
	step,
	setStep,
	areaOptions,
	professionOptions,
	ageOptions,
	genderOptions,
	subProfessions,
	yearsOfExperienceOptions,
	positionOptions,
	academicOptions,
	nonAcademicOptions,
	hospitalTypeOptions,
	diplomaDegreeOptions
}) => {
	const history = useHistory();

	const [firstState, setFirstState] = useState({
		area: '',
		areaError: '',
		profession: '',
		professionError: '',
		name: '',
		nameError: '',
		gender: '',
		genderError: '',
		age: '',
		ageError: '',
		email: '',
		emailError: '',
		mobile: '',
		mobileError: '',
		city: '',
		cityError: '',
		district: '',
		districtError: ''
	});

	const [secondState, setSecondState] = useState({
		profession: '',
		professionError: '',
		diplomaDegree: '',
		diplomaDegreeError: '',
		institution: '',
		institutionError: '',
		yearsOfExperience: '',
		yearsOfExperienceError: ''
	});

	const [thirdState, setThirdState] = useState({
		isPublic: false,
		isPrivate: false,
		isNgo: false,
		sectorError: '',
		showPrivateClinic: false,
		showPrivateHospital: false,
		publicHospital: {
			type: '',
			typeError: '',
			position: '',
			positionError: '',
			positionSpec: '',
			positionSpecError: '',
			name: '',
			nameError: '',
			address: '',
			addressError: ''
		},
		privateHospital: {
			type: '',
			typeError: '',
			position: '',
			positionError: '',
			positionSpec: '',
			positionSpecError: '',
			name: '',
			nameError: '',
			address: '',
			addressError: ''
		},
		ngo: {
			name: '',
			nameError: '',
			address: '',
			addressError: ''
		},
		clinic: {
			name: '',
			nameError: '',
			address: '',
			addressError: ''
		},
	});

	const changeFirstState = (changes) => {
		changes.areaError = validateOptions(changes.area, areaOptions);
		changes.professionError = validateOptions(changes.profession, professionOptions);
		changes.nameError = validateName(changes.name);
		changes.genderError = validateOptions(changes.gender, genderOptions);
		changes.ageError = validateNum(changes.age, 20, 80);
		changes.emailError = validateEmail(changes.email);
		changes.mobileError = validateMobile(changes.mobile);
		changes.cityError = validateExists(changes.city);
		changes.districtError = validateExists(changes.district);

		setFirstState(prev => ({
			...prev,
			...changes
		}))

		return {
			areaError: changes.areaError,
			professionError: changes.professionError,
			nameError: changes.nameError,
			genderError: changes.genderError,
			ageError: changes.ageError,
			emailError: changes.emailError,
			mobileError: changes.mobileError,
			cityError: changes.cityError,
			districtError: changes.districtError,
		}
	};

	const changeSecondState = (changes) => {
		if (firstState.profession in subProfessions) {
			changes.professionError = validateOptions(
				changes.profession,
				subProfessions[firstState.profession]
			);
		}

		changes.diplomaDegreeError = validateExists(changes.diplomaDegree);
		changes.yearsOfExperienceError = validateOptions(
			changes.yearsOfExperience,
			yearsOfExperienceOptions
		);
		changes.institutionError = validateExists(changes.institution);

		setSecondState(prev => ({
			...prev,
			...changes
		}))

		return {
			professionError: changes.professionError,
			diplomaDegreeError: changes.diplomaDegreeError,
			institutionError: changes.institutionError,
			yearsOfExperienceError: changes.yearsOfExperienceError
		}
	};


	const changeThirdState = (changes) => {
		changes.sectorError = validateAtLeastOne(
			changes.isNgo ?? thirdState.isNgo,
			changes.isPrivate ?? thirdState.isPrivate,
			changes.isPublic ?? thirdState.isPublic
		);
		const errors = [changes.sectorError];

		['ngo', 'clinic'].forEach(key => {
			if (key in changes && (
				(key === 'ngo' && thirdState.isNgo) ||
				(key === 'clinic' && thirdState.showPrivateClinic)
			)) {
				changes[key].nameError = validateExists(changes[key].name);
				changes[key].addressError = validateExists(changes[key].address);
				errors.push(changes[key].nameError);
				errors.push(changes[key].addressError);
			}
		});
		['publicHospital', 'privateHospital'].forEach(key => {
			if (key in changes &&
				(
					(key === 'publicHospital' && thirdState.isPublic) ||
					(key === 'privateHospital' && thirdState.isPrivate && thirdState.showPrivateHospital)
				)
			) {
				changes[key].nameError = validateExists(changes[key].name);
				changes[key].addressError = validateExists(changes[key].address);
				changes[key].typeError = validateOptions(
					changes[key].type,
					hospitalTypeOptions
				);
				changes[key].positionError = validateOptions(
					changes[key].position,
					positionOptions
				);
				changes[key].positionSpecError = validateExists(
					changes[key].positionSpec
				);
				errors.push(changes[key].nameError);
				errors.push(changes[key].addressError);
				errors.push(changes[key].typeError);
				errors.push(changes[key].positionError);
				errors.push(changes[key].positionSpecError);
			}
		});

		setThirdState(prev => ({
			isPublic: changes.isPublic ?? prev.isPublic,
			isPrivate: changes.isPrivate ?? prev.isPrivate,
			isNgo: changes.isNgo ?? prev.isNgo,
			sectorError: changes.sectorError ?? prev.sectorError,
			showPrivateClinic: changes.showPrivateClinic ?? prev.showPrivateClinic,
			showPrivateHospital: changes.showPrivateHospital ?? prev.showPrivateHospital,
			publicHospital: {
				type: changes.publicHospital?.type ?? prev.publicHospital.type,
				typeError: changes.publicHospital?.typeError ?? prev.publicHospital.typeError,
				position: changes.publicHospital?.position ?? prev.publicHospital.position,
				positionError: changes.publicHospital?.positionError ?? prev.publicHospital.positionError,
				positionSpec: changes.publicHospital?.positionSpec ?? prev.publicHospital.positionSpec,
				positionSpecError: changes.publicHospital?.positionSpecError ?? prev.publicHospital.positionSpecError,
				name: changes.publicHospital?.name ?? prev.publicHospital.name,
				nameError: changes.publicHospital?.nameError ?? prev.publicHospital.nameError,
				address: changes.publicHospital?.address ?? prev.publicHospital.address,
				addressError: changes.publicHospital?.addressError ?? prev.publicHospital.addressError,
			},
			privateHospital: {
				type: changes.privateHospital?.type ?? prev.privateHospital.type,
				typeError: changes.privateHospital?.typeError ?? prev.privateHospital.typeError,
				position: changes.privateHospital?.position ?? prev.privateHospital.position,
				positionError: changes.privateHospital?.positionError ?? prev.privateHospital.positionError,
				positionSpec: changes.privateHospital?.positionSpec ?? prev.privateHospital.positionSpec,
				positionSpecError: changes.privateHospital?.positionSpecError ?? prev.privateHospital.positionSpecError,
				name: changes.privateHospital?.name ?? prev.privateHospital.name,
				nameError: changes.privateHospital?.nameError ?? prev.privateHospital.nameError,
				address: changes.privateHospital?.address ?? prev.privateHospital.address,
				addressError: changes.privateHospital?.addressError ?? prev.privateHospital.addressError,
			},
			ngo: {
				name: changes.ngo?.name ?? prev.ngo.name,
				address: changes.ngo?.address ?? prev.ngo.address,
				nameError: changes.ngo?.nameError ?? prev.ngo.nameError,
				addressError: changes.ngo?.addressError ?? prev.ngo.addressError,
			},
			clinic: {
				name: changes.clinic?.name ?? prev.clinic.name,
				address: changes.clinic?.address ?? prev.clinic.address,
				nameError: changes.clinic?.nameError ?? prev.clinic.nameError,
				addressError: changes.clinic?.addressError ?? prev.clinic.addressError,
			}
		}))

		return errors;
	}

	const nextStep = () => {
		if (step === 1) {
			const errors = changeFirstState(firstState);

			if (
				!errors.areaError && !errors.professionError &&
				!errors.nameError && !errors.genderError &&
				!errors.ageError && !errors.emailError &&
				!errors.mobileError && !errors.cityError &&
				!errors.cityError
			) {
				setStep(prev => prev + 1);
			}
		}
		else if (step === 2) {
			const errors = changeSecondState(secondState);

			if (
				(!(firstState.profession in subProfessions) || !errors.professionError) &&
				!errors.diplomaDegreeError && !errors.institutionError &&
				!errors.yearsOfExperienceError
			) {
				setStep(prev => prev + 1);
			}
		}
	};

	const prevStep = () => setStep(prev => prev - 1);

	const register = async () => {
		const errors = changeThirdState(thirdState);
		if (errors.every(e => e === '')) {
			const data = {
				area: firstState.area,
				profession: firstState.profession,
				qualification_as: firstState.profession,
				name: firstState.name,
				gender: firstState.gender,
				age: firstState.age,
				email: firstState.email,
				phone_number: firstState.mobile,
				city: firstState.city,
				district: firstState.district,
				sub_profession: secondState.profession,
				degree: secondState.diplomaDegree,
				institute: secondState.institution,
				experience: secondState.yearsOfExperience,
				is_public: thirdState.isPublic,
				is_private: thirdState.isPrivate,
				public_hospital_type: (
					thirdState.publicHospital.type
				),
				public_hospital_name: (
					thirdState.publicHospital.name
				),
				public_hospital_address: (
					thirdState.publicHospital.address
				),
				public_position_type: (
					thirdState.publicHospital.position
				),
				public_position: (
					thirdState.publicHospital.positionSpec
				),
				private_hospital_type: (
					thirdState.privateHospital.type
				),
				private_hospital_name: (
					thirdState.privateHospital.name
				),
				private_hospital_address: (
					thirdState.privateHospital.address
				),
				private_position_type: (
					thirdState.privateHospital.position
				),
				private_position: (
					thirdState.privateHospital.positionSpec
				),
				clinic_name: thirdState.clinic.name,
				clinic_address: thirdState.clinic.address,
				ngo_name: thirdState.ngo.name,
				ngo_address: thirdState.ngo.address
			};

			try {
				const res = await makeRequest(routes.register_individual, data, 'POST');
				const resJson = await res.json();
				if (res.ok) {
					history.push('/sign_up/success');
					return true
				}
				else {
					console.error(resJson);
					return false;
				}
			}
			catch (e) {
				console.error(e);
				return false;
			}
		}
	};

	return (
		<>
			<Typography
				component='h1'
				variant='h5'
				className={`${classes.heading} ${classes.uppercase} ${classes.mapHeading}`}
				style={{fontWeight: 900}}
			>
				Individual
			</Typography>

			{
				step === 1 ?
					<Step1
						state={firstState}
						changeState={changeFirstState}
						classes={classes}
						nextStep={nextStep}
						areaOptions={areaOptions}
						professionOptions={professionOptions}
						ageOptions={ageOptions}
						genderOptions={genderOptions}
					/>
					:
					step === 2 ?
						<Step2
							classes={classes}
							nextStep={nextStep}
							prevStep={prevStep}
							profession={firstState.profession}
							state={secondState}
							changeState={changeSecondState}
							subProfessions={subProfessions}
							yearsOfExperienceOptions={yearsOfExperienceOptions}
							diplomaDegreeOptions={diplomaDegreeOptions}
						/>
						:
						step === 3 ?
							<Step3
								classes={classes}
								profession={firstState.profession}
								prevStep={prevStep}
								register={register}
								state={thirdState}
								changeState={changeThirdState}
								positionOptions={positionOptions}
								academicOptions={academicOptions}
								nonAcademicOptions={nonAcademicOptions}
								hospitalTypeOptions={hospitalTypeOptions}
							/>
							:
							<Typography>
								There was an error with the form
							</Typography>
			}
		</>
	);
};


Individual.defaultProps = {
	areaOptions: [
		'ICT',
		'Punjab',
		'Sindh',
		'Balochistan',
		'KPK',
		'AJK',
		'GB',
		'Armed Forces'
	],
	professionOptions: [
		'Psychiatrist',
		'Psychologist',
		'Other Mental Health Professional'
	],
	ageOptions: [...Array(81).keys()].filter(num => num >= 20),
	genderOptions: [
		'Male',
		'Female'
	],
	subProfessions: {
		'Psychologist': [
			'Clinical Psychologist',
			'Educational Psychologist',
			'Other Psychologist'
		],
		'Other Mental Health Professional': [
			'Social Work',
			'Occupational Therapy',
			'Speech Therapy',
			'Psychiatric Nurse',
			'Other'
		]
	},
	yearsOfExperienceOptions: [
		'Less than 5 years',
		'5-10 years',
		'More than 10 years'
	],
	hospitalTypeOptions: [
		'Secondary care hospital (District)',
		'Tertiary care ',
		'Tertiary care + teaching hospital '
	],
	positionOptions: [
		'Academic',
		'Non-Academic'
	],
	academicOptions: [
		'Professor',
		'Senior Registrar',
		'Assistant Professor',
		'Associate Professor'
	],
	nonAcademicOptions: [
		'Consultant'
	],
	diplomaDegreeOptions: [
		'FCPS',
		'MCPS',
		'MRCPsych',
		'American Board of Psychiatry and Neurology(ABPN)',
		'Other'
	]
};

export default Individual;

import { Button, CircularProgress, Container, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';


const useStyle= makeStyles(theme => ({
	root: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '2rem',
		backgroundColor: '#eaeaea',
		border: '2px solid #cec9de',
		borderRadius: '7px',
		paddingBottom: '2rem'
	},
	textFeild: {
		backgroundColor: '#fafafa',
		border: '2px solid #cec9de',
		borderRadius: '6px'
	},
	label:{
		color: '#07D5C2',
		padding: '0.5rem',
		fontSize: '1rem',
		fontWeight: 'bold',
		textAlign: 'left',
		textTransform: 'uppercase'
	},
	alert_appointment:{
		color: '#ff2301',
		textAlign: 'left',
		textTransform: 'uppercase',
		padding: '0.5rem',
		fontSize: '1rem'
	},
	checkboxes: {
		display: 'flex',
		marginTop: theme.spacing(1),
		flexWrap:'wrap',
	},
	checkboxContainer: {
		backgroundColor: 'white',
		borderRadius: '7px',
		border: `1px solid #cec9de`,
		padding: theme.spacing(2),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		alignItems: 'center'
	},
	topContainer: {
		backgroundColor: 'white',
		borderRadius: '7px',
		border: `1px solid #cec9de`,
		padding: theme.spacing(2),
		marginBottom: theme.spacing(1),
		alignItems: 'center'
	},
	formLayout: {
		[theme.breakpoints.down('lg')]: {
			maxWidth: '68%',

		},
		[theme.breakpoints.down('md')]: {
			maxWidth: '75%'
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%'
		}
	},
	radioLayout: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		[theme.breakpoints.down('xs')]: {
			flexWrap: 'wrap',
		}
	},
	radioButton: {
		marginLeft: '1rem',
		[theme.breakpoints.down('md')]: {
			marginLeft: '0.8rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0.8rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginLeft: '-1px',
		}
	},
}))

const BookAppointmentForm = ({ values, setValues, submit, loading }) => {
	const [showForm, setShowForm] = useState(null);
	const classes= useStyle();

	return (

		<Container component='main' className= {classes.formLayout} style={{paddingBottom: '2rem'}}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Book Appointment</title>
				<meta
					name='description'
					content='Book Appointment Form.'
				/>
			</Helmet>
			<form className={classes.root}
			// style={{marginLeft: '10rem', marginRight: '10rem'}}
				maxWidth= '80%'
			>
				<FormControl style={{width: '100%', padding: '0rem'}}>
					<Typography
						variant= 'subtitle1'
						className={classes.label}

					>
						Are You based in islamabad?
					</Typography>

					<RadioGroup row aria-label="location" name="row-radio-buttons-group" className={classes.topContainer}>

					<FormControlLabel value="location"
							control={<Radio
								checked={showForm}
								onChange={e => setShowForm(true)}
								color='primary'
							/>} label="Yes" />

					<FormControlLabel value="location"
							control={<Radio
								checked={showForm === false}
								onChange={e => setShowForm(false)}
								color='primary'
							/>} label="No" />

					</RadioGroup>
					{
						showForm === false && (
							<Typography
								variant='subtitle1'
								className={classes.alert_appointment}
							>
								THIS SERVICE IS AVAILABLE IN ISLAMABAD ONLY
							</Typography>
						)
					}
				</FormControl>
			</form>
			{
				showForm &&
				<>
					<form className={classes.root}
						maxWidth= '80%'
					>
						<FormControl style={{width: '100%', padding: '0rem'}}>

							<Typography
								variant= 'h6'
								className={classes.label}
							>
								Name
							</Typography>

							<TextField
								variant= "outlined"
								fullWidth
								required
								value= {values.name}
								error={values.nameError !== ''}
								helperText={values.nameError}
								onChange={e => {
									setValues( {
											name: e.target.value
									})
								}}
								className={classes.textFeild}
								style={{border: '2px solid #cec9de',
								borderRadius: '6px'}}
							/>
							<Typography
								variant= 'h6'
								className={classes.label}
							>
								Age
							</Typography>
							<TextField
								select
								variant='outlined'
								margin='normal'
								required
								fullWidth
								autoComplete='age'
								placeholder={`16-70`}
								value={values.age}
								error={values.ageError !== ''}
								helperText={values.ageError}
								onChange={e => {
									setValues( {
											age: e.target.value
									});
								}}
								className={classes.textFeild}
							>
								{
									[...Array(71).keys()].filter(num => num >= 16).map((num, i) => {
										return (
											<MenuItem key={i} value={num}>
												{num}
											</MenuItem>
										);
									})
								}
							</TextField>
							<Typography
								variant= 'h6'
								className={classes.label}
							>
								Occupation
							</Typography>
							<TextField
							variant="outlined"
							fullWidth
							required
							value={values.occupation}
							error={values.occupationError !== ''}
							helperText={values.occupationError}
							onChange={e => {
								setValues( {
										occupation: e.target.value
								})
							}}
							className={classes.textFeild}

							/>
							<Typography
								variant= 'h6'
								className={classes.label}
							>
								Contact Number
							</Typography>
							<TextField
							InputProps={{
								inputProps: {
									maxLength: 11
								}
							}}
							variant="outlined"
							fullWidth
							required
							autoComplete='phone_no'
							placeholder='03xxxxxxxxx'
							value={values.phone_no}
							error={values.phoneNumberError !== ''}
							helperText={values.phoneNumberError}
							onChange={e => {
								setValues( {
									phone_no: e.target.value
								})
							}}
							className={classes.textFeild}
							/>
							<Typography
								variant= 'h6'
								className={classes.label}
							>
								Email ID
							</Typography>
							<TextField
							variant="outlined"
							fullWidth
							required
							value={values.email}
							error={values.emailError !== ''}
							helperText={values.emailError}
							onChange={e => {
								setValues({
										email: e.target.value
								})
							}}
							className={classes.textFeild}
							/>
							<Typography
								variant= 'h6'

								className={classes.label}
							>
								PLEASE DESCRIBE YOUR PROBLEM BRIEFLY
							</Typography>

							<TextField
								variant="outlined"
								placeholder='(Max 200 words)'
								fullWidth
								required
								multiline
								rows={6}
								value={values.description}
								error={values.descriptionError !== ''}
								helperText={values.descriptionError}
								onChange={e => {
									setValues({
										description: e.target.value
									})
								}}
									className={classes.textFeild}
							/>
						</FormControl>
					</form>
					<div style={{width: '100%',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center'
						}}>
					<Button
						style={{
							backgroundColor: ' #59567f',
							color: '#fafafa',
							fontSize: '0.875rem',
							width: '6rem',
							margin: '2rem auto 0 auto',
						}}
						onClick={submit}
						disabled={loading}
					>
						{
							loading ?
								<CircularProgress
									color="primaryText"
									size={24}
								/>
								:
								'Save'
						}
					</Button>
					</div>

				</>
			}
		</Container>

	)
}

export default BookAppointmentForm


import { Backdrop, Button, CircularProgress, Container, Fade, makeStyles, Modal, Typography } from '@material-ui/core';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
	modal: {
		backgroundColor: '#eaeaea',
		border: '2px solid #cec9de',
		margin: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		minHeight: '50%',
		borderRadius: '1rem',
		paddingTop: theme.spacing(2)
	},
}));

const LanguageConfirmation = ({ open, language, changeLanguage, changeLanguageConfirmation }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);

	const onChangeLanguage = () => {
		setLoading(true);
		changeLanguage();
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			style={{
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			<Fade in={open}>
				<Container
					component='main'
					maxWidth='sm'
					className={classes.modal}
				>
					<Typography
						variant='body1'
						gutterBottom
					>
						Do you want to change from {language} course
						to {language === 'Urdu' ? 'English' : 'Urdu'} course?
						<br />
						Click Yes to Confirm.
					</Typography>

					<div>
						<Button
							style={{
								backgroundColor: '#9487bc',
								color: '#fafafa',
								fontSize: '0.875rem',
								width: '14rem',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								margin: '0 auto',
								textTransform: 'uppercase',
								marginTop: '0.875rem'
							}}
							onClick={onChangeLanguage}
							disabled={loading}
						>
							{
								loading ?
									<CircularProgress
										color="primaryText"
										size={24}
									/>
									:
									'Yes'
							}

						</Button>
						<Button
							style={{
								backgroundColor: '#9487bc',
								color: '#fafafa',
								fontSize: '0.875rem',
								width: '14rem',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								margin: '0 auto',
								textTransform: 'uppercase',
								marginTop: '0.875rem'
							}}
							onClick={changeLanguageConfirmation}
						>
							No
						</Button>
					</div>
				</Container>
			</Fade>
		</Modal>
	)
}

export default LanguageConfirmation;

import { Button, CircularProgress, Container, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { makeAuthRequest, routes } from "../../Auth/AuthApi";
import CaseItem from "./CaseItem";


const Cases = ({ authData, classes }) => {
	const [loading, setLoading] = useState(true);
	const [cases, setCases] = useState([]);

	useEffect(() => {
		const getAssignedCases = async () => {
			try {
				const res = await makeAuthRequest(routes.get_assigned_cases, null, 'GET', authData);

				const resJson = await res.json();

				if (res.ok) {
					setLoading(false);
					setCases(resJson.data);
				}
				else {
					console.error(resJson);
				}
			}
			catch (e) {
				console.error(e);
			}
		};

		getAssignedCases();
	}, [authData]);

	return (
		<Container
			component='main'
			maxWidth='lg'
			style={{
				marginTop: '1rem',
				height: '65vh'
			}}
		>
			{
				cases.length ?
					<Container
						className={classes.caseGrid}
					>
						{
							cases.map(item => (
								<CaseItem
									item={item}
									classes={classes}
								>
									<Button
										fullWidth
										className={classes.homeButton}
										component={Link}
										to={`/home/case/${item.id}`}
									>
										Update
									</Button>
								</CaseItem>
							))
						}
					</Container>
					:
					<Container
						className={classes.case}
						style={{
							marginTop: '2rem'
						}}
					>
						{
							loading ?
								<CircularProgress color="inherit" size="5rem" />
								:
								<Typography
									style={{
										textTransform: 'uppercase',
										fontWeight: 900,
									}}
								>
									Currently, You don't have any cases assigned to you.
								</Typography>
						}
					</Container>
			}
		</Container>
	);
};

export default Cases;

import { createTheme, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import 'typeface-roboto';
import AppRouter from './components/AppRouter';
import { version } from '../package.json';
import CacheBuster from 'react-cache-buster';


const App = () => {
	const theme = createTheme({
		typography: {
			"fontFamily": `"Arial", sans-serif`,
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536,
				xsl: 2520,
			},
		},
		overrides: {
			MuiSwitch: {
				colorSecondary: {
					"&$checked": {
						color: "#9487bc"
					}
				},
				track: {
					"$checked$checked + &": {
						opacity: 0.7,
						backgroundColor: "#9487bc"
					}
				}
			}
		}
	});

	const isProduction = process.env.NODE_ENV === 'production';

	return (
		<CacheBuster
			currentVersion={version}
			isEnabled={isProduction}
			isVerboseMode={false}
		>
			<HelmetProvider>
				<ThemeProvider theme={theme}>
					<AppRouter />
				</ThemeProvider>
			</HelmetProvider>
		</CacheBuster>
	);
}
export default App;

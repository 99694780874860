import { Typography } from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { makeRequest, routes } from '../../Auth/AuthApi';
import { validateAtLeastOne, validateEmail, validateExists, validateMobile, validateName, validateOptions } from '../../Auth/FormParts/Validation';
import Step1 from './Step1';
import Step2 from './Step2';


const Organization = ({
	classes, step, setStep, areaOptions, hospitalTypeOptions
}) => {
	const history = useHistory();


	const [firstState, setFirstState] = useState({
		type: null,
		typeError: '',
		organizationName: '',
		organizationNameError: '',
		address: '',
		addressError: '',
		city: '',
		cityError: '',
		district: '',
		districtError: '',
		area: '',
		areaError: '',
		personName: '',
		personNameError: '',
		email: '',
		emailError: '',
		mobile: '',
		mobileError: '',
	});

	const [secondState, setSecondState] = useState({
		sector: null,
		sectorError: '',
		isClinicalService: false,
		clinicalService: '',
		clinicalServiceError: '',
		isResearch: false,
		isTraining: false,
		isOthers: false,
		categoryError: '',
		secondary: {
			psychiatrists: null,
			psychiatristsError: '',
			medicalOfficers: null,
			medicalOfficersError: '',
			psychologists: null,
			psychologistsError: '',
			psychiatristNurses: null,
			psychiatristNursesError: '',
		},
		tertiary: {
			psychiatrists: null,
			psychiatristsError: '',
			medicalOfficers: null,
			medicalOfficersError: '',
			psychologists: null,
			psychologistsError: '',
			psychiatristNurses: null,
			psychiatristNursesError: '',
		},
		tertiaryTeaching: {
			psychiatrists: null,
			psychiatristsError: '',
			medicalOfficers: null,
			medicalOfficersError: '',
			psychologists: null,
			psychologistsError: '',
			psychiatristNurses: null,
			psychiatristNursesError: '',
			psychiatristStudents: null,
			psychiatristStudentsError: '',
			psychologistStudents: null,
			psychologistStudentsError: '',
		},
		isUndergraduate: false,
		isPostGraduate: false,
		isSpecialists: false,
		isGeneral: false,
		trainingError: '',
		research: '',
		researchError: '',
		other: '',
		otherError: ''
	});

	const changeFirstState = (changes) => {
		changes.typeError = validateExists(changes.type);
		changes.organizationNameError = validateName(changes.organizationName);
		changes.personNameError = validateName(changes.personName);
		changes.emailError = validateEmail(changes.email);
		changes.mobileError = validateMobile(changes.mobile);
		changes.cityError = validateExists(changes.city);
		changes.districtError = validateExists(changes.district);
		changes.areaError = validateOptions(changes.area, areaOptions);
		changes.addressError = validateExists(changes.address);


		setFirstState(prev => ({
			...prev,
			...changes
		}));

		return [
			changes.typeError,
			changes.organizationNameError,
			changes.personNameError,
			changes.emailError,
			changes.mobileError,
			changes.cityError,
			changes.districtError,
			changes.areaError,
			changes.addressError,
		]
	};

	const changeSecondState = changes => {
		changes.sectorError = validateExists(changes.sector);
		changes.researchError = validateExists(changes.research);
		changes.otherError = validateExists(changes.other);
		changes.clinicalServiceError = validateExists(changes.clinicalService);
		changes.trainingError = validateAtLeastOne(
			changes.isUndergraduate ?? secondState.isUndergraduate,
			changes.isPostGraduate ?? secondState.isPostGraduate,
			changes.isSpecialists ?? secondState.isSpecialists,
			changes.isGeneral ?? secondState.isGeneral
		);
		changes.categoryError = validateAtLeastOne(
			changes.isClinicalService ?? secondState.isClinicalService,
			changes.isResearch ?? secondState.isResearch,
			changes.isTraining ?? secondState.isTraining,
			changes.isOthers ?? secondState.isOthers,
		);

		const errors = [
			changes.sectorError,
			changes.categoryError
		];

		if (secondState.isOthers) errors.push(changes.otherError);
		if (secondState.isTraining) errors.push(changes.trainingError);
		if (secondState.isResearch) errors.push(changes.researchError);
		if (secondState.isClinicalService) errors.push(changes.clinicalServiceError);

		['secondary', 'tertiary', 'tertiaryTeaching'].forEach(key => {
			if (key in changes) {
				changes[key].psychiatristsError = validateExists(changes[key].psychiatrists);
				changes[key].psychologistsError = validateExists(changes[key].psychologists);
				changes[key].psychiatristNursesError = validateExists(changes[key].psychiatristNurses);
				changes[key].medicalOfficersError = validateExists(changes[key].medicalOfficers);
				errors.push(changes[key].psychiatristsError);
				errors.push(changes[key].psychologistsError);
				errors.push(changes[key].psychiatristNursesError);
				errors.push(changes[key].medicalOfficersError);

				if (key === 'tertiaryTeaching') {
					changes[key].psychiatristStudentsError = validateExists(changes[key].psychiatristStudents);
					changes[key].psychologistStudentsError = validateExists(changes[key].psychologistStudents);
					errors.push(changes[key].psychiatristStudentsError);
					errors.push(changes[key].psychologistStudentsError);
				}
			}
		});

		setSecondState(prev => ({
			...prev,
			...changes,
			secondary: {
				...prev.secondary,
				...changes.secondary
			},
			tertiary: {
				...prev.tertiary,
				...changes.tertiary,
			},
			tertiaryTeaching: {
				...prev.tertiaryTeaching,
				...changes.tertiaryTeaching,
			}
		}));

		return errors;
	};


	const prevStep = () => {

	};

	const nextStep = () => {
		const errors = changeFirstState(firstState);

		if (errors.every(e => e === '')) setStep(prev => prev + 1);
	};

	const register = async () => {
		const errors = changeSecondState(secondState);

		if (errors.every(e => e === '')) {
			const data = {
				organization_type: firstState.type,
				name: firstState.organizationName,
				area: firstState.area,
				address: firstState.address,
				city: firstState.city,
				district: firstState.district,
				name_of_focal_person: firstState.personName,
				email_of_focal_person: firstState.email,
				phone_no_of_focal_person: firstState.mobile,
				sector: secondState.sector,
				clinical_services: (
					secondState.isClinicalService ? secondState.clinicalService : null
				),
				other: (
					secondState.isOthers ? secondState.other : null
				),
				research: (
					secondState.isResearch ? secondState.research : null
				),
				trainings: (
					secondState.isTraining ? [
						secondState.isGeneral ? 'General population / community' : '',
						secondState.isSpecialists ? 'Non-specialist healthcare workers' : '',
						secondState.isPostGraduate ? 'Post-graduate' : '',
						secondState.isUndergraduate ? 'Undergraduate' : '',
					] : null
				),
				hospitals: [
					{
						organizational_type: 'Secondary Care Hospital',
						psychiatrits_count: secondState.secondary.psychiatrists,
						psychologists_count: secondState.secondary.psychologists,
						medical_officers: secondState.secondary.medicalOfficers,
						psychiatric_nurses: secondState.secondary.psychiatristNurses
					},
					{
						organizational_type: 'Tertiary Care Hospital',
						psychiatrits_count: secondState.tertiary.psychiatrists,
						psychologists_count: secondState.tertiary.psychologists,
						medical_officers: secondState.tertiary.medicalOfficers,
						psychiatric_nurses: secondState.tertiary.psychiatristNurses
					},
					{
						organizational_type: 'Tertiary Care & Teaching Hospital',
						psychiatrits_count: secondState.tertiaryTeaching.psychiatrists,
						psychologists_count: secondState.tertiaryTeaching.psychologists,
						medical_officers: secondState.tertiaryTeaching.medicalOfficers,
						psychiatric_nurses: secondState.tertiaryTeaching.psychiatristNurses,
						psychiatrists_students: secondState.tertiaryTeaching.psychiatristStudents,
						psychologists_students: secondState.tertiaryTeaching.psychologistStudents,
					}
				]
			};

			try {
				const res = await makeRequest(routes.register_organization, data, 'POST');
				const resJson = await res.json();
				if (res.ok) {
					history.push('/sign_up/success');
					return true
				}
				else {
					console.error(resJson);
					return false;
				}
			}
			catch (e) {
				console.error(e);
				return false;
			}
		}
	};

	return (
		<>
			<Typography
				component='h1'
				variant='h5'
				className={`${classes.heading} ${classes.uppercase}`}
				style={{
					color: '#59567f',
					textTransform: 'uppercase',
					fontWeight: 900,
				}}
			>
				Organization
			</Typography>
			{
				step === 1 ?
					<>
						<Step1
							classes={classes}
							nextStep={nextStep}
							state={firstState}
							changeState={changeFirstState}
							areaOptions={areaOptions}
						/>
					</>
					:
					step === 2 ?
						<Step2
							classes={classes}
							prevStep={prevStep}
							register={register}
							hospitalTypeOptions={hospitalTypeOptions}
							state={secondState}
							changeState={changeSecondState}
						/>
						:
						<Typography>
							There was an error with the form
						</Typography>
			}
		</>
	);
};

Organization.defaultProps = {
	areaOptions: [
		'ICT',
		'Punjab',
		'Sindh',
		'Balochistan',
		'KPK',
		'AJK',
		'GB',
		'Armed Forces'
	],
	hospitalTypeOptions: [
		'Secondary care hospital (District)',
		'Tertiary care Hospital',
		'Tertiary care + teaching hospital '
	],
};


export default Organization;

import { Button, Container, Typography } from "@material-ui/core";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import { makeRequest, routes } from "./AuthApi";
import PasswordDetails from "./FormParts/PasswordDetails";
import PersonalDetails from "./FormParts/PersonalDetails";
import WorkDetails from "./FormParts/WorkDetails";

const PsychiatristForm = ({ classes }) => {
  const history = useHistory();

  const [step, setStep] = useState(1);

  const [personalState, setPersonalState] = useState({
    gender: "",
    genderError: "",
    name: "",
    nameError: "",
    age: "",
    ageError: "",
    email: "",
    emailError: "",
    mobile: "",
    mobileError: "",
    showGraduate: "",
    showGraduateError: "",
  });

  const [passwordState, setPasswordState] = useState({
    password: "",
    passwordError: "",
    passwordConfirmation: "",
    confirmationError: "",
  });

  const [detailsState, setDetailsState] = useState({
    degreeOrDiploma: "",
    degreeOrDiplomaError: "",
    showOtherDegree: false,
    otherDegreeOrDiploma: "",
    otherDegreeOrDiplomaError: "",
    yearsOfExperience: "",
    yearsOfExperienceError: "",
    trainingUniversity: "",
    trainingUniversityError: "",
    yearsOfTraining: "",
    yearsOfTrainingError: "",
    publicSector: false,
    privateSector: false,
    sectorError: "",
    title: "",
    titleError: "",
    academicTitleError: "",
    academicTitle: "",
    nonAcademicTitle: "",
    nonAcademicTitleError: "",
    workplace1Name: "",
    workplace2Name: "",
    workplaceNameError: "",
    workplace1Address: "",
    workplace2Address: "",
    workplaceAddressError: "",
    district: "",
    districtError: "",
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const register = async () => {
    const personalData = {
      email: personalState.email,
      name: personalState.name,
      age: personalState.age,
      gender: personalState.gender,
      phone_no: personalState.mobile,
    };

    const passwordData = {
      password: passwordState.password,
      password_confirmation: passwordState.passwordConfirmation,
    };

    // TODO rename all the state to be compatible with api names so this becomes simpler
    const detailsData =
      personalState.showGraduate === "yes"
        ? {
            is_pg_training_completed: personalState.showGraduate === "yes",
            pg_training_degree_or_diploma:
              detailsState.degreeOrDiploma === "Other"
                ? detailsState.otherDegreeOrDiploma
                : detailsState.degreeOrDiploma,
            pg_training_years_of_experience: detailsState.yearsOfExperience,
            is_public_sector: detailsState.publicSector,
            is_private_sector: detailsState.privateSector,
            is_NGO_worker: detailsState.ngoWorker,
            academic_title:
              detailsState.title === "Academic"
                ? detailsState.academicTitle
                : undefined,
            non_academic_title:
              detailsState.title === "Non-academic"
                ? detailsState.nonAcademicTitle
                : undefined,
            workplace1_name: detailsState.workplace1Name,
            workplace2_name: detailsState.workplace2Name,
            workplace1_address: detailsState.workplace1Address,
            workplace2_address: detailsState.workplace2Address,
            district: detailsState.district,
          }
        : {
            is_pg_training_completed: personalState.showGraduate === "yes",
            pg_training_university: detailsState.trainingUniversity,
            pg_years_of_training: detailsState.yearsOfTraining,
            district: detailsState.district,
          };

    const data = {
      ...personalData,
      ...passwordData,
      ...detailsData,
    };

    try {
      const res = await makeRequest(
        routes.register_as_psychiatrists_path,
        data
      );
      const resJson = await res.json();

      if (res.ok) {
        history.push("/sign_up/success");
      } else {
        setPersonalState((prevState) => {
          return {
            ...prevState,
            genderError: resJson.gender ?? prevState.genderError,
            nameError: resJson.name ?? prevState.nameError,
            ageError: resJson.age ?? prevState.ageError,
            emailError: resJson.email ?? prevState.emailError,
            mobileError: resJson.mobile ?? prevState.mobileError,
          };
        });

        setPasswordState((prevState) => {
          return {
            ...prevState,
            passwordError: resJson.password ?? prevState.passwordError,
            confirmationError:
              resJson.password_confirmation ?? prevState.confirmationError,
          };
        });

        if (
          ["gender", "name", "age", "email", "mobile"].some(
            (prop) => prop in resJson
          )
        ) {
          setStep(1);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  let currentPageStep = <Typography>Some error has occurred!</Typography>;

  switch (step) {
    case 1:
      currentPageStep = (
        <PersonalDetails
          classes={classes}
          nextStep={nextStep}
          personalState={personalState}
          setPersonalState={setPersonalState}
        />
      );
      break;
    case 2:
      currentPageStep = (
        <WorkDetails
          classes={classes}
          prevStep={prevStep}
          nextStep={nextStep}
          detailsState={detailsState}
          setDetailsState={setDetailsState}
          universityLabel="Institute of Training"
          showGraduate={personalState.showGraduate === "yes"}
        />
      );
      break;
    case 3:
      currentPageStep = (
        <PasswordDetails
          classes={classes}
          register={register}
          prevStep={prevStep}
          passwordState={passwordState}
          setPasswordState={setPasswordState}
        />
      );
      break;
    default:
      alert("ERROR!");
  }

  return (
    <Container component="main" maxWidth="sm">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Register as Psychiatrist</title>
        <meta
          name="description"
          content="Registration form for Psychiatrist to register to MHPSS."
        />
      </Helmet>

      <div className={classes.paper}>
        <Typography
          component="h1"
          variant="h5"
          className={`${classes.heading} ${classes.uppercase}`}
        >
          Psychiatrist
        </Typography>

        <Typography
          component="h3"
          variant="subtitle1"
          style={{
            color: "#0FF2DD ",
            marginTop: "10px",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          STEP {step} OF 3
        </Typography>

        {step !== 1 && (
          <Button
            style={{
              backgroundColor: "#38364c",
            }}
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={prevStep}
          >
            GO TO PREVIOUS STEP
          </Button>
        )}

        {currentPageStep}
      </div>
    </Container>
  );
};

export default PsychiatristForm;

import { Button, CircularProgress, Container, MenuItem, TextField, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { makeAuthRequest, routes } from "../../Auth/AuthApi";
import { validateNotBlank, validateOptions } from "../../Auth/FormParts/Validation";
import CaseItem from "./CaseItem";


const Case = ({ authData, classes }) => {
	const { id } = useParams();
	const [saved, setSaved] = useState(false);
	const [item, setItem] = useState({});
	const [comment, setComment] = useState({
		value: '',
		error: ''
	});
	const [status, setStatus] = useState({
		value: '',
		error: ''
	});

	const [statuses, setStatuses] = useState([]);

	const saveComment = async () => {
		const error = validateNotBlank(comment.value);
		if (!error) {
			const data = {
				comments_list: [
					{
						comment: comment.value,
						case_id: id,
						status: status.value
					}
				]
			};

			try {
				const res = await makeAuthRequest(routes.add_comments, data, 'POST', authData);

				const resJson = await res.json();

				if (res.ok) {
					setSaved(true);
				}
				else {
					console.error(resJson);
					setComment(prev => ({
						...prev,
						error: 'There was a problem with adding comment.'
					}));
				}
			}
			catch (e) {
				console.error(e);
				setComment(prev => ({
					...prev,
					error: 'There was a problem with adding comment.'
				}));
			}
		}
		setComment(prev => ({
			...prev,
			error
		}));
	};

	const changeStatus = useCallback((e) => {
		setStatus({
			value: e.target.value,
			error: validateOptions(e.target.value, statuses.map(status => status.value))
		});
	}, [statuses]);

	useEffect(() => {
		const getCase = async () => {
			try {
				const res = await makeAuthRequest(
					`${routes.get_assigned_cases}/${id}`,
					null,
					'GET',
					authData
				)

				const resJson = await res.json();

				if (res.ok) {
					setItem(resJson.data);
					setStatus(prev => ({
						...prev,
						value: resJson.data.status
					}))
				}
				else {
					console.error(resJson);
				}
			}
			catch (e) {
				console.error(e);
			}
		};

		const fetchStatuses = async () => {
			try {
				const res = await makeAuthRequest(
					routes.get_statuses,
					null,
					'GET',
					authData
				)

				const resJson = await res.json();

				if (res.ok) {
					setStatuses(resJson);
				}
				else {
					console.error(resJson);
				}
			}
			catch (e) {
				console.error(e);
			}
		};

		fetchStatuses();
		getCase();
	}, [authData, id]);

	return (
		<Container
			component='main'
			maxWidth='md'
			style={{
				marginTop: '2rem',
			}}
		>
			{
				saved ?
					<Container className={classes.case}>
						<Typography
							variant='h5'
							className={classes.warningText}
						>
							Your changes have been successfully saved.
						</Typography>
						<Button
							className={classes.homeButton}
							component={Link}
							to='/home/cases'
						>
							Go Back
						</Button>
					</Container>
					:
					item && Object.keys(item).length ?
						<CaseItem
							item={item}
							classes={classes}
						>
							<Container
								className={classes.caseText}
							>
								{
									item.comment.map(comment => (
										<Container
											key={comment.id}
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between',
												alignItems: 'center',
												padding: '0 1rem',
												margin: '0.25rem 0 1rem 0'
											}}
										>
											<Typography
												variant="subtitle1"
												style={{
													textAlign: 'justify',
													wordBreak: 'break-all'
												}}
											>
												{comment.comment}
											</Typography>

											<Typography
												variant="subtitle1"
												style={{
													minWidth: '6rem',
													marginLeft: '5px'
												}}
											>
												{comment.created_at}
											</Typography>
										</Container>
									))
								}
								<Typography
									variant="h6"
									component="label"
									className={classes.purpleText}
								>
									Change status
								</Typography>

								<TextField
									select
									variant='outlined'
									margin='normal'
									required
									fullWidth
									autoComplete='age'
									placeholder={`16-70`}
									value={status.value}
									error={status.error !== ''}
									helperText={status.error}
									onChange={changeStatus}
									className={classes.input}
								>
									{
										statuses.map(status => {
											return (
												<MenuItem key={status.id} value={status.value}>
													{status.value}
												</MenuItem>
											);
										})
									}
								</TextField>

								<Typography
									variant="h6"
									component="label"
									className={classes.purpleText}
									gutterBottom
								>
									Enter your comments here
								</Typography>

								<TextField
									variant="outlined"
									fullWidth
									required
									multiline
									rows={6}
									value={comment.value}
									error={comment.error !== ''}
									helperText={comment.error}
									onChange={e => {
										setComment({
											value: e.target.value,
											error: validateNotBlank(e.target.value)
										})
									}}
									className={classes.input}
								/>

								<Container
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										width: '100%',
										gap: '2rem',
										padding: 0
									}}
								>
									<Button
										fullWidth
										className={classes.homeButton}
										component={Link}
										to={`/home/cases`}
									>
										Cancel
									</Button>

									<Button
										fullWidth
										className={classes.homeButton}
										onClick={saveComment}
									>
										Save
									</Button>
								</Container>
							</Container>
						</CaseItem>
						:
						<Container className={classes.case}>
							<CircularProgress color="inherit" size="5rem" />
						</Container>
			}
		</Container>
	);
};

export default Case;

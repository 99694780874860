import { Button, CircularProgress, Container, FormControl, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { makeRequest, routes } from '../../Auth/AuthApi';
import Header from '../Header';

const useStyle= makeStyles(theme => ({
	textField: {
		backgroundColor: '#fafafa',
	},
	root: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '2rem',
		backgroundColor: '#eaeaea',
		border: '2px solid #cec9de',
		borderRadius: '7px',
		paddingBottom: '2rem'
	},
	heading: {
		textAlign: 'center',
		backgroundColor: '#EAEAEA',
		fontWeight: 'bold',
		fontSize: '1.4rem',
		padding: '5px 0 5px 0',
		marginBottom: theme.spacing(2),
		justifyContent: 'center',
	},
	uppercase: {
		textTransform: 'uppercase'
	},
	topHeader: {
		textAlign: "center",
		padding: '10',
		background: "#eaeaea",
		color: "#383838",
		fontSize: '14',
		fontFamily: "Arial",
		fontWeight: '400'
	},
	logo: {
		width: '7rem',
		height: '7rem',
		objectFit: "contain",
		display: 'flex',
		margin: 'auto',
	},
	center: {
		textAlign: "center",
		fontFamily: "Arial",
	},
	right: {
		textAlign: "right",
		fontFamily: "Arial",
		[theme.breakpoints.down('sm')]: {
			alignItems:'center'

		},
		[theme.breakpoints.down('xs')]: {
			alignItems:'center'

		},
		[theme.breakpoints.down('lg')]: {
			alignItems:'center'

		},
		[theme.breakpoints.down('md')]: {
			alignItems:'center'

		}
	},
	textFeild: {
		backgroundColor: '#fafafa',
		border: '2px solid #cec9de',
		borderRadius: '6px'
	},
	label:{
		color: '#07D5C2',
		padding: '0.5rem',
		fontSize: '1rem',
		fontWeight: 'bold',
		textAlign: 'left',
		textTransform: 'uppercase',
		marginTop: '0.5rem'
	},
	checkboxes: {
		display: 'flex',
		marginTop: theme.spacing(1),
		flexWrap:'wrap',
	},
	info:{
		color : 'black',
		fontSize: '0.875rem',
	},
	checkboxContainer: {
		backgroundColor: 'white',
		borderRadius: '7px',
		border: `1px solid #cec9de`,
		padding: theme.spacing(2),
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		alignItems: 'center'
	},
	topContainer: {
		backgroundColor: 'white',
		borderRadius: '7px',
		border: `1px solid #cec9de`,
		padding: theme.spacing(2),
		marginBottom: theme.spacing(1),
		alignItems: 'center'
	},
	formLayout: {
		[theme.breakpoints.down('lg')]: {
			width: '68%',

		},
		[theme.breakpoints.down('md')]: {
			width: '75%'
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	radioLayout: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		[theme.breakpoints.down('xs')]: {
			flexWrap: 'wrap',
		}
	},
	radioButton: {
		marginLeft: '11rem',
		[theme.breakpoints.down('md')]: {
			marginLeft: '8rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '8rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginLeft: '-11px',
		}
	},
	languageTitle: {
		width: '68%',
		[theme.breakpoints.down('md')]:
		{
			width: '75%'
		},
		[theme.breakpoints.down('sm')]:
		{
			width: '75%'
		},
		[theme.breakpoints.down('xs')]:
		{
			width: '100%'
		},
	},
	progressBar: {
		height : '20px',
		color: ' #a1035a',
},
	colorPrimary: {
		backgroundColor: '#f5f5f5',
	},
	barColorPrimary: {
		backgroundColor: '#a1035a'
	},
}))

const Login = ({ setAuth }) => {
	const classes = useStyle();
	const [loading, setLoading] = useState(false);
	const [login, setLogin] = useState({
		username: '',
		password: '',
	});

	const [errors, setErrors] = useState('');

	const changeState = (changeState) => {
		setLogin(prevState => {
			return {
				...prevState,
				...changeState,
			}
		})
	}

	const submit = async() => {
		try {
			setLoading(true);
			const res = await makeRequest(routes.log_in, {
				username: login.username,
				password: login.password
			});
			const resJson = await res.json()
			if (res.ok) {
				const headers = {
					uid: res.headers.get('uid'),
					accessToken: res.headers.get('access-token'),
					client: res.headers.get('client'),
					expiry: res.headers.get('expiry'),
					tokenType: res.headers.get('token-type'),
					role: resJson.role
				}
				setAuth(headers, resJson);
			}
			else {
				setErrors(resJson.errors)
				setLoading(false);
			}
		}
		catch(e) {
			console.error(e)
			setLoading(false);
		}
	};

	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Log In</title>
				<meta
					name='description'
					content='Login Page'
				/>
			</Helmet>

			<Header />

			<Container
				component='main'
				maxWidth='sm'
				style={{
					paddingBottom: '2rem',
					marginTop: '3rem'
				}}
			>

				<Container
					style={{
						alignItems: 'center',
						justifyContent: 'center',
						display: 'flex'
					}}
				>
					<Typography
						component='h1'
						variant='h5'
						className={`${classes.heading} ${classes.uppercase}`}
						style={{
							fontSize: '0.875rem',
							width: '11rem'
						}}
					>

						Log In
					</Typography>
				</Container>

				<form className={classes.root}>
					<FormControl style={{width: '100%', padding: '0rem'}}>
						<Typography
							variant= 'h6'
							className={classes.label}
						>
							Your Mobile Number *
						</Typography>

						<TextField
							InputProps={{
								inputProps: {
									maxLength: 11
								}
							}}
							variant="outlined"
							fullWidth
							required
							autoComplete='phone_no'
							placeholder='Enter your mobile number. '
							value={login.username}
							onChange={e => {
								changeState({ username: e.target.value })
							}}
							className={classes.textField}
						/>

						<Typography
							variant= 'subtitle1'
							className={classes.label}
						>
							Password *
						</Typography>

						<TextField
							variant="outlined"
							fullWidth
							required
							placeholder='Enter your password.'
							className={classes.textField}
							style={{padding: '0'}}
							id='password'
							autoComplete='password'
							value={login.password}
							onChange={e => {
								changeState({ password: e.target.value })
							}}
							type='password'
							onKeyPress={e => e.key === 'Enter' && submit()}
						>
						</TextField>

						<Typography
							variant= 'subtitle1'
							color='secondary'
						>
							{ errors }
						</Typography>

						<Typography
							variant= 'subtitle1'
							className={classes.label}
						>
							<Link
								to="/forgot_password"
								style={{
									color: 'rgb(56, 54, 76)',
									textAlign: 'center',
									textTransform: 'none',
									fontSize: '1rem',
									padding: 0.5,
									cursor: 'pointer',
									textDecoration: 'none',
								}}
							>
								I forgot my password
							</Link>
						</Typography>
					</FormControl>
				</form>

				<br/>
				<br/>
				<Button
					style={{
						backgroundColor: 'rgb(56, 54, 76)',
						color: '#fafafa',
						fontSize:'0.875rem',
						width: '11rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						margin: '0 auto',
					}}
					onClick={submit}
					disabled={loading}
				>
					{
						loading ?
							<CircularProgress
								color="inherit"
								size={24}
							/>
							:
							'LOG IN'
					}
				</Button>
			</Container>
		</div>
	)
}

export default Login

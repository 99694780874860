import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";

import { DISTRICTS } from "../../constants";
import { validateNotBlank, validateNum, validateOptions } from "./Validation";

const WorkDetails = ({
  classes,
  nextStep,
  detailsState,
  setDetailsState,
  academicTitles,
  nonAcademicTitles,
  degrees,
  yearsOfExperienceOptions,
  showGraduate,
  formType,
}) => {
  const changeState = (changeState) => {
    changeState.workplaceNameError = validateNotBlank(
      changeState.workplace1Name
    );

    changeState.workplaceAddressError = validateNotBlank(
      changeState.workplace1Address
    );

    changeState.titleError = validateOptions(changeState.title, [
      "Academic",
      "Non-academic",
    ]);

    changeState.degreeOrDiplomaError = validateOptions(
      changeState.degreeOrDiploma,
      degrees
    );

    changeState.otherDegreeOrDiplomaError = validateNotBlank(
      changeState.otherDegreeOrDiploma
    );

    changeState.yearsOfExperienceError = validateOptions(
      changeState.yearsOfExperience,
      yearsOfExperienceOptions
    );

    changeState.trainingUniversityError = validateNotBlank(
      changeState.trainingUniversity
    );

    changeState.yearsOfTrainingError = validateNum(
      changeState.yearsOfTraining,
      1,
      4
    );

    changeState.districtError = validateNotBlank(changeState.district);

    if ("publicSector" in changeState) {
      changeState.sectorError =
        changeState.publicSector ||
        detailsState.privateSector ||
        detailsState.ngoWorker
          ? ""
          : "Choose at least one";
    } else if ("privateSector" in changeState) {
      changeState.sectorError =
        detailsState.publicSector ||
        changeState.privateSector ||
        detailsState.ngoWorker
          ? ""
          : "Choose at least one";
    } else if ("ngoWorker" in changeState) {
      changeState.sectorError =
        detailsState.publicSector ||
        detailsState.privateSector ||
        changeState.ngoWorker
          ? ""
          : "Choose at least one";
    }

    const title = changeState.title ?? detailsState.title;
    if (title === "Academic") {
      changeState.academicTitleError = validateOptions(
        changeState.academicTitle,
        academicTitles
      );
    } else if (title === "Non-academic") {
      changeState.nonAcademicTitleError = validateOptions(
        changeState.nonAcademicTitle,
        nonAcademicTitles
      );
    }

    setDetailsState((prevState) => {
      return {
        ...prevState,
        ...changeState,
      };
    });

    return {
      workplaceNameError: changeState.workplaceNameError,
      workplaceAddressError: changeState.workplaceAddressError,
      titleError: changeState.titleError,
      academicTitleError: changeState.academicTitleError,
      nonAcademicTitleError: changeState.nonAcademicTitleError,
      sectorError: changeState.sectorError,
      degreeOrDiplomaError: changeState.degreeOrDiplomaError,
      otherDegreeOrDiplomaError: changeState.otherDegreeOrDiplomaError,
      yearsOfExperienceError: changeState.yearsOfExperienceError,
      trainingUniversityError: changeState.trainingUniversityError,
      yearsOfTrainingError: changeState.yearsOfTrainingError,
      districtError: changeState.districtError,
    };
  };

  const validateOtherState = (errors) => {
    if (showGraduate) {
      return (
        !errors.degreeOrDiplomaError &&
        (detailsState.degreeOrDiploma !== "Other" ||
          !errors.otherDegreeOrDiplomaError) &&
        !errors.yearsOfExperienceError &&
        !errors.workplaceNameError &&
        !errors.workplaceAddressError &&
        !errors.titleError &&
        !errors.sectorError &&
        !errors.academicTitleError &&
        !errors.nonAcademicTitleError
      );
    }
    return !errors.trainingUniversityError && !errors.yearsOfTrainingError;
  };

  const validateNextStep = () => {
    if (validateOtherState(changeState(detailsState))) nextStep();
  };

  return (
    <>
      <form className={classes.form} noValidate>
        {showGraduate === true && (
          <>
            <Typography
              variant="subtitle1"
              style={{
                color: "#38364c",
                fontSize: "14px",
                fontWeight: "bold",
                marginLeft: "30px",
              }}
            >
              QUALIFICATION &amp; EXPERIENCE
            </Typography>

            <FormControl
              component="fieldset"
              className={classes.formControl}
              fullWidth
              style={{
                backgroundColor: "#EAEAEA",
                border: "2px solid black",
                borderRadius: "7px",
              }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  color: "#07D5C2",
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginLeft: "2.5px",
                }}
              >
                DIPLOMA/DEGREE
              </Typography>

              <TextField
                style={{
                  backgroundColor: "white",
                  borderRadius: "7px",
                }}
                select
                variant="outlined"
                margin="normal"
                id="degree_or_diploma"
                name="degree_or_diploma"
                // label='Diploma/Degree'
                required
                fullWidth
                value={detailsState.degreeOrDiploma}
                error={detailsState.degreeOrDiplomaError !== ""}
                helperText={detailsState.degreeOrDiplomaError}
                onChange={(e) => {
                  changeState({
                    degreeOrDiploma: e.target.value,
                    showOtherDegree: e.target.value === "Other",
                  });
                }}
              >
                {degrees.map((degree, i) => {
                  return (
                    <MenuItem value={degree} key={i}>
                      {degree}
                    </MenuItem>
                  );
                })}
              </TextField>

              {detailsState.showOtherDegree && (
                <TextField
                  InputProps={{
                    inputProps: {
                      maxLength: 100,
                    },
                  }}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "7px",
                  }}
                  id="other_degree_or_diploma"
                  name="other_degree_or_diploma"
                  autoComplete="other_degree_or_diploma"
                  label="Specify"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={detailsState.otherDegreeOrDiploma}
                  error={detailsState.otherDegreeOrDiplomaError !== ""}
                  helperText={detailsState.otherDegreeOrDiplomaError}
                  onChange={(e) =>
                    changeState({ otherDegreeOrDiploma: e.target.value })
                  }
                />
              )}

              <Typography
                variant="subtitle1"
                style={{
                  color: "#07D5C2",
                  fontSize: "11px",
                  fontWeight: "bold",
                  marginLeft: "2.5px",
                }}
              >
                YEARS OF EXPERIENCE
              </Typography>

              <TextField
                style={{
                  backgroundColor: "white",
                  borderRadius: "7px",
                }}
                select
                variant="outlined"
                margin="normal"
                id="years_of_experience"
                name="years_of_experience"
                // label='Years of Experience'
                required
                fullWidth
                value={detailsState.yearsOfExperience}
                error={detailsState.yearsOfExperienceError !== ""}
                helperText={detailsState.yearsOfExperienceError}
                onChange={(e) =>
                  changeState({ yearsOfExperience: e.target.value })
                }
              >
                {yearsOfExperienceOptions.map((option, i) => {
                  return (
                    <MenuItem key={i} value={option}>
                      {option} years
                    </MenuItem>
                  );
                })}
              </TextField>

              <Typography variant="subtitle1" className={classes.formLabel}>
                WORK SECTOR
              </Typography>

              <Grid container className={classes.checkboxContainer}>
                <Grid lg={12}>
                  <Typography>More than one field can be ticked</Typography>
                </Grid>

                <Grid item lg={12} sm={1} xs={1} className={classes.checkboxes}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_public_sector"
                        color="primary"
                        checked={detailsState.publicSector}
                        onChange={(e) =>
                          changeState({ publicSector: e.target.checked })
                        }
                      />
                    }
                    label="Public"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_private_sector"
                        color="primary"
                        checked={detailsState.privateSector}
                        onChange={(e) =>
                          changeState({ privateSector: e.target.checked })
                        }
                      />
                    }
                    label="Private"
                  />
                  {"ngoWorker" in detailsState && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="is_NGO_worker"
                          color="primary"
                          checked={detailsState.ngoWorker}
                          onChange={(e) =>
                            changeState({ ngoWorker: e.target.checked })
                          }
                        />
                      }
                      label="NGO"
                    />
                  )}
                </Grid>

                {detailsState.sectorError && (
                  <Grid item lg={12}>
                    <Typography color="error">
                      {detailsState.sectorError}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Typography variant="subtitle1" className={classes.formLabel}>
                TITLE
              </Typography>

              <TextField
                className={classes.formField}
                select
                variant="outlined"
                margin="normal"
                id="title"
                name="title"
                required
                fullWidth
                value={detailsState.title}
                error={detailsState.titleError}
                helperText={detailsState.titleError}
                onChange={(e) => changeState({ title: e.target.value })}
              >
                <MenuItem value="Academic">Academic</MenuItem>
                <MenuItem value="Non-academic">Non-academic</MenuItem>
              </TextField>

              {detailsState.title === "Academic" && (
                <TextField
                  className={classes.formField}
                  select
                  required
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  id="academic_title"
                  name="academic_title"
                  value={detailsState.academicTitle}
                  error={detailsState.academicTitleError}
                  helperText={detailsState.academicTitleError}
                  onChange={(e) =>
                    changeState({ academicTitle: e.target.value })
                  }
                >
                  {academicTitles.map((title, i) => {
                    return (
                      <MenuItem key={i} value={title}>
                        {title}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
              {detailsState.title === "Non-academic" && (
                <TextField
                  className={classes.formField}
                  select
                  required
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  id="non_academic_title"
                  name="non_academic_title"
                  value={detailsState.nonAcademicTitle}
                  error={detailsState.nonAcademicTitleError}
                  helperText={detailsState.nonAcademicTitleError}
                  onChange={(e) =>
                    changeState({ nonAcademicTitle: e.target.value })
                  }
                >
                  {nonAcademicTitles.map((title, i) => {
                    return (
                      <MenuItem key={i} value={title}>
                        {title}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}

              <Typography variant="subtitle1" className={classes.formLabel}>
                WORK PLACE 1
              </Typography>

              <TextField
                className={classes.formField}
                InputProps={{
                  inputProps: {
                    maxLength: 255,
                  },
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="workplace1_name"
                name="workplace1_name"
                autoComplete="workplace"
                value={detailsState.workplace1Name}
                error={detailsState.workplaceNameError}
                helperText={detailsState.workplaceNameError}
                onChange={(e) =>
                  changeState({ workplace1Name: e.target.value })
                }
              />

              <Typography variant="subtitle1" className={classes.formLabel}>
                ADDRESS
              </Typography>

              <TextField
                className={classes.formField}
                InputProps={{
                  inputProps: {
                    maxLength: 255,
                  },
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="workplace1_address"
                name="workplace1_address"
                autoComplete="workplace"
                value={detailsState.workplace1Address}
                error={detailsState.workplaceAddressError}
                helperText={detailsState.workplaceAddressError}
                onChange={(e) =>
                  changeState({ workplace1Address: e.target.value })
                }
              />

              <Typography variant="subtitle1" className={classes.formLabel}>
                WORK PLACE 2
              </Typography>

              <TextField
                className={classes.formField}
                InputProps={{
                  inputProps: {
                    maxLength: 255,
                  },
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                id="workplace2_name"
                name="workplace2_name"
                autoComplete="workplace_name"
                onChange={(e) =>
                  changeState({ workplace2Name: e.target.value })
                }
              />

              <Typography variant="subtitle1" className={classes.formLabel}>
                ADDRESS
              </Typography>

              <TextField
                className={classes.formField}
                InputProps={{
                  inputProps: {
                    maxLength: 255,
                  },
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                id="workplace2_address"
                name="workplace2_address"
                autoComplete="workplace_address"
                value={detailsState.workplace2Address}
                onChange={(e) =>
                  changeState({ workplace2Address: e.target.value })
                }
              />

              <Typography variant="subtitle1" className={classes.formLabel}>
                DISTRICT
              </Typography>

              <TextField
                select
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="district"
                name="district"
                value={detailsState.district}
                error={detailsState.districtError !== ""}
                helperText={detailsState.districtError}
                onChange={(e) => changeState({ district: e.target.value })}
                className={classes.formField}
              >
                {DISTRICTS.map((district, index) => (
                  <MenuItem key={index} value={district}>
                    {district}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </>
        )}

        {showGraduate === false && (
          <>
            <Typography
              variant="subtitle1"
              style={{
                color: "#38364c",
                fontSize: "14px",
                fontWeight: "bold",
                marginLeft: "30px",
              }}
            >
              QUALIFICATION
            </Typography>

            <FormControl
              component="fieldset"
              className={classes.formControl}
              fullWidth
              style={{
                backgroundColor: "#EAEAEA",
                border: "2px solid black",
                borderRadius: "7px",
              }}
            >
              <Typography variant="subtitle1" className={classes.formLabel}>
                INSTITUTE OF TRAINING
              </Typography>

              <TextField
                className={classes.formField}
                InputProps={{
                  inputProps: {
                    maxLength: 100,
                  },
                }}
                id="pg_training_university"
                name="pg_training_university"
                autoComplete="pg_training_undergraduate_degree"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={detailsState.trainingUniversity}
                error={detailsState.trainingUniversityError !== ""}
                helperText={detailsState.trainingUniversityError}
                onChange={(e) =>
                  changeState({ trainingUniversity: e.target.value })
                }
              />

              <Typography variant="subtitle1" className={classes.formLabel}>
                YEARS OF TRAINING COMPLETED
              </Typography>

              {formType === "psychologist" ? (
                <TextField
                  className={classes.formField}
                  select
                  variant="outlined"
                  margin="normal"
                  id="pg_years_of_training"
                  name="pg_years_of_training"
                  required
                  fullWidth
                  value={detailsState.yearsOfTraining}
                  error={detailsState.yearsOfTrainingError !== ""}
                  helperText={detailsState.yearsOfTrainingError}
                  onChange={(e) =>
                    changeState({ yearsOfTraining: e.target.value })
                  }
                >
                  {[1, 2].map((option, i) => {
                    return (
                      <MenuItem key={i} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </TextField>
              ) : (
                <TextField
                  className={classes.formField}
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 4,
                      min: 1,
                    },
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="pg_years_of_training"
                  name="pg_years_of_training"
                  autoComplete="pg_years_of_training"
                  // label='Years of training completed'
                  placeholder="1-4"
                  value={detailsState.yearsOfTraining}
                  error={detailsState.yearsOfTrainingError}
                  helperText={detailsState.yearsOfTrainingError}
                  onChange={(e) =>
                    changeState({ yearsOfTraining: e.target.value })
                  }
                />
              )}

              <Typography variant="subtitle1" className={classes.formLabel}>
                DISTRICT
              </Typography>

              <TextField
                select
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="district"
                name="district"
                value={detailsState.district}
                error={detailsState.districtError !== ""}
                helperText={detailsState.districtError}
                onChange={(e) => changeState({ district: e.target.value })}
                className={classes.formField}
              >
                {DISTRICTS.map((district, index) => (
                  <MenuItem key={index} value={district}>
                    {district}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </>
        )}
      </form>

      <Button
        style={{
          backgroundColor: "#38364c",
          width: "100px",
        }}
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={validateNextStep}
      >
        Next
      </Button>
    </>
  );
};

WorkDetails.defaultProps = {
  degrees: [
    "FCPS",
    "MRCPsych",
    "American Board of Psychiatry and Neurology (ABPN)",
    "Other",
  ],
  academicTitles: [
    "Professor",
    "Associate Professor",
    "Assistant Professor",
    "Senior Registrar",
  ],
  nonAcademicTitles: ["Consultant", "Others"],
  yearsOfExperienceOptions: [">10", "5-10", "<5"],
};

export default WorkDetails;

import {
	Checkbox, Container, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography
} from '@material-ui/core';

const Step1 = ({ classes, courseData, langError, register, setCourseData }) => {
	return (
		<Container
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				paddingBottom: '3rem',
				paddingLeft: '0px',
				paddingRight: '0px'
			}}
		>
			<Typography
				component='h1'
				variant='h5'
				className={`${classes.heading} ${classes.uppercase} `}
				style={{
					color: '#451062',
					textTransform: 'uppercase',
					fontWeight: 900,
					minWidth: '100%',
					marginTop: '2rem'
				}}
			>
				Step 1: Complete your course
			</Typography>

			<form
				className={classes.root}
				style={{
					paddingBottom: '2rem',
					width: '100%'
				}}
			>
				<FormControl
					style={{
						width: '100%',
						paddingBottom: '2rem'
					}}
				>
					<Typography
						className={classes.label}
						style={{
							color: '#a1035a',
							textAlign: 'center',
							fontSize: '0.875rem',
							textTransform: 'uppercase'
						}}
					>
						Please select a language of the course
					</Typography>

					<Grid
						container
						className={classes.topContainer}
						style={{
							border: '3px solid #cec9de',
							borderRadius: '6px'
						}}
					>
						<Grid item lg={12} sm={1} xs={1} className={classes.checkboxes}>
							<FormControl
								component="fieldset"
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<RadioGroup
									onChange={e => {
										setCourseData(prev => {
											return {
												...prev,
												language: e.target.value
											};
										})
									}}
									color='primary'
									style={{
										marginLeft: '0.7rem',
									}}
									className={classes.radioLayout}
								>
									<FormControlLabel
										value="English"
										control={
											<Radio
												checked={courseData?.language === 'English'}
												required={true}
												color='primary'
											/>
										}
										label="English"
									/>

									<FormControlLabel
										value="Urdu"
										className={classes.radioButton}
										control={
											<Radio
												checked={courseData?.language === 'Urdu'}
												required={true}
												color='primary'
											/>
										}
										label="Urdu"
									/>
									<br />
								</RadioGroup>
							</FormControl>
							<Container>
								{
									langError &&
									<Grid item lg={12}>
										<Typography color='error'>
											{langError}
										</Typography>
									</Grid>
								}
							</Container>

						</Grid>
					</Grid>

					<FormControlLabel
						control={
							<Checkbox
								name=''
								color='primary'
								onClick={register}
								disabled={courseData?.registered}
							/>
						}
						label='Are you sure you want to take the course in the selected language'
						style={{ color: '#a1035a', justifyContent: 'flex-end' }}
						labelPlacement='start'
					/>
				</FormControl>
			</form>

		</Container>
	);
};

export default Step1;

import ImportExportOutlinedIcon from "@material-ui/icons/ImportExportOutlined";
import GridOnIcon from "@material-ui/icons/GridOn";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { ButtonGroup, Button, Hidden } from "@material-ui/core";
import { handleExport } from "./HandleExport";
import { GridToolbarContainer } from "@mui/x-data-grid";
const CasesToolbar = ({ rows, isGridView, settingGridView }) => {
  return (
    <div
      style={{
        display: "flex",
        margin: "1rem",
        justifyContent: "space-around",
      }}>
      <ButtonGroup style={{ flex: 0.9 }}>
        <Hidden smDown implementation="css">
          <Button
            variant={!isGridView ? "contained" : "outlined"}
            onClick={() => settingGridView(false)}
            title="Card View">
            <DashboardIcon />
          </Button>
          <Button
            variant={isGridView ? "contained" : "outlined"}
            onClick={() => settingGridView(true)}
            title="Grid View">
            <GridOnIcon />
          </Button>
        </Hidden>
      </ButtonGroup>
      <GridToolbarContainer style={{ flex: 0.1 }}>
        <Button
          variant="text"
          color="black"
          size="small"
          style={{
            backgroundColor: "#cbb6ce",
            fontWeight: "bold",
          }}
          startIcon={<ImportExportOutlinedIcon />}
          onClick={() => handleExport(rows)}>
          Export
        </Button>
      </GridToolbarContainer>
    </div>
  );
};
export default CasesToolbar;

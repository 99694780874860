import {
  Container,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useCallback, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";

import { MenuOpen } from "@material-ui/icons";
import { makeAuthRequest, routes } from "../../Auth/AuthApi";
import Header from "../Header";
import AllCases from "./AllCases";
import HamdardForce from "./HamdardForce";
import MHGAP from "./MHGAP";
import MHWS from "./MHWS";
import MyCare from "./MyCare";
import { Helmet } from "react-helmet-async";

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: "24rem",
      flexShrink: 0,
    },
  },
  wrapper: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: "0rem",
  },
  wrapperShifter: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "12rem",
  },
  main: {
    display: "flex",
  },
  drawerPaper: {
    width: "12rem",
  },
  homeOfDashboard: {
    width: "100%",
    display: "flex",
    flexDisplay: "row",
    padding: "4rem 0rem",
    alignContent: "center",
    justifyContent: "center",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      gap: "15px",
    },
  },
  container_flex: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: "1rem",
    width: "13rem",
  },
  textInput: {
    drawer: {
      width: "24rem",
    },
    homeOfDashboard: {
      width: "100%",
      display: "flex",
      flexDisplay: "row",
      marginTop: "5rem",
      alignContent: "center",
      justifyContent: "space-around",
    },
    container_flex: {
      display: "flex",
      justifyContent: "space-evenly",
      padding: "1rem",
      width: "13rem",
    },
    textInput: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "12ch",
      justifyContent: "space-evenly",
    },
    display: "flex",
    flexDirection: "column",
    maxWidth: "12ch",
    justifyContent: "space-evenly",
  },
}));

const AdminDashboard = ({ authData, logOut, redirectToHome }) => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(() => redirectToHome(history), [redirectToHome, history]);

  const [mhws, setMhws] = useState([]);
  const [mhwsCount, setMhwsCount] = useState(0);
  const [allCases, setAllCases] = useState([]);
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // toggle sidebar
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // MHWS rows fetch
  const fetchMHWS = useCallback(
    async (page = 1) => {
      try {
        const res = await makeAuthRequest(
          `${routes.get_mhws}?page=${page}`,
          {},
          "GET",
          authData
        );

        const response = await res.json();

        setMhwsCount(res.headers.get("X-Total-Count"));
        setMhws(response);
      } catch (e) {
        // alert(routes.get_mhws);
        console.error(e);
      }
    },
    [authData]
  );

  useEffect(() => {
    if (!authData || Object.keys(authData).length === 0) {
      history.push("/hamdard/login");
    } else {
      // All Cases rows fetch
      const fetchAllCases = async () => {
        try {
          const res = await makeAuthRequest(
            routes.get_allcases,
            {},
            "GET",
            authData
          );
          const response = await res.json();
          const defaultValue = (value) => {
            return value || "N/A ";
          };
          const allCases = response.data.map((elem) => {
            return {
              id: defaultValue(elem.id),
              name: defaultValue(elem.name),
              age: defaultValue(elem.age),
              contactNumber: defaultValue(elem.contact_number),
              source: defaultValue(elem.column_1 || "Hamdard Force"),
              district: defaultValue(elem?.district),
              description: defaultValue(elem.description),
              diagnosis: defaultValue(elem?.provisional_diagnosis),
              "urgent?": elem.is_urgent ? "Yes" : "No",
              "contactTeam?": elem.needs_supervisor ? "Yes" : "No",
              outcome: defaultValue(elem.outcome),
              reporterId: defaultValue(elem?.user?.id),
              reporterName: defaultValue(elem?.user?.name),
              reporterPhoneNumber: defaultValue(elem?.user?.phone_no),
              createdAt: defaultValue(elem.created_at),
              status: defaultValue(elem.status),
            };
          });
          setAllCases(allCases);
        } catch (e) {
          // alert(routes.get_allcases);
          console.error(e);
        }
      };

      fetchMHWS();
      fetchAllCases();
    }
  }, [authData, history, fetchMHWS]);

  return (
    <>
      {/* <Hidden xsDown implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}

          // open={open}
        >
          <div style={{ textAlign: "right", marginRight: "10px" }}>
            <IconButton
              color="inherit"
              size="medium"
              aria-label="open drawer"
              edge="end"
              style={{
                width: "fit-content",
              }}
              onClick={handleDrawerToggle}>
              <MenuOpen />
            </IconButton>
          </div>
          <List>
            {[
              "Home",
              "mhGAP Cases",
              "MyCare+ Cases",
              "Hamdard Force Cases",
              "All Cases",
              "MHWS",
            ].map((text, index) => (
              <ListItem
                selected={index === step}
                button
                key={text}
                onClick={() => setStep(index)}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Hidden> */}
      <div
        className={clsx(classes.wrapper, {
          [classes.wrapperShifter]: open && !isMobile,
        })}>
        <Header
          handleDrawerToggle={handleDrawerToggle}
          logOut={logOut}
          open={open}
        />
        <Container component="main">
          <Hidden implementation="css">
            <Drawer
              variant={isMobile ? "temporary" : "persistent"}
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={open}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: false, // Better open performance on mobile.
              }}>
              {open && (
                <div style={{ textAlign: "right" }}>
                  <IconButton
                    color="inherit"
                    size="medium"
                    aria-label="open drawer"
                    edge="start"
                    style={{
                      width: "fit-content",
                    }}
                    onClick={handleDrawerToggle}>
                    <MenuOpen />
                  </IconButton>
                </div>
              )}
              <List>
                {[
                  "Home",
                  "mhGAP Cases",
                  "MyCare+ Cases",
                  "Hamdard Force Cases",
                  "All Cases",
                  "MHWS",
                ].map((text, index) => (
                  <ListItem
                    selected={index === step}
                    button
                    key={text}
                    onClick={() => setStep(index)}>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </Hidden>

          <div className={classes.main}>
            {step === 0 && (
              <div className={classes.homeOfDashboard}>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>Admin Portal</title>
                  <meta name="description" content="Admin Portal" />
                </Helmet>
                <Paper
                  className={classes.container_flex}
                  style={{ padding: "1rem 1.5rem" }}>
                  <div className={classes.textInput}>
                    <img
                      src="./mhGap.png"
                      width="100"
                      height="100"
                      loading="lazy"
                      alt=""
                    />
                    <Typography
                      style={{ fontSize: "1.5rem", textAlign: "center" }}>
                      {
                        allCases.filter(
                          (source) => source.source === "mhGAP-PK"
                        ).length
                      }
                    </Typography>
                    <Typography style={{ textAlign: "center" }}>
                      mhGAP Cases
                    </Typography>
                  </div>
                </Paper>
                <Paper
                  className={classes.container_flex}
                  style={{ padding: "1rem 1.5rem" }}>
                  <div className={classes.textInput}>
                    <img
                      src="./mycare.png"
                      width="100"
                      height="100"
                      loading="lazy"
                      alt=""
                    />
                    <Typography
                      style={{ fontSize: "1.5rem", textAlign: "center" }}>
                      {
                        allCases.filter((source) => source.source === "MyCare")
                          .length
                      }
                    </Typography>
                    <Typography style={{ textAlign: "center" }}>
                      MyCare+ Cases
                    </Typography>
                  </div>
                </Paper>
                <Paper
                  className={classes.container_flex}
                  style={{ padding: "1rem 1.5rem" }}>
                  <div className={classes.textInput}>
                    <img
                      src="./hf.png"
                      width="100"
                      height="100"
                      loading="lazy"
                      alt=""
                    />
                    <Typography
                      style={{ fontSize: "1.5rem", textAlign: "center" }}>
                      {
                        allCases.filter(
                          (source) => source.source === "Hamdard Force"
                        ).length
                      }
                    </Typography>
                    <Typography style={{ textAlign: "center" }}>
                      Hamdard Force Cases
                    </Typography>
                  </div>
                </Paper>
                <Paper className={classes.container_flex} style={{}}>
                  <div className={classes.textInput}>
                    <img
                      src="./allcases.png"
                      width="100"
                      height="75"
                      loading="lazy"
                      alt=""
                    />
                    <Typography
                      style={{ fontSize: "1.5rem", textAlign: "center" }}>
                      {allCases.length}
                    </Typography>
                    <Typography style={{ textAlign: "center" }}>
                      All Cases
                    </Typography>
                  </div>
                </Paper>
                <Paper className={classes.container_flex} style={{}}>
                  <div className={classes.textInput}>
                    <img
                      src="./mhwps.png"
                      width="100"
                      height="73"
                      loading="lazy"
                      alt=""
                    />
                    <Typography
                      style={{ fontSize: "1.5rem", textAlign: "center" }}>
                      {mhwsCount}
                    </Typography>
                    <Typography style={{ textAlign: "center" }}>
                      MHWs
                    </Typography>
                  </div>
                </Paper>
              </div>
            )}
            {step === 1 && (
              <MHGAP
                authData={authData}
                rows={allCases.filter((source) => source.source === "mhGAP-PK")}
                onUpdate={(id, updatedItem) => {
                  setAllCases(
                    allCases.map((item) =>
                      item.id === id ? updatedItem : item
                    )
                  );
                }}
                onDelete={(id) => {
                  setAllCases(allCases.filter((item) => item.id !== id));
                }}
              />
            )}
            {step === 2 && (
              <MyCare
                authData={authData}
                rows={allCases.filter((source) => source.source === "MyCare")}
                onUpdate={(id, updatedItem) => {
                  setAllCases(
                    allCases.map((item) =>
                      item.id === id ? updatedItem : item
                    )
                  );
                }}
                onDelete={(id) => {
                  setAllCases(allCases.filter((item) => item.id !== id));
                }}
              />
            )}
            {step === 3 && (
              <HamdardForce
                authData={authData}
                rows={allCases.filter(
                  (source) => source.source === "Hamdard Force"
                )}
                onUpdate={(id, updatedItem) => {
                  setAllCases(
                    allCases.map((item) =>
                      item.id === id ? updatedItem : item
                    )
                  );
                }}
                onDelete={(id) => {
                  setAllCases(allCases.filter((item) => item.id !== id));
                }}
              />
            )}
            {step === 4 && (
              <AllCases
                authData={authData}
                rows={allCases}
                onUpdate={(id, updatedItem) => {
                  setAllCases(
                    allCases.map((item) =>
                      item.id === id ? updatedItem : item
                    )
                  );
                }}
                onDelete={(id) => {
                  setAllCases(allCases.filter((item) => item.id !== id));
                }}
              />
            )}
            {step === 5 && (
              <MHWS
                rows={mhws}
                rowCount={mhwsCount}
                changePage={(n) => fetchMHWS(n)}
              />
            )}
          </div>
        </Container>
        <Toaster />
      </div>
    </>
  );
};
export default AdminDashboard;

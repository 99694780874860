import { useEffect } from "react";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router";
import Header from "../Header";
import Case from "./Case";
import Cases from './Cases';
import Home from './Home';


const Tier2 = ({ authData, classes, logOut, redirectToHome }) => {
	let { path } = useRouteMatch();
	const history = useHistory();
	const location = useLocation();

	useEffect(
		() => redirectToHome(history, location.pathname),
		[redirectToHome, history, location.pathname]
	);

	return (
		<>
			<Header logOut={logOut} />

			<Switch>
				<Route path={`${path}/cases`}>
					<Cases
						authData={authData}
						classes={classes}
					/>
				</Route>

				<Route path={`${path}/case/:id`}>
					<Case classes={classes} authData={authData} />
				</Route>

				<Route>
					<Home
						classes={classes}
						authData={authData}
					/>
				</Route>
			</Switch>
		</>
	);
};

export default Tier2;

import { Avatar, Container, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';

const NotFound = ({ classes }) => {

	return (
		<Container component='main' maxWidth='sm' >
			<div className={`${classes.paper} ${classes.warningContainer}`}>
				<Avatar className={classes.warningAvatar}>
					<Warning />
				</Avatar>
				<Typography component='h1' variant='h5' className={classes.successText}>
					404 Page Not Found
				</Typography>
			</div>
		</Container>
	);
};

export default NotFound;

import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { useState } from "react";
import { deleteCaseById } from "../../helpers/admin.helpers";
import DeleteModel from "./DeleteModel";

const DeleteModelButton = ({ id, onDelete }) => {
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const handleDeleteModelOpen = () => {
    setDeleteModelOpen((model) => !model);
  };

  const handleConfirm = () =>
    deleteCaseById(id, (id) => {
      onDelete(id);
      setDeleteModelOpen(false);
    });

  return (
    <>
      <IconButton style={{ marginLeft: "4px" }} onClick={handleDeleteModelOpen}>
        <Delete
          fontSize="small"
          style={{
            color: "#e4403f",
          }}
        />
      </IconButton>
      {deleteModelOpen && (
        <DeleteModel
          deleteModelOpen={deleteModelOpen}
          handleDeleteModelOpen={handleDeleteModelOpen}
          handleConfirm={handleConfirm}
        />
      )}
    </>
  );
};

export default DeleteModelButton;

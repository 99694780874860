import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";

import { DISTRICTS } from "../../constants";
import { validateNotBlank, validateNum, validateOptions } from "./Validation";

const DoctorDetails = ({
  classes,
  nextStep,
  detailsState,
  setDetailsState,
  degrees,
  yearsOfExperienceOptions,
}) => {
  const changeState = (changeState) => {
    changeState.workplaceNameError = validateNotBlank(
      changeState.workplace1Name
    );

    changeState.workplaceAddressError = validateNotBlank(
      changeState.workplace1Address
    );

    changeState.degreeOrDiplomaError = validateOptions(
      changeState.degreeOrDiploma,
      degrees
    );

    changeState.otherDegreeOrDiplomaError = validateNotBlank(
      changeState.otherDegreeOrDiploma
    );

    changeState.yearsOfExperienceError = validateOptions(
      changeState.yearsOfExperience,
      yearsOfExperienceOptions
    );

    changeState.trainingUniversityError = validateNotBlank(
      changeState.trainingUniversity
    );

    changeState.yearsOfTrainingError = validateNum(
      changeState.yearsOfTraining,
      1,
      4
    );

    changeState.districtError = validateNotBlank(changeState.district);

    if ("publicSector" in changeState) {
      changeState.sectorError =
        changeState.publicSector ||
        detailsState.privateSector ||
        detailsState.ngoWorker
          ? ""
          : "Choose at least one";
    } else if ("privateSector" in changeState) {
      changeState.sectorError =
        detailsState.publicSector ||
        changeState.privateSector ||
        detailsState.ngoWorker
          ? ""
          : "Choose at least one";
    } else if ("ngoWorker" in changeState) {
      changeState.sectorError =
        detailsState.publicSector ||
        detailsState.privateSector ||
        changeState.ngoWorker
          ? ""
          : "Choose at least one";
    }

    setDetailsState((prevState) => {
      return {
        ...prevState,
        ...changeState,
      };
    });

    return {
      workplaceNameError: changeState.workplaceNameError,
      workplaceAddressError: changeState.workplaceAddressError,
      sectorError: changeState.sectorError,
      degreeOrDiplomaError: changeState.degreeOrDiplomaError,
      otherDegreeOrDiplomaError: changeState.otherDegreeOrDiplomaError,
      yearsOfExperienceError: changeState.yearsOfExperienceError,
      trainingUniversityError: changeState.trainingUniversityError,
      yearsOfTrainingError: changeState.yearsOfTrainingError,
      districtError: changeState.districtError,
    };
  };

  const validateOtherState = (errors) => {
    return (
      !errors.degreeOrDiplomaError &&
      (detailsState.degreeOrDiploma !== "Additional" ||
        !errors.otherDegreeOrDiplomaError) &&
      !errors.yearsOfExperienceError &&
      !errors.workplaceNameError &&
      !errors.workplaceAddressError &&
      !errors.sectorError
    );
  };

  const validateNextStep = () => {
    if (validateOtherState(changeState(detailsState))) nextStep();
  };

  return (
    <>
      <form className={classes.form} noValidate>
        <Typography
          component="h3"
          variant="h5"
          className={classes.formHeading}
          style={{
            color: "#38364c",
            fontSize: "14px",
            fontWeight: "bold",
            marginLeft: "30px",
          }}
        >
          WORK EXPERIENCE
        </Typography>

        <FormControl
          component="fieldset"
          className={classes.formControl}
          fullWidth
          style={{
            backgroundColor: "#EAEAEA",
            border: "2px solid black",
            borderRadius: "7px",
          }}
        >
          <Typography variant="subtitle1" className={classes.formLabel}>
            DIPLOMA/DEGREE
          </Typography>

          <TextField
            className={classes.formField}
            select
            variant="outlined"
            margin="normal"
            id="degree_or_diploma"
            name="degree_or_diploma"
            required
            fullWidth
            value={detailsState.degreeOrDiploma}
            error={detailsState.degreeOrDiplomaError !== ""}
            helperText={detailsState.degreeOrDiplomaError}
            onChange={(e) => {
              changeState({
                degreeOrDiploma: e.target.value,
                showOtherDegree: e.target.value === "Additional",
              });
            }}
          >
            {degrees.map((degree, i) => {
              return (
                <MenuItem value={degree} key={i}>
                  {degree}
                </MenuItem>
              );
            })}
          </TextField>

          {detailsState.showOtherDegree && (
            <TextField
              className={classes.formField}
              InputProps={{
                inputProps: {
                  maxLength: 100,
                },
              }}
              id="other_degree_or_diploma"
              name="other_degree_or_diploma"
              autoComplete="other_degree_or_diploma"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={detailsState.otherDegreeOrDiploma}
              error={detailsState.otherDegreeOrDiplomaError !== ""}
              helperText={detailsState.otherDegreeOrDiplomaError}
              onChange={(e) =>
                changeState({ otherDegreeOrDiploma: e.target.value })
              }
            />
          )}

          <Typography variant="subtitle1" className={classes.formLabel}>
            YEARS OF EXPERIENCE
          </Typography>

          <TextField
            className={classes.formField}
            select
            variant="outlined"
            margin="normal"
            id="years_of_experience"
            name="years_of_experience"
            required
            fullWidth
            value={detailsState.yearsOfExperience}
            error={detailsState.yearsOfExperienceError !== ""}
            helperText={detailsState.yearsOfExperienceError}
            onChange={(e) => changeState({ yearsOfExperience: e.target.value })}
          >
            {yearsOfExperienceOptions.map((option, i) => {
              return (
                <MenuItem key={i} value={option}>
                  {option} years
                </MenuItem>
              );
            })}
          </TextField>

          <Typography variant="subtitle1" className={classes.formLabel}>
            WORK SECTOR
          </Typography>

          <Grid container className={classes.checkboxContainer}>
            <Grid item lg={12}>
              <Typography>More than one field can be ticked</Typography>
            </Grid>

            <Grid item lg={12} className={classes.checkboxes}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_public_sector"
                    color="primary"
                    checked={detailsState.publicSector}
                    onChange={(e) =>
                      changeState({ publicSector: e.target.checked })
                    }
                  />
                }
                label="Public"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_private_sector"
                    color="primary"
                    checked={detailsState.privateSector}
                    onChange={(e) =>
                      changeState({ privateSector: e.target.checked })
                    }
                  />
                }
                label="Private"
              />
            </Grid>

            {detailsState.sectorError && (
              <Grid item lg={12}>
                <Typography color="error">
                  {detailsState.sectorError}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Typography variant="subtitle1" className={classes.formLabel}>
            WORK PLACE 1
          </Typography>

          <TextField
            className={classes.formField}
            InputProps={{
              inputProps: {
                maxLength: 255,
              },
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="workplace1_name"
            name="workplace1_name"
            autoComplete="workplace"
            value={detailsState.workplace1Name}
            error={detailsState.workplaceNameError}
            helperText={detailsState.workplaceNameError}
            onChange={(e) => changeState({ workplace1Name: e.target.value })}
          />

          <Typography variant="subtitle1" className={classes.formLabel}>
            ADDRESS
          </Typography>

          <TextField
            className={classes.formField}
            InputProps={{
              inputProps: {
                maxLength: 255,
              },
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="workplace1_address"
            name="workplace1_address"
            autoComplete="workplace"
            value={detailsState.workplace1Address}
            error={detailsState.workplaceAddressError}
            helperText={detailsState.workplaceAddressError}
            onChange={(e) => changeState({ workplace1Address: e.target.value })}
          />

          <Typography variant="subtitle1" className={classes.formLabel}>
            WORK PLACE 2
          </Typography>

          <TextField
            className={classes.formField}
            InputProps={{
              inputProps: {
                maxLength: 255,
              },
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            id="workplace2_name"
            name="workplace2_name"
            autoComplete="workplace_name"
            onChange={(e) => changeState({ workplace2Name: e.target.value })}
          />

          <Typography variant="subtitle1" className={classes.formLabel}>
            ADDRESS
          </Typography>

          <TextField
            className={classes.formField}
            InputProps={{
              inputProps: {
                maxLength: 255,
              },
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            id="workplace2_address"
            name="workplace2_address"
            autoComplete="workplace_address"
            value={detailsState.workplace2Address}
            onChange={(e) => changeState({ workplace2Address: e.target.value })}
          />

          <Typography variant="subtitle1" className={classes.formLabel}>
            DISTRICT
          </Typography>

          <TextField
            select
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="district"
            name="district"
            value={detailsState.district}
            error={detailsState.districtError !== ""}
            helperText={detailsState.districtError}
            onChange={(e) => changeState({ district: e.target.value })}
            className={classes.formField}
          >
            {DISTRICTS.map((district, index) => (
              <MenuItem key={index} value={district}>
                {district}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </form>

      <Button
        style={{
          backgroundColor: "#38364c",
          width: "100px",
        }}
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={validateNextStep}
      >
        Next
      </Button>
    </>
  );
};

DoctorDetails.defaultProps = {
  degrees: ["MBBS", "Additional"],
  yearsOfExperienceOptions: [">10", "5-10", "<5"],
};

export default DoctorDetails;

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import React from "react";

const DeleteModel = ({
  deleteModelOpen,
  handleConfirm,
  handleDeleteModelOpen,
}) => {
  return (
    <>
      <Dialog
        open={deleteModelOpen}
        onClose={handleDeleteModelOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this case.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteModelOpen} color="primary">
            No, cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary" autoFocus>
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteModel;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Grid,
  Container,
  Typography,
  FormControl,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Helmet } from "react-helmet-async";

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
    backgroundColor: "#eaeaea",
    border: "2px solid #cec9de",
    borderRadius: "7px",
    paddingBottom: "2rem",
  },
  textFeild: {
    backgroundColor: "#fafafa",
    border: "2px solid #cec9de",
    borderRadius: "6px",
  },
  label: {
    color: "#07D5C2",
    padding: "0.5rem",
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "left",
    textTransform: "uppercase",
  },
  checkboxes: {
    display: "flex",
    marginTop: theme.spacing(1),
    flexWrap: "wrap",
  },
  checkboxContainer: {
    backgroundColor: "white",
    borderRadius: "7px",
    border: `1px solid #cec9de`,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    alignItems: "center",
  },
  topContainer: {
    backgroundColor: "white",
    borderRadius: "7px",
    border: `1px solid #cec9de`,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    alignItems: "center",
  },
  formLayout: {
    [theme.breakpoints.down("lg")]: {
      maxWidth: "68%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "75%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  radioLayout: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  radioButton: {
    marginLeft: "11rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: "8rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "8rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-11px",
    },
  },
}));

const FeedbackForm = ({ values, setValues }) => {
  const classes = useStyle();

  return (
    <Container
      component="main"
      className={classes.formLayout}
      style={{ paddingBottom: "2rem" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Feedback</title>
        <meta name="description" content="FeedBack Form." />
      </Helmet>
      <form
        className={classes.root}
        // style={{marginLeft: '10rem', marginRight: '10rem'}}
        maxWidth="80%">
        <FormControl style={{ width: "100%", padding: "0rem" }}>
          <Typography variant="subtitle1" className={classes.label}>
            Services
          </Typography>
          <Grid
            container
            className={classes.topContainer}
            style={{ border: "3px solid #cec9de", borderRadius: "6px" }}>
            <Grid item lg={12} sm={1} xs={1} className={classes.checkboxes}>
              <FormControl
                component="fieldset"
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}>
                <RadioGroup
                  value={values}
                  color="primary"
                  style={{
                    marginLeft: "0.7rem",
                  }}
                  className={classes.radioLayout}
                  onChange={(e) => {
                    setValues({
                      service_type: e.target.value,
                    });
                  }}>
                  <FormControlLabel
                    value="ourServices"
                    control={
                      <Radio
                        checked={values.service_type === "ourServices"}
                        required={true}
                        color="primary"
                      />
                    }
                    label="Our Service"
                  />
                  <FormControlLabel
                    value="otherServices"
                    className={classes.radioButton}
                    control={
                      <Radio
                        checked={values.service_type === "otherServices"}
                        required={true}
                        color="primary"
                      />
                    }
                    label="Other Services"
                  />
                  {values.serviceTypeError && (
                    <Grid item lg={12}>
                      <Typography color="error">
                        {values.serviceTypeError}
                      </Typography>
                    </Grid>
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          {values.service_type === "otherServices" && (
            <>
              <Typography variant="subtitle1" className={classes.label}>
                Specify Service Name
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                required
                value={values.service}
                error={values.serviceError !== ""}
                helperText={values.serviceError}
                onChange={(e) => {
                  setValues({
                    service: e.target.value,
                  });
                }}
                className={classes.textFeild}
                style={{ padding: "0" }}
              />
            </>
          )}

          <Typography variant="h6" className={classes.label}>
            Name
          </Typography>

          <TextField
            variant="outlined"
            fullWidth
            required
            value={values.name}
            error={values.nameError !== ""}
            helperText={values.nameError}
            onChange={(e) => {
              setValues({
                name: e.target.value,
              });
            }}
            className={classes.textFeild}
            style={{ border: "2px solid #cec9de", borderRadius: "6px" }}
          />
          <Typography variant="h6" className={classes.label}>
            Age
          </Typography>
          <TextField
            select
            variant="outlined"
            required
            fullWidth
            autoComplete="age"
            placeholder={`16-70`}
            value={values.age}
            error={values.ageError !== ""}
            helperText={values.ageError}
            onChange={(e) => {
              setValues({
                age: e.target.value,
              });
            }}
            className={classes.textFeild}>
            {[...Array(71).keys()]
              .filter((num) => num >= 16)
              .map((num, i) => {
                return (
                  <MenuItem key={i} value={num}>
                    {num}
                  </MenuItem>
                );
              })}
          </TextField>
          <Typography variant="h6" className={classes.label}>
            Your Occupation
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            required
            value={values.occupation}
            error={values.occupationError !== ""}
            helperText={values.occupationError}
            onChange={(e) => {
              setValues({
                occupation: e.target.value,
              });
            }}
            className={classes.textFeild}
          />
          <Typography variant="h6" className={classes.label}>
            Contact Number
          </Typography>
          <TextField
            InputProps={{
              inputProps: {
                maxLength: 11,
              },
            }}
            variant="outlined"
            fullWidth
            required
            autoComplete="phone_no"
            placeholder="03xxxxxxxxx"
            value={values.phone_number}
            error={values.phoneNumberError !== ""}
            helperText={values.phoneNumberError}
            onChange={(e) => {
              setValues({
                phone_number: e.target.value,
              });
            }}
            className={classes.textFeild}
          />
          <Typography variant="h6" className={classes.label}>
            Email ID
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            required
            value={values.email}
            error={values.emailError !== ""}
            helperText={values.emailError}
            onChange={(e) => {
              setValues({
                email: e.target.value,
              });
            }}
            className={classes.textFeild}
          />
          <Typography variant="h6" className={classes.label}>
            PLEASE DESCRIBE YOUR EXPERIENCE BRIEFLY
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            required
            multiline
            rows={6}
            value={values.message}
            error={values.messageError !== ""}
            helperText={values.messageError}
            onChange={(e) => {
              setValues({
                message: e.target.value,
              });
            }}
            className={classes.textFeild}
          />
        </FormControl>
      </form>
    </Container>
  );
};

export default FeedbackForm;

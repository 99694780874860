import { useState } from 'react';
import { Button, Container, Typography } from '@material-ui/core';
import { routes, makeRequest } from './AuthApi'
import PersonalDetails from './FormParts/PersonalDetails';
import MentalHealthwDetails from './FormParts/MentalHealthwDetails';
import PasswordDetails from './FormParts/PasswordDetails';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


const MentalHealthwForm = ({ classes }) => {
	const history = useHistory();

	const [step, setStep] = useState(1);

	const [personalState, setPersonalState] = useState({
		gender: '',
		genderError: '',
		name: '',
		nameError: '',
		age: '',
		ageError: '',
		email: '',
		emailError: '',
		mobile: '',
		mobileError: ''
	});

	const [passwordState, setPasswordState] = useState({
		password: '',
		passwordError: '',
		passwordConfirmation: '',
		confirmationError: '',
	});

	const [detailsState, setDetailsState] = useState({
		qualificationError: '',
		qualification: '',
		primary_education: '',
		secondary_education: '',
		primary_educationError: '',
		graduation_degree: '',
		graduation_degreeError: '',
		secondary_educationError: '',
		masters_degree: '',
		masters_degreeError: '',
		showotheroccupation: false,
		otheroccupation: '',
		otheroccupationError: '',
		occupation: '',
		occupationError: '',
		workstudyName: '',
		workstudyNameError: '',
	});

	const nextStep = () => {
		setStep(step + 1);
	};

	const prevStep = () => {
		setStep(step - 1);
	};

	const register = async () => {
		const personalData = {
			email: personalState.email,
			name: personalState.name,
			age: personalState.age,
			gender: personalState.gender,
			phone_no: personalState.mobile,
		}

		const passwordData = {
			password: passwordState.password,
			password_confirmation: passwordState.passwordConfirmation,
		}

		// TODO rename all the state to be compatible with api names so this becomes simpler
		const detailsData = {
			primary_education: detailsState.primary_education,
			secondary_education: detailsState.secondary_education,
			graduation_degree: detailsState.graduation_degree,
			masters_degree: detailsState.masters_degree,
			profession: (
				detailsState.occupation === 'Others' ?
					detailsState.otheroccupation :
					detailsState.occupation
			),
			study_place: detailsState.workstudyName,
			workplace: detailsState.workstudyName,
		};


		const data = {
			...personalData,
			...passwordData,
			...detailsData,
		}

		try {
			const res = await makeRequest(routes.register_as_mental_health_worker_path, data);
			const resJson = await res.json();

			if (res.ok) {
				history.push('/sign_up/mentalhealthworker/success');
			}
			else {
				setPersonalState(prevState => {
					return {
						...prevState,
						genderError: resJson.gender ?? prevState.genderError,
						nameError: resJson.name ?? prevState.nameError,
						ageError: resJson.age ?? prevState.ageError,
						emailError: resJson.email ?? prevState.emailError,
						mobileError: resJson.mobile ?? prevState.mobileError
					}
				});

				setPasswordState(prevState => {
					return {
						...prevState,
						passwordError: resJson.password ?? prevState.passwordError,
						confirmationError: resJson.password_confirmation ?? prevState.confirmationError,
					};
				})

				if (['gender', 'name', 'age', 'email', 'mobile'].some(prop => prop in resJson)) {
					setStep(1);
				}
			}
		}
		catch (error) {
			console.error(error);
		}
	};

	let currentPageStep = <Typography>Some error has occurred!</Typography>;

	switch (step) {
		case 1:
			currentPageStep = (
				<PersonalDetails
					classes={classes}
					nextStep={nextStep}
					personalState={personalState}
					setPersonalState={setPersonalState}
					minAge={16}
				/>
			);
			break;
		case 2:
			currentPageStep = (
				<MentalHealthwDetails
					classes={classes}
					nextStep={nextStep}
					detailsState={detailsState}
					setDetailsState={setDetailsState}
				/>
			);
			break;
		case 3:
			currentPageStep = (
				<PasswordDetails
					classes={classes}
					register={register}
					passwordState={passwordState}
					setPasswordState={setPasswordState}
					minLength={8}
				/>
			)
			break;
		default:
			alert('ERROR!');
	}

	return (
		<Container component='main' maxWidth='sm'>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Register as Hamdard Force</title>
				<meta
					name='description'
					content='Registration form for Hamdard Force to register to MHPSS.'
				/>
			</Helmet>

			<div className={classes.paper}>
				<Typography
					component='h1'
					variant='h5'
					className={`${classes.heading} ${classes.uppercase}`}
				>
					Hamdard Force
				</Typography>

				<Typography
					component='h3'
					variant='subtitle1'
					style={{
						color:'#0FF2DD ',
						marginTop: '10px',
						fontSize: '16px',
						fontWeight : 'bold'
					}}
				>
					STEP {step} OF 3
				</Typography>

				{
					step !== 1 &&
					<Button
						style={{
							backgroundColor: '#38364c'
						}}

						variant='contained'
						color='primary'
						className={classes.submit}
						onClick={prevStep}
					>
						GO TO PREVIOUS STEP
					</Button>
				}

				{currentPageStep}
			</div>
		</Container>
	)
};

export default MentalHealthwForm;

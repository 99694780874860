export const DISTRICTS = [
  "Haripur",
  "Mansehra",
  "Mardan",
  "Peshawar",
  "Swabi",
  "Lower Dir",
  "Chitral",
  "Nowshera",
  "Peshawar",
  "Kohat",
];

import toast from "react-hot-toast";

export const addeditOutcome = async (
  newOutcomeValue,
  selectedItem,
  setSelectedItem,
  setNewOutcomeValue,
  isSetModal,
  onUpdate
) => {
  const authDataEntryForThisPage = Object.entries(localStorage).find(
    (entry) => entry[0] === "authData"
  );
  const authDataForThisPage = JSON.parse(authDataEntryForThisPage[1]);
  const requestData = {
    outcome: newOutcomeValue,
  };
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": authDataForThisPage.accessToken,
      client: authDataForThisPage.client,
      expiry: authDataForThisPage.expiry,
      "token-type": authDataForThisPage.tokenType,
      uid: authDataForThisPage.uid,
    },
    body: JSON.stringify(requestData),
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/cases/${selectedItem.id}`,
      requestOptions
    );
    if (!response.ok) throw new Error("Network response was not ok");
    onUpdate(selectedItem.id, {
      ...selectedItem,
      outcome: newOutcomeValue,
    });
    setSelectedItem({});
    setNewOutcomeValue("");
    isSetModal(false);
    toast(
      `Outcome has been ${
        selectedItem.outcome === "N/A " ? "Added" : "Updated"
      } successfully.`
    );
  } catch (error) {
    console.error("Error:", error);
  }
};

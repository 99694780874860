import { Avatar, Button, Checkbox, Container, FormControl, FormControlLabel, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import logo from '../img/mhpss.png';
import { makeRequest, routes } from './AuthApi';
import { validateEmail, validateLength } from './FormParts/Validation';


const ChangePassword = ({ classes }) => {
	const [success, setSuccess] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [passwordState, setPasswordState] = useState({
		email: '',
		oldPassword: '',
		password: '',
		passwordConfirmation: '',
		emailError: '',
		oldPasswordError: '',
		passwordError: '',
		confirmationError: ''
	});

	const setPassword = (val) => {
		const error = validateLength(val, 6);
		setPasswordState(prev => {
			return {
				...prev,
				password: val,
				passwordError: error
			}
		});

		return error === '';
	}

	const setOldPassword = val => {
		const error = validateLength(val, 6);
		setPasswordState(prev => {
			return {
				...prev,
				oldPassword: val,
				oldPasswordError: error
			}
		});

		return error === '';
	}

	const setEmail = val => {
		const error = validateEmail(val);
		setPasswordState(prev => {
			return {
				...prev,
				email: val,
				emailError: error
			}
		});

		return error === '';
	}

	const setConfirmation = val => {
		let error = '';
		if (!val) error = 'Please enter password again for confirmation';
		else if (passwordState.password !== val) error = 'Password does not match.';

		setPasswordState(prev => {
			return {
				...prev,
				passwordConfirmation: val,
				confirmationError: error
			}
		});
		return error === '';
	};

	const handleRegister = async () => {
		if (
			setEmail(passwordState.email) &&
			setOldPassword(passwordState.oldPassword) &&
			setPassword(passwordState.password) &&
			setConfirmation(passwordState.passwordConfirmation)

		) {
			try {
				const res = await makeRequest(routes.reset_password, {
					email: passwordState.email,
					current_password: passwordState.oldPassword,
					new_password: passwordState.password,
					confirm_new_password: passwordState.passwordConfirmation
				}, 'PUT')
				const resJson = await res.json();
				if (res.ok) {
					setSuccess(resJson.message);
				}
				else {
					setPasswordState(prev => {
						return {
							...prev,
							emailError: (
								resJson.message.toLowerCase().includes('email') ? resJson.message : ''
							),
							oldPasswordError: (
								resJson.message.toLowerCase().includes('password') ? resJson.message : ''
							)
						}
					})
				}
			}
			catch (e) {
				alert('Error');
				console.error(e);
			}
		}
	}

	return (
		<Container
			component='main'
			maxWidth='sm'
		>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Change Password</title>
				<meta
					name='description'
					content='Change Password'
				/>
			</Helmet>

			<div className={classes.paper}>
				<Typography
					component='h1'
					variant='h5'
					className={`${classes.heading} ${classes.uppercase}`}
				>
					Change Password
				</Typography>

				{
					success ?
						<>
							<Avatar
								className={classes.avatar}
								alt='logo'
								src={logo}
							/>
							<Typography component='h1' variant='h5' className={classes.successText}>
								{success}
							</Typography>
						</>
					:

					<>
						<form className={classes.form} noValidate>
							<FormControl
								component='fieldset'
								className={classes.formControl}
								fullWidth
								style={{
									backgroundColor: "#EAEAEA",
									border: '2px solid black',
									borderRadius: '7px'
								}}
							>
								<Typography
									variant='subtitle1'
									className={classes.formLabel}
								>
									EMAIL
								</Typography>

								<TextField
									className={classes.formField}
									variant='outlined'
									margin='normal'
									required
									fullWidth
									name='email'
									type='email'
									id='email'
									autoComplete='email'
									value={passwordState.email}
									onChange={e => setEmail(e.target.value)}
									error={passwordState.emailError !== ''}
									helperText={passwordState.emailError}
								/>

								<Typography
									variant='subtitle1'
									className={classes.formLabel}
								>
									EXISTING PASSWORD
								</Typography>

								<TextField
									className={classes.formField}
									variant='outlined'
									margin='normal'
									required
									fullWidth
									name='oldPassword'
									type={showPassword ? 'text' : 'password'}
									id='oldPassword'
									value={passwordState.oldPassword}
									onChange={e => setOldPassword(e.target.value)}
									error={passwordState.oldPasswordError !== ''}
									helperText={passwordState.oldPasswordError}
								/>

								<Typography
									variant='subtitle1'
									className={classes.formLabel}
								>
									NEW PASSWORD
								</Typography>

								<TextField
									className={classes.formField}
									variant='outlined'
									margin='normal'
									required
									fullWidth
									name='password'
									type={showPassword ? 'text' : 'password'}
									id='password'
									value={passwordState.password}
									onChange={e => setPassword(e.target.value)}
									error={passwordState.passwordError !== ''}
									helperText={passwordState.passwordError}
								/>

								<Typography
									variant='subtitle1'
									className={classes.formLabel}
								>
									PASSWORD CONFIRMATION
								</Typography>

								<TextField
									className={classes.formField}
									variant='outlined'
									margin='normal'
									required
									fullWidth
									name='password_confirmation'
									type={showPassword ? 'text' : 'password'}
									id='password_confirmation'
									value={passwordState.passwordConfirmation}
									onChange={e => setConfirmation(e.target.value)}
									error={passwordState.confirmationError !== ''}
									helperText={passwordState.confirmationError}
								/>

								<FormControlLabel
									style={{
										paddingLeft: '1rem'
									}}
									control={
										<Checkbox
											value='show_password'
											color='primary'
											checked={showPassword}
											onChange={e => setShowPassword(e.target.checked)}
										/>
									}
									label='Show Password'
								/>
							</FormControl>

						</form>

						<Button
							style={{
								backgroundColor: '#38364c',
								width: '100px',
							}}
							fullWidth
							variant='contained'
							color='primary'
							className={classes.submit}
							onClick={handleRegister}
						>
							Submit
						</Button>
					</>
				}
			</div>
		</Container>
	);
};

export default ChangePassword;

import Tooltip from "@material-ui/core/Tooltip";

import DeleteModelButton from "./DeleteModelButton";

export const columns = [
  {
    field: "id",
    headerName: "Case Id",
    width: 130,
    type: "number",
  },
  {
    field: "name",
    headerName: "Name",
    width: 250,
    editable: true,
  },
  {
    field: "age",
    headerName: "Age",
    width: 120,
    editable: true,
  },
  {
    field: "contactNumber",
    headerName: "Contact Number",
    type: "number",
    width: 200,
    editable: true,
  },
  {
    field: "source",
    headerName: "Source",
    sortable: false,
    width: 150,
  },
  {
    field: "district",
    headerName: "District",
    sortable: false,
    width: 150,
  },
  {
    field: "description",
    headerName: "Description",
    sortable: false,
    width: 500,
    renderCell: (params) => (
      <Tooltip title={params.row.description}>
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
          {params.row.description}
        </span>
      </Tooltip>
    ),
  },
  {
    field: "diagnosis",
    headerName: "Diagnosis",
    sortable: false,
    width: 250,
  },
  {
    field: "urgent?",
    headerName: "Urgent?",
    sortable: false,
    width: 130,
  },
  {
    field: "contactTeam?",
    headerName: "Contact Team?",
    sortable: false,
    width: 170,
  },
  {
    field: "outcome",
    headerName: "Outcome",
    sortable: false,
    width: 250,
  },
  {
    field: "reporterId",
    headerName: "Reporter Id",
    width: 150,
    type: "number",
    align: "center",
  },
  {
    field: "reporterName",
    headerName: "Reporter Name",
    sortable: false,
    width: 250,
  },
  {
    field: "reporterPhoneNumber",
    headerName: "Reporter Phone Number",
    sortable: false,
    width: 250,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 150,
    type: "number",
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    width: 130,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    renderCell: (params) => (
      <DeleteModelButton id={params.row.id} onDelete={params.row.delete} />
    ),
  },
];

import { Button, Container, FormControl, Typography } from "@material-ui/core";


const Step4 = ({ classes, download}) => {
	return (
		<>

			<Typography
				component='h1'
				variant='h5'
				className={`${classes.heading} ${classes.uppercase} `}
				style={{
					color: '#a1035a',
					textTransform: 'uppercase',
					fontWeight: 900,
					minWidth: '100%',
					margin: '2rem 0'
				}}
			>
				Congratulations on completing your field work
			</Typography>

			<Container
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					paddingBottom: '3rem',
					paddingLeft: '0px',
					paddingRight: '0px'
				}}
				className={classes.root}
			>
				<FormControl style={{width: '100%', padding: '0rem'}}>
				<Typography
					component='h1'
					variant='h5'
					className={`${classes.heading} ${classes.uppercase} `}
					style={{
						color:'#451062',
						textTransform: 'uppercase',
						fontWeight: 900,
						minWidth: '100%',
					}}
				>
					Step 3: Please Download your certificate
				</Typography>
				<Button
					style={{
						backgroundColor: '#9487bc',
						color: '#fafafa',
						fontSize:'0.875rem',
						width: '17rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						margin: '0 auto',
						marginTop: '2rem',
						textTransform: 'uppercase'
					}}
					onClick={download}
				>
					Download your certificate
				</Button>

				<Typography style={{color:'#a1035a', margin: '0 auto', marginTop: '0.8rem', textTransform: 'uppercase'}}>
					Thank you for your help
				</Typography>

				</FormControl>
			</Container>
		</>
	);
};

export default Step4;

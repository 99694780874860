import { Avatar, Container, Typography } from "@material-ui/core";
import { Helmet } from 'react-helmet-async';
import logo from '../../img/mhpss.png';


const Consultants = ({ classes }) => {

	return (
		<Container component='main' className={classes.criteriaPage}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Inclusion Criteria for Consultants</title>
				<meta
					name='description'
					content='Inclusion Criteria for Consultants'
				/>
			</Helmet>

			<Avatar
				className={`${classes.avatar} ${classes.pageLogo}`}
				alt='logo'
				src={logo}
			/>

			<Container className={classes.watermark}>
				<Typography
					id='consultants'
					component='h3'
					variant='h5'
					gutterBottom
				>
					Tier 3: Consultants
				</Typography>

				<Typography
					component='div'
					variant='body1'
					className={classes.listBody}
					gutterBottom
				>
					<ol>
						<li>
							Clinical Psychology (ADCP, MS, PsychD) with at least 2 years of
							supervised clinical experience
							<br />
							OR
							<br />
							Post-graduate student in Psychiatry (3rd &amp; 4th years of
							training) with a recognized training centre.
							<br />
							OR
							<br />
							Primary care physicians with clinical experience of at least 2 years
							but not more than 10 years.
						</li>
						<li>
							Should be motivated to contribute towards MHPSS project voluntarily
						</li>
						<li>Should have effective communication skills</li>
						<li>Should be willing to seek training and supervision</li>
						<li>Must uphold high ethical standards in their own practice</li>
						<li>Must have good standing in their professional circle</li>
						<li>Should be interested in continuous professional development</li>
						<li>Two references needed</li>
						<li>Should be based in Islamabad</li>
					</ol>
				</Typography>
			</Container>
		</Container>
	);
};

export default Consultants;

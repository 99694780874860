import { Button, Container, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import MyCareIconNew from '../img/MyCareIconNew.png';
import watermark from '../img/watermark.png';
import { makeRequest, routes } from './AuthApi';
import PasswordDetails from './FormParts/PasswordDetails';
import { validateEmail, validateExists, validateMobile, validateName, validateNum, validateLength } from './FormParts/Validation';


const useStyle= makeStyles(theme => ({
	main: {
		marginTop: theme.spacing(4),
		width: '50%',
		backgroundColor: '#EAEAEA',
		[theme.breakpoints.down('xs')]: {
			width:'100%'
		},
	},
	heading: {
		textAlign: 'center',
		backgroundColor: '#EAEAEA',
		fontWeight: 'bold',
		fontSize: '1.4rem',
		padding: '5px 0 5px 0',
		marginBottom: theme.spacing(2),
		justifyContent: 'center',
	},
	uppercase: {
		textTransform: 'uppercase'
	},
	watermark: {
		backgroundImage: `url(${watermark})`,
		backgroundRepeat: 'no-repeat',
		backgroundColor: 'transparent',
		backgroundPosition: 'center',
		padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
		backgroundSize: 'contained',
	},
	topHeader: {
		textAlign: "center",
		padding: '10',
		background: "#eaeaea",
		color: "#383838",
		fontSize: '14',
		fontFamily: "Arial",
		fontWeight: '400'
	},
	right: {
		textAlign: "right",
		fontFamily: "Arial",
		[theme.breakpoints.down('sm')]: {
			alignItems:'center'

		},
		[theme.breakpoints.down('xs')]: {
			alignItems:'center'

		},
		[theme.breakpoints.down('lg')]: {
			alignItems:'center'

		},
		[theme.breakpoints.down('md')]: {
			alignItems:'center'

		}
	},
	center: {
		textAlign: "center",
		fontFamily: "Arial",
	},
	logo: {
		width: '7rem',
		height: '7rem',
		objectFit: "contain",
		display: 'flex',
		margin: 'auto',
	},
	form: {
		width: '50%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
		margin: '0 auto',
		[theme.breakpoints.down('md')]: {
			width:'75%'

		},
		[theme.breakpoints.down('xs')]: {
			width:'85%'

		},
	},
	formControl: {
		margin: `${theme.spacing(3)}px 0`,
		padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
		border: `2px solid ${theme.palette.primary.light}`,
	},
	formLabel: {
		color: '#07D5C2',
		fontSize: '0.825rem',
		fontWeight: 'bold',
		marginLeft: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	resp:{
		width: '50%',
		[theme.breakpoints.down('xs')]: {
			width:'75%'

		},
	},
}))

const FrontlineRespondersForm = ({ routerClasses }) => {
	const classes= useStyle();

	const [values, setValues]= useState({
		name: '',
		nameError: '',
		age: '',
		ageError: '',
		occupation: '',
		occupationError: '',
		phone_no: '',
		phoneNumberError: '',
		email: '',
		emailError: '',
		description: '',
		descriptionError: '',
		organization: '',
		organizationError: ''
	});
	const [passwordState, setPasswordState] = useState({
		password: '',
		passwordError: '',
		passwordConfirmation: '',
		confirmationError: '',
	});

	const [showForm, setShowForm] = useState(null);
	const [step, setStep] = useState(1);

	const moveToPassword = async () => {
		const errors = changeState(values);

		if (!errors.emailError) {
			try {
				const res = await fetch(`${routes.user_exist}?email=${values.email}`);
				const json = await res.json();

				errors.emailError = json.data.error;
			}
			catch (e) {
				console.error(e);
			}
		}

		if (!errors.phoneNumberError) {
			try {
				const res = await fetch(`${routes.phone_exist}?phone_no=${values.phone_no}`);
				const json = await res.json();

				errors.phoneNumberError = json.data.error;
			}
			catch (e) {
				console.error(e);
			}
		}

		if (
			!errors.nameError && !errors.ageError &&
			!errors.occupationError && !errors.emailError &&
			!errors.phoneNumberError && !errors.organizationError &&
			!errors.descriptionError
		) {
			setStep(2);
		}
		else {
			setValues(prevState => ({
				...prevState,
				...errors
			}));
		}
	};


	const submit = async () => {
		const data = {
			...values,
			password: passwordState.password,
			password_confirmation: passwordState.passwordConfirmation,
		}
		const res = await makeRequest(routes.frontline_responders, data);
		const resJson = await res.json();
		if (res.ok) {
			setStep(3);
		}
		else {
			console.error(resJson);
		}
	};

	const changeState = (changeState) => {
		changeState.nameError = validateName(changeState.name);
		changeState.ageError = validateNum(changeState.age, 16, 70);
		changeState.emailError = validateEmail(changeState.email);
		changeState.phoneNumberError = validateMobile(changeState.phone_no);
		changeState.descriptionError = validateLength(changeState.description, 1, 250);
		changeState.occupationError = validateExists(changeState.occupation);
		changeState.organizationError = validateExists(changeState.organization);


		setValues(prevState => {
			return {
				...prevState,
				...changeState,
			}
		});

		return {
			nameError: changeState.nameError,
			ageError: changeState.ageError,
			emailError: changeState.emailError,
			phoneNumberError: changeState.phoneNumberError,
			descriptionError: changeState.descriptionError,
			occupationError: changeState.occupationError
		}
	}

	return (
		<>
			<Helmet>
					{/* TITLE */}
				<meta charSet="utf-8" />
				<title>Frontline Responders</title>
				<meta
					name='frontlineWorkers'
					content='FrontlineWorkers'
				/>
			</Helmet>

			<Header />

			{
				step !== 3 ?
					<>
						<Container
							component='main'
							className={classes.main}
						>
							<Typography
								component='h1'
								variant='h5'
								className={`${classes.heading} ${classes.uppercase}`}
								style={{
									color:'#59567f',
								 	textTransform: 'uppercase',
									fontWeight: 900,

								 }}
							>
								 Frontline Responders
							</Typography>
						</Container>

						<form className={classes.form}>

							{
								step === 1 ?
									<>
										<FormControl
											component='fieldset'
											className={classes.formControl}
											fullWidth
											style={{
												backgroundColor: "#EAEAEA",
												border: '2px solid #cec9de',
												borderRadius: '7px',
											}}
										>
											<Typography
												variant='subtitle1'
												className={classes.formLabel}
												style={{
													fontFamily: 'Arial',
													textTransform: 'uppercase',
													fontSize: '1rem',
													fontWeight: 550
												}}
											>
												Are you based in Islamabad?
											</Typography>

											{/* RADIO BUTTONS */}

											<RadioGroup
												row
												style={{
													marginTop: '0.5rem',
													backgroundColor: 'white',
													width: '100%',
													borderRadius: '5px',
													display: 'flex',
													justifyContent: 'space-around',
													marginBottom: '0.5rem'
												}}
											>
												<FormControlLabel
													value="location"
													control={
														<Radio
															checked={showForm}
															onChange={e => setShowForm(true)}
															color='primary'
														/>
													}
													label="Yes"
												/>

												<FormControlLabel
													value="location"
													control={
														<Radio
															checked={showForm === false}
															onChange={e => setShowForm(false)}
															color='primary'
														/>
													}
													label="No"
												/>
											</RadioGroup>

									{
										showForm === false && (

											<Typography
												variant='subtitle1'
												className={classes.formLabel}
												style={{
													fontFamily: 'Arial',
													textTransform: 'uppercase',
													fontSize: '1rem',
													color: '#ff2301',
													fontWeight: "normal",
												}}
											>
												this service is available in islamabad only
											</Typography>
										)
									}

										</FormControl>


										{/* 2ND FORM ON THE PAGE WHICH ASKS DATA FROM USER  */}

										{
											showForm &&
											<>
												<FormControl
													component='fieldset'
													className={classes.formControl}
													fullWidth
													style={{
														backgroundColor: "#EAEAEA",
														border: '2px solid #cec9de',
														borderRadius: '7px',
													}}
												>

													{/* NAME TEXT FIELD */}

													<Typography
														variant='subtitle1'
														className={classes.formLabel}
														style={{
															fontFamily: 'Arial',
															textTransform: 'uppercase',
															fontSize: '1rem',
															fontWeight: 550
														}}
													>
														name
													</Typography>

													<TextField
														className={classes.formField}
														InputProps={{
															inputProps: {
																maxLength: 100,
															}
														}}
														variant='outlined'
														margin='normal'
														required
														fullWidth
														id='name'
														name='name'
														autoComplete='name'
														autoFocus
														style={{
															backgroundColor: 'white',
															border: '2px solid #cec9de',
															borderRadius: '7px'
															}}
														value= {values.name}
														error={values.nameError !== ''}
														helperText={values.nameError}
														onChange={e => {
															changeState({
																name: e.target.value
															});
														}}
													/>

													{/* AGE FIELD */}

													<Typography
														variant='subtitle1'
														className={classes.formLabel}
														style={{
															fontFamily: 'Arial',
															textTransform: 'uppercase',
															fontSize: '1rem',
															fontWeight: 550
														}}
													>
														age
													</Typography>

													<TextField
														className={classes.formField}
														select
														variant='outlined'
														margin='normal'
														required
														fullWidth
														id='age'
														name='age'
														autoComplete='age'
														style={{
															backgroundColor: 'white',
															border: '2px solid #cec9de',
															borderRadius: '7px'
															}}
														value={values.age}
														error={values.ageError !== ''}
														helperText={values.ageError}
														onChange={e => {
															changeState({
																age: e.target.value
															});
														}}
													>
														{
															[...Array(71).keys()].filter(num => num >= 16).map((num, i) => {
																return (
																	<MenuItem key={i} value={num}>
																		{num}
																	</MenuItem>
																);
															})
														}
													</TextField>

													{/* OCCUPATION FIELD */}

													<Typography
														variant='subtitle1'
														className={classes.formLabel}
														style={{
															fontFamily: 'Arial',
															textTransform: 'uppercase',
															fontSize: '1rem',
															fontWeight: 550
														}}
													>
														occupation
													</Typography>

													<TextField
														className={classes.formField}
														InputProps={{
															inputProps: {
																maxLength: 100,
															}
														}}
														variant='outlined'
														margin='normal'
														required
														fullWidth
														id='occupation'
														name='occupation'
														style={{
															backgroundColor: 'white',
															border: '2px solid #cec9de',
															borderRadius: '7px'
															}}
														value={values.occupation}
														error={values.occupationError !== ''}
														helperText={values.occupationError}
														onChange={e => {
															changeState({
																occupation: e.target.value
															});
														}}
													/>

													{/* ORGANIZATION/INSTITUTION FIELD */}

													<Typography
														variant='subtitle1'
														className={classes.formLabel}
														style={{
															fontFamily: 'Arial',
															textTransform: 'uppercase',
															fontSize: '1rem',
															fontWeight: 550,
														}}
													>
														organization / institution
													</Typography>

													<TextField
														className={classes.formField}
														InputProps={{
															inputProps: {
																maxLength: 100,
															}
														}}
														variant='outlined'
														margin='normal'
														required
														fullWidth
														id='organization/institution'
														name='organization/institution'
														style={{
															backgroundColor: 'white',
															border: '2px solid #cec9de',
															borderRadius: '7px'
															}}
														value={values.organization}
														error={values.organizationError !== ''}
														helperText={values.organizationError}
														onChange={e => {
															changeState( {
																organization: e.target.value
															});
														}}
													/>


													{/* CONTACT INFO FIELD */}

													<Typography
														variant='subtitle1'
														className={classes.formLabel}
														style={{
															fontFamily: 'Arial',
															textTransform: 'uppercase',
															fontSize: '1rem',
															fontWeight: 550
														}}
													>
														contact number
													</Typography>

													<TextField
														className={classes.formField}
														InputProps={{
															inputProps: {
																maxLength: 11,
															}
														}}
														variant='outlined'
														margin='normal'
														required
														fullWidth
														autoComplete='phone_no'
														placeholder='03xx xxxxxxx'
														id='phone_no'
														name='phone_no'
														style={{
															backgroundColor: 'white',
															border: '2px solid #cec9de',
															borderRadius: '7px'
															}}
														value={values.phone_no}
														error={values.phoneNumberError !== ''}
														helperText={values.phoneNumberError}
														onChange={e => {
															changeState( {
																phone_no: e.target.value
															});
														}}
													/>

													{/* EMAIL FIELD */}

													<Typography
														variant='subtitle1'
														className={classes.formLabel}
														style={{
															fontFamily: 'Arial',
															textTransform: 'uppercase',
															fontSize: '1rem',
															fontWeight: 550
														}}
													>
														email id
													</Typography>

													<TextField
														className={classes.formField}
														variant='outlined'
														margin='normal'
														required
														fullWidth
														autoComplete='email'
														id='email'
														name='email'
														style={{
															backgroundColor: 'white',
															border: '2px solid #cec9de',
															borderRadius: '7px'
															}}
														value={values.email}
														error={values.emailError !== ''}
														helperText={values.emailError}
														onChange={e => {
															changeState({
																email: e.target.value
															});
														}}
													/>

													<Typography
														variant='subtitle1'
														className={classes.formLabel}
														style={{
															fontFamily: 'Arial',
															textTransform: 'uppercase',
															fontSize: '1rem',
															fontWeight: 550
														}}
													>
														Please describe your problem briefly
													</Typography>

													<TextField
														className={classes.formField}
														InputProps={{
															inputProps: {
																maxLength: 250,
															}
														}}
														variant='outlined'
														margin='normal'
														required
														fullWidth
														id='TextBox'
														name='TextBox'
														placeholder='(Max 250 characters)'
														multiline
														rows={15}
														style={{
															backgroundColor: 'white',
															marginBottom: '2rem',
															border: '2px solid #cec9de',
															borderRadius: '7px'
															}}
														value={values.description}
														error={values.descriptionError !== ''}
														helperText={values.descriptionError}
														onChange={e => {
															changeState({
																description: e.target.value
															});
														}}
													/>

												</FormControl>

												{/* REGISTER BUTTON */}

												<Button
													style={{
														backgroundColor: 'rgb(89, 86, 127)',
														width: '100px',
														display: 'flex',
														marginLeft: 'auto',
														marginRight: 'auto',
														marginBottom: '3rem',
														fontWeight: 900
													}}
													variant='contained'
													color='primary'
													className={classes.submit}
													onClick={moveToPassword}
												>
													Next
												</Button>
											</>
										}
									</>
									:
									<PasswordDetails
										classes={routerClasses}
										register={submit}
										passwordState={passwordState}
										setPasswordState={setPasswordState}
										registerStyles={{
											backgroundColor: 'rgb(89, 86, 127)',
											width: '100px',
											display: 'flex',
											marginLeft: 'auto',
											marginRight: 'auto',
										}}
									/>
							}
						</form>
					</>
					:
					<>
						<Typography
							variant='subtitle1'
							className={classes.resp}
							style={{
								fontFamily: 'Arial',
								textTransform: 'uppercase',
								fontSize: '1rem',
								fontWeight: 550,
								color: '#ff2301',
								display: 'flex',
								margin: '2rem auto',
								marginTop: '5rem'
							}}
						>
							thank you for your registration.
						</Typography>

						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginBottom: '1rem'
							}}
						>
							<Typography
								variant='subtitle1'
								className={classes.resp}
								style={{
									fontFamily: 'Arial',
									textTransform: 'uppercase',
									fontSize: '1rem',
									fontWeight: 550,
									color: '#000',
									justifyContent: 'center',
									whiteSpace: 'initial',
								}}
							>
								you will receive an email when
								<img
									src={MyCareIconNew}
									alt=""
									style={{
										width: 80,
										height: 30,
										margin: '0 0.5rem'
									}}
								/>
								mobile application is ready for download.<br />
								this application will help you assess and manage your stress. <br />
								it will also help you seek advice from our counsellors.
							</Typography>
						</div>

						<Typography
							variant='subtitle1'
							className={classes.resp}
							style={{
								fontFamily: 'Arial',
								textTransform: 'uppercase',
								fontSize: '1rem',
								fontWeight: 550,
								color: '#ff2301',
								display: 'flex',
								marginLeft: 'auto',
								marginRight: 'auto',
								marginBottom: '1rem'
							}}
						>
							if you wish to speak to our team, please book an appointment.
						</Typography>

						<Button
							style={{
								backgroundColor: '#59567f',
								width: '15rem',
								display: 'flex',
								marginLeft: 'auto',
								marginRight: 'auto',
								marginBottom: '2rem',
								fontWeight: 900
							}}
							variant='contained'
							color='primary'
							className={classes.submit}
							component={Link}
							to='/appointment'
						>
							BOOK AN APPOINTMENT
						</Button>
					</>
			}
		</>
	);
};

export default FrontlineRespondersForm;

import { CssBaseline, makeStyles } from "@material-ui/core";
import { useCallback, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { makeAuthRequest, routes } from "../Auth/AuthApi";
import BookAppointment from "../Auth/BookAppointment";
import ChangePassword from "../Auth/ChangePassword";
import ClinicalPsychologist from "../Auth/ClinicalPsychologist";
import DoctorForm from "../Auth/DoctorForm";
import ForgotPassword from "../Auth/ForgotPassword";
import FrontlineRespondersForm from "../Auth/FrontlineRespondersForm";
import GiveFeedBack from "../Auth/GiveFeedBack";
import MentalHealthWorkerForm from "../Auth/MentalHealthWorkerForm";
import PsychiatristForm from "../Auth/PsychiatristForm";
import ResetPassword from "../Auth/ResetPassword";
import Signup from "../Auth/Signup";
import SubmissionSuccess from "../Auth/SubmissionSuccess";
import MappingForms from "../Mapping/MappingForms";
import banner from "../img/banner.png";
import watermark from "../img/watermark.png";
import DeleteRequest from "../pages/DeleteRequest";
import {
  getAuthData,
  getCourseData,
  saveAuthData,
  saveCourseData,
} from "../services/AuthStorage";
import AdminDashboard from "./Admin/AdminDashboard";
import Consultants from "./CriteriaPages/Consultants";
import Counsellors from "./CriteriaPages/Counsellors";
import EvidenceBased from "./CriteriaPages/EvidenceBased";
import HamdardForce from "./CriteriaPages/HamdardForce";
import Specialists from "./CriteriaPages/Specialists";
import Hamdard from "./Hamdard/Hamdard";
import NotFound from "./NotFound";
import PrivacyPolicy from "./PrivacyPolicy";
import Root from "./Root";
import Support from "./Support";
import Team from "./Team";
import Tier2 from "./Tier2/Tier2";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      overflowX: "hidden",
      height: "100%",
    },
    html: {
      overflowX: "hidden",
      height: "100%",
    },
  },
  main: {
    marginTop: theme.spacing(4),
    width: "50%",
    backgroundColor: "#EAEAEA",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  modal: {
    backgroundColor: "white",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "50%",
    borderRadius: "1rem",
  },
  loader: {
    width: "6rem !important",
    height: "6rem !important",
    color: "#07D5C2",
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
  },
  paperBackground: {
    backgroundColor: "#fafafa",
    border: "solid black",
    borderWidth: "0.15rem",
    borderRadius: "0.3rem",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  bold: {
    fontWeight: "bold",
  },
  avatar: {
    margin: theme.spacing(1),
    width: 172,
    height: 84,
    backgroundColor: theme.palette.background.main,
    borderRadius: 0,
  },
  pageLogo: {
    margin: "auto",
  },
  banner: {
    width: "100vw",
  },
  criteriaPage: {
    marginTop: theme.spacing(2),
  },
  heading: {
    textAlign: "center",
    backgroundColor: "#EAEAEA",
    fontWeight: "bold",
    fontSize: "1.4rem",
    padding: "5px 0 5px 0",
    marginBottom: theme.spacing(2),
  },
  mapHeading: {
    color: "#59567f",
    textTransform: "uppercase",
    margin: "1rem 0",
  },
  links: {
    alignSelf: "normal",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    flexGrow: 1,
  },
  formControl: {
    margin: `${theme.spacing(3)}px 0`,
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    border: `2px solid ${theme.palette.primary.light}`,
  },
  formLabel: {
    color: "#07D5C2",
    fontSize: "0.825rem",
    fontWeight: "bold",
    marginLeft: theme.spacing(1),
  },
  mapFormLabel: {
    color: "#07D5C2",
    marginLeft: theme.spacing(1),
    fontFamily: "Arial",
    textTransform: "uppercase",
    fontSize: "1rem",
    fontWeight: 550,
  },
  formField: {
    margin: `${theme.spacing(1)}px 0`,
    backgroundColor: "white",
    borderRadius: "7px",
  },
  controlError: {
    borderColor: theme.palette.error.main,
  },
  checkboxContainer: {
    backgroundColor: "white",
    borderRadius: "7px",
    border: `1px solid #bbb`,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    alignItems: "center",
  },
  checkboxes: {
    display: "flex",
    marginTop: theme.spacing(1),
    flexWrap: "wrap",
  },
  formHeading: {
    margin: `${theme.spacing(2)}px 0`,
  },
  successAvatar: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
  warningAvatar: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.error.main,
  },
  warningContainer: {
    padding: theme.spacing(4),
    border: `2px solid ${theme.palette.error.main}`,
  },
  watermark: {
    backgroundImage: `url(${watermark})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "transparent",
    backgroundPosition: "center",
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    marginLeft: "4.25rem",
  },
  listBody: {
    fontSize: "1.25rem",
    "& > ol > li": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  successText: {
    textAlign: "center",
  },
  caseGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: 10,
    padding: 0,
    marginBottom: "3rem",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
    },
  },
  case: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: "#eaeaea",
    border: "2px solid #cec9de",
    borderRadius: "7px",
    position: "relative",
  },
  purpleText: {
    color: "#451062",
  },
  warningText: {
    color: " #a1035a",
    textAlign: "center",
  },
  caseInfo: {
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  caseTop: {
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
  },
  caseText: {
    marginTop: "1rem",
  },
  homeButton: {
    backgroundColor: "#9487bc",
    color: "#fafafa",
    fontSize: "0.875rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "0 auto",
    marginTop: "1rem",
    textTransform: "uppercase",

    "&:hover": {
      backgroundColor: "#451062",
    },
  },
  input: {
    backgroundColor: "#fafafa",
    border: "2px solid #cec9de",
    borderRadius: "6px",
    marginTop: 8,
    marginBottom: 8,
  },
  caseValue: {
    marginLeft: "0.5rem",
  },
  resp: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "75%",
    },
  },
  containerBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const AppRouter = () => {
  const classes = useStyles();
  const [authData, setAuthData] = useState(getAuthData());
  const [courseData, setCourseData] = useState(getCourseData());

  const redirectToHome = useCallback(
    (history, currentPath) => {
      if (authData && Object.keys(authData).length) {
        history.push(
          authData.role === "Admin"
            ? "/admin"
            : authData.role === "Tier 2"
            ? currentPath?.includes("home")
              ? currentPath
              : "/home"
            : "/hamdard/home"
        );
      } else {
        history.push("/hamdard/login");
      }
    },
    [authData]
  );

  const setAuth = useCallback((authData, body) => {
    localStorage.clear();
    if (body.data) {
      setCourseData(() => {
        let courseData = {
          language: body.data.user_data.pfa_course_language,
          link: null,
          progress: null,
          lang_request: null,
          registered: false,
        };
        if (body.data.user_data.pfa_course_language) {
          const course = body.data.courses[0];
          courseData = {
            language: body.data.user_data.pfa_course_language,
            link: course ? course.course_url : null,
            progress: course ? course.progress : null,
            lang_request: body.data.user_data.change_lang_request,
            registered: true,
          };
        }
        saveCourseData(courseData);
        return courseData;
      });
    }

    setAuthData(() => {
      saveAuthData(authData);
      return authData;
    });
  }, []);

  const logOut = useCallback(
    async (history) => {
      try {
        makeAuthRequest(routes.log_out, {}, "DELETE", authData);
        setAuthData({});
        setCourseData({});
        localStorage.clear();
        history.push("/");
      } catch (e) {
        console.error(e);
      }
    },
    [authData]
  );

  return (
    <Router>
      <CssBaseline />
      <Switch>
        {"registration.mhpss.pk" !== window.location.href.split("/")[2] && (
          <Route exact path="/" children={<Root />} />
        )}
        <Route path="/change_password" exact>
          <ChangePassword classes={classes} />
        </Route>

        <Route path="/hamdard">
          <Hamdard
            authData={authData}
            setAuth={setAuth}
            courseData={courseData}
            setCourseData={setCourseData}
            classes={classes}
            redirectToHome={redirectToHome}
            logOut={logOut}
          />
        </Route>

        <Route path="/home">
          <Tier2
            authData={authData}
            classes={classes}
            logOut={logOut}
            redirectToHome={redirectToHome}
          />
        </Route>

        <Route path="/admin">
          <AdminDashboard
            authData={authData}
            logOut={logOut}
            redirectToHome={redirectToHome}
          />
        </Route>

        <Route path="/forgot_password">
          <ForgotPassword classes={classes} />
        </Route>

        <Route path="/reset_password">
          <ResetPassword classes={classes} />
        </Route>

        <Route path="/feedback">
          <GiveFeedBack classes={classes} />
        </Route>

        <Route path="/appointment">
          <BookAppointment classes={classes} />
        </Route>

        <Route path="/privacy_policy">
          <PrivacyPolicy classes={classes} />
        </Route>

        <Route path="/support">
          <Support classes={classes} />
        </Route>

        <Route path="/team">
          <img
            src={banner}
            alt="logo"
            className={`${classes.logo} ${classes.banner}`}
            height="200px"
          />
          <Team classes={classes} />
        </Route>

        <Route exact path="/sign_up/success">
          <SubmissionSuccess classes={classes} />
        </Route>

        <Route exact path={"/sign_up"}>
          <img
            src={banner}
            alt="logo"
            className={`${classes.logo} ${classes.banner}`}
            height="200px"
          />
          <Signup classes={classes} />
        </Route>

        <Route exact path="/sign_up/clinical_psychologist">
          <img
            src={banner}
            alt="logo"
            className={`${classes.logo} ${classes.banner}`}
            height="200px"
          />
          <ClinicalPsychologist classes={classes} />
        </Route>

        <Route exact path="/sign_up/psychiatrist">
          <img
            src={banner}
            alt="logo"
            className={`${classes.logo} ${classes.banner}`}
            height="200px"
          />
          <PsychiatristForm classes={classes} />
        </Route>

        <Route exact path="/sign_up/doctor">
          <img
            src={banner}
            alt="logo"
            className={`${classes.logo} ${classes.banner}`}
            height="200px"
          />
          <DoctorForm classes={classes} />
        </Route>

        <Route
          exact
          path="/sign_up/mentalhealthworker/success"
          children={<SubmissionSuccess classes={classes} />}>
          <SubmissionSuccess classes={classes} mhw={true} />
        </Route>

        <Route exact path="/sign_up/mentalhealthworker">
          <img
            src={banner}
            alt="logo"
            className={`${classes.logo} ${classes.banner}`}
            height="200px"
          />
          <MentalHealthWorkerForm classes={classes} />
        </Route>

        <Route
          path="/evidence-based"
          children={<EvidenceBased classes={classes} />}
        />
        <Route path="/support" children={<Support classes={classes} />} />
        <Route
          path="/frontline_workers"
          children={<FrontlineRespondersForm routerClasses={classes} />}
        />
        <Route path="/mapping_resources">
          <MappingForms classes={classes} />
        </Route>
        <Route
          path="/inclusion_criteria/hamdard-force"
          children={<HamdardForce classes={classes} />}
        />
        <Route
          path="/inclusion_criteria/consultants"
          children={<Consultants classes={classes} />}
        />
        <Route
          path="/inclusion_criteria/counsellors"
          children={<Counsellors classes={classes} />}
        />
        <Route
          path="/inclusion_criteria/specialists"
          children={<Specialists classes={classes} />}
        />
        <Route path="/deletion-request" children={<DeleteRequest />} />
        <Route children={<NotFound classes={classes} />} />
      </Switch>
    </Router>
  );
};

export default AppRouter;

import {
  Grid,
  Button,
  makeStyles,
  IconButton,
  Hidden,
} from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import Logo from "../img/Logo.png";
import ministryLogo from "../img/ministry-logo.png";
import unicef from "../img/unicef.png";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  navbar: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    padding: "0px 30px",
    [theme.breakpoints.up("sm")]: {},
  },
  topHeader: {
    textAlign: "center",
    padding: "10",
    background: "#eaeaea",
    color: "#383838",
    fontSize: "14",
    fontFamily: "Arial",
    fontWeight: "400",
  },
  logo: {
    width: "7rem",
    height: "7rem",
    objectFit: "contain",
    display: "flex",
    margin: "auto",
  },
  center: {
    textAlign: "center",
    fontFamily: "Arial",
  },
  right: {
    textAlign: "right",
    fontFamily: "Arial",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("lg")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
}));

const Header = ({ logOut, handleDrawerToggle, open }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Grid
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.navbar}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {!open && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          )}

          <Link to="/">
            <img
              src={Logo}
              alt="logo"
              style={{ width: 120 }}
              className={classes.logo}
            />
          </Link>
        </div>

        {logOut && (
          <Button
            size="medium"
            style={{
              fontSize: 12,
              background: "#cab6cf",
              fontFamily: "Arial",
              height: 30,
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
            onClick={() => logOut(history)}>
            Log out
          </Button>
        )}
      </Grid>

      <Grid
        className={classes.topHeader}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs={false} lg={1} sm={false}></Grid>
        <Grid item xs={12} lg={2} sm={3}>
          <img src={ministryLogo} alt="logo" className={classes.logo} />
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          sm={6}
          className={classes.center}
          style={{ fontSize: "1rem", fontFamily: "Arial", fontWeight: 700 }}>
          Mental Health &amp; Psychosocial Support <br />
          A Project of Planning, Development &amp; Special Initiatives <br />
          Government of Pakistan
        </Grid>

        <Grid item xs={12} lg={2} sm={3} className={classes.right}>
          <img src={unicef} alt="logo" className={classes.logo} style={{}} />
        </Grid>
        <Grid item xs={false} lg={1} sm={false}></Grid>
      </Grid>
    </>
  );
};

export default Header;

import { Container, Switch, Typography } from "@material-ui/core";


const CaseItem = ({ item, classes, children }) => {
	return (
		<Container className={classes.case}>
			<Container className={classes.caseTop}>
				<Typography
					className={`${classes.caseInfo} ${classes.purpleText}`}
				>
					{item.created_at}
				</Typography>

				<Typography
					className={`${classes.caseInfo} ${classes.purpleText}`}
				>
					{item.status}
				</Typography>
			</Container>

			<Container className={classes.caseText}>
				<Typography variant="subtitle2" gutterBottom>
					MHPSS CASE ID:
					<Typography
						variant="body1"
						component="span"
						className={classes.caseValue}
					>
						{item.id}
					</Typography>
				</Typography>

				<Typography variant="subtitle2" gutterBottom>
					NTC CASE ID:
					<Typography
						variant="body1"
						component="span"
						className={classes.caseValue}
					>
						{item.NTC_case_id}
					</Typography>
				</Typography>

				<Typography variant="subtitle2" gutterBottom>
					Name:
					<Typography
						variant="body1"
						component="span"
						className={classes.caseValue}
					>
						{item.name}
					</Typography>
				</Typography>

				<Typography variant="subtitle2" gutterBottom>
					Age:
					<Typography
						variant="body1"
						component="span"
						className={classes.caseValue}
					>
						{item.age}
					</Typography>
				</Typography>

				<Typography variant="subtitle2">
					Description:
					<Typography
						variant="body1"
						component="span"
						className={classes.caseValue}
					>
						{item.description}
					</Typography>
				</Typography>

				<Container
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: 0,
						margin: '0.25rem 0'
					}}
				>
					<Typography variant="subtitle2">
						Urgent
					</Typography>

					<Switch
						className={classes.toggle}
						checked={item.is_urgent}
						disableRipple
					/>
				</Container>

				<Container
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: 0,
						margin: '0.25rem 0'
					}}
				>
					<Typography variant="subtitle2">
						Contact the team
					</Typography>

					<Switch
						className={classes.toggle}
						checked={item.needs_supervisor}
						disableRipple
					/>
				</Container>
			</Container>

			{ children }
		</Container>
	);
};

export default CaseItem;

import {
	Button, FormControl, MenuItem, TextField, Typography
} from '@material-ui/core';

import {
	validateOptions, validateNum, validateEmail, validateMobile, validateExists,
	validateName
} from './Validation';

import { routes } from '../AuthApi';


const PersonalDetails = ({
	classes, nextStep,
	personalState, setPersonalState,
	genderOptions, minAge
}) => {

	const changeState = (changeState) => {
		changeState.nameError = validateName(changeState.name);
		changeState.ageError = validateNum(changeState.age, personalState.minAge, 70);
		changeState.genderError = validateOptions(changeState.gender, genderOptions);
		changeState.emailError = validateEmail(changeState.email);
		changeState.mobileError = validateMobile(changeState.mobile);
		changeState.showGraduateError = validateExists(changeState.showGraduate);

		setPersonalState(prevState => {
			return {
				...prevState,
				...changeState,
			}
		});

		return {
			nameError: changeState.nameError,
			ageError: changeState.ageError,
			genderError: changeState.genderError,
			emailError: changeState.emailError,
			mobileError: changeState.mobileError,
			showGraduateError: changeState.showGraduateError,
		};
	}

	const validateNextStep = async () => {
		const errors = changeState(personalState);

		if (!errors.emailError) {
			try {
				const res = await fetch(`${routes.user_exist}?email=${personalState.email}`);
				const json = await res.json();

				errors.emailError = json.data.error;
			}
			catch (e) {
				console.error(e);
			}
		}

		if (!errors.mobileError) {
			try {
				const res = await fetch(`${routes.phone_exist}?phone_no=${personalState.mobile}`);
				const json = await res.json();

				errors.mobileError = json.data.error;
			}
			catch (e) {
				console.error(e);
			}
		}

		if (
			!errors.nameError && !errors.ageError &&
			!errors.genderError && !errors.emailError &&
			!errors.mobileError && !errors.showGraduateError
		) {
			nextStep();
		}
		else {
			setPersonalState(prevState => { return {
				...prevState,
				...errors
			}});
		}
	};

	return (
		<>

			<form className={classes.form} noValidate >
				<Typography
					variant='subtitle1'
					style={{
						color: '#38364c',
						fontSize: '14px',
						fontWeight : 'bold',
						marginLeft: '30px'
					}}
				>
					PERSONAL DETAILS
				</Typography>

				<FormControl
					component='fieldset'
					className={classes.formControl}
					fullWidth
					style={{
						backgroundColor: "#EAEAEA",
						border: '2px solid black',
						borderRadius: '7px'
					}}
				>

					<Typography
						variant='subtitle1'
						className={classes.formLabel}
					>
						FULL NAME *
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 100,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='name'
						placeholder='This name will appear on your certificate'
						name='name'
						autoComplete='name'
						autoFocus
						value={personalState.name}
						error={personalState.nameError !== ''}
						helperText={personalState.nameError}
						onChange={e => {
							changeState({
								name: e.target.value.toUpperCase(),
							});
						}}
					/>

					<Typography
						variant='subtitle1'
						className={classes.formLabel}
					>
						GENDER *
					</Typography>

					<TextField
						className={classes.formField}
						select
						variant='outlined'
						margin='normal'
						id='gender'
						name='gender'
						required
						fullWidth
						value={personalState.gender}
						error={personalState.genderError !== ''}
						helperText={personalState.genderError}
						onChange={e => {
							changeState({
								gender: e.target.value,
							});
						}}
					>
						{
							genderOptions.map((gender, i) => {
								return (
									<MenuItem key={i} value={gender}>
										{gender}
									</MenuItem>
								);
							})
						}
					</TextField>

					<Typography
						variant='subtitle1'
						className={classes.formLabel}
					>
						AGE *
					</Typography>

					<TextField
						className={classes.formField}
						select
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='age'
						name='age'
						autoComplete='age'
						placeholder={`${minAge}-70`}
						value={personalState.age}
						error={personalState.ageError !== ''}
						helperText={personalState.ageError}
						onChange={e => {
							changeState({
								age: e.target.value,
							});
						}}
					>
						{
							[...Array(71).keys()].filter(num => num >= minAge).map((num, i) => {
								return (
									<MenuItem key={i} value={num}>
										{num}
									</MenuItem>
								);
							})
						}
					</TextField>

					<Typography
						variant='subtitle1'
						className={classes.formLabel}
					>
						EMAIL ADDRESS *
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 100,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='email'
						name='email'
						autoComplete='email'
						value={personalState.email}
						error={personalState.emailError !== ''}
						helperText={personalState.emailError}
						onChange={e => {
							changeState({
								email: e.target.value,
							});
						}}
					/>

					<Typography
						variant='subtitle1'
						className={classes.formLabel}
					>
						YOUR MOBILE NUMBER *
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 11
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='phone_no'
						name='phone_no'
						autoComplete='phone_no'
						placeholder='03xxxxxxxxx'
						value={personalState.mobile}
						error={personalState.mobileError !== ''}
						helperText={personalState.mobileError}
						onChange={e => {
							changeState({
								mobile: e.target.value,
							});
						}}
					/>
				</FormControl>
			</form>

			{
				personalState.showGraduate !== undefined &&
				<form
					className={classes.form}
					noValidate
				>
					<FormControl
						component='fieldset'
						className={classes.formControl}
						fullWidth
						style={{
							backgroundColor: "#EAEAEA",
							border: '2px solid black',
							borderRadius: '7px'
						}}
					>
						<Typography
							variant='subtitle1'
							className={classes.formLabel}
						>
							Have you completed Post Graduate Training?
						</Typography>

						<TextField
							className={classes.formField}
							select
							variant='outlined'
							margin='normal'
							id='showGraduate'
							name='showGraduate'
							required
							fullWidth
							value={personalState.showGraduate}
							error={personalState.showGraduateError !== ''}
							helperText={personalState.showGraduateError}
							onChange={e => {
								changeState({
									showGraduate: e.target.value,
								});
							}}
						>
							<MenuItem value='yes'>
								Yes
							</MenuItem>
							<MenuItem value='no'>
								No
							</MenuItem>
						</TextField>
					</FormControl>
				</form>
			}

			<Typography
				variant='subtitle1'
				style={{
					color: '#38364c',
					fontSize: '12px',
					fontWeight : 'bold',
					alignSelf: 'flex-start'
				}}
			>
				* MUST BE COMPLETED
			</Typography>

			<Button
				style={{
					backgroundColor: '#38364c',
					width: '100px'
				}}
				variant='contained'
				color='primary'
				className={classes.submit}
				onClick={validateNextStep}
			>
				Next
			</Button>
		</>
	)
};

PersonalDetails.defaultProps = {
	genderOptions: [
		'Male',
		'Female'
	],
	minAge: 20
};

export default PersonalDetails;

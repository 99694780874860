import { Button, FormControl, MenuItem, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';


const Step1 = ({
	state,
	changeState,
	classes,
	nextStep,
	areaOptions,
	professionOptions,
	ageOptions
}) => {
	return (
		<>
			<form className={classes.form}>

				<FormControl
					component='fieldset'
					className={classes.formControl}
					fullWidth
					style={{
						backgroundColor: "#EAEAEA",
						border: '2px solid #cec9de',
						borderRadius: '7px',
					}}
				>

					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						Area *
					</Typography>

					<TextField
						className={classes.formField}
						select
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='area'
						name='area'
						value={state.area}
						onChange={e => changeState({ area: e.target.value })}
						error={state.areaError !== ''}
						helperText={state.areaError}
					>
						{

								<MenuItem  disabled selected>
									Please Select Your Area
								</MenuItem>

						}
						{
							areaOptions.map((option, i) => (
								<MenuItem key={`area-${i}`} value={option}>
									{option}
								</MenuItem>
							))
						}
					</TextField>

					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						Profession *
					</Typography>

					<TextField
						className={classes.formField}
						select
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='profession'
						name='profession'
						value={state.profession}
						onChange={e => changeState({ profession: e.target.value })}
						error={state.professionError !== ''}
						helperText={state.professionError}
					>
						{
							professionOptions.map((option, i) => (
								<MenuItem key={`profession-${i}`} value={option}>
									{option}
								</MenuItem>
							))
						}
					</TextField>
				</FormControl>

			</form>

			<Typography
				component='h2'
				variant='h5'
				className={clsx(classes.heading, classes.uppercase, classes.mapHeading)}
				style={{fontWeight: 900,}}
			>
				Personal Details
			</Typography>

			<form className={classes.form} >

				<FormControl
					component='fieldset'
					className={classes.formControl}
					fullWidth
					style={{
						backgroundColor: "#EAEAEA",
						border: '2px solid #cec9de',
						borderRadius: '7px',
					}}
				>

					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						full name *
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 100,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='name'
						name='name'
						autoComplete='name'
						value={state.name}
						onChange={e => changeState({ name: e.target.value })}
						error={state.nameError !== ''}
						helperText={state.nameError}
					/>

					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						Gender *
					</Typography>

					<TextField
						className={classes.formField}
						select
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='gender'
						name='gender'
						value={state.gender}
						onChange={e => changeState({ gender: e.target.value })}
						error={state.genderError !== ''}
						helperText={state.genderError}
					>
						<MenuItem value='Male'>
							Male
						</MenuItem>
						<MenuItem value='Female'>
							Female
						</MenuItem>
					</TextField>

					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						age *
					</Typography>

					<TextField
						className={classes.formField}
						select
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='age'
						name='age'
						value={state.age}
						onChange={e => changeState({ age: e.target.value })}
						error={state.ageError !== ''}
						helperText={state.ageError}
					>
						{
							ageOptions.map((num, i) => {
								return (
									<MenuItem key={`age-${i}`} value={num}>
										{num}
									</MenuItem>
								);
							})
						}
					</TextField>

					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						email Address *
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 100,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						autoComplete='email'
						id='email'
						name='email'
						value={state.email}
						onChange={e => changeState({ email: e.target.value })}
						error={state.emailError !== ''}
						helperText={state.emailError}
					/>

					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						contact number *
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 11,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						placeholder='03xx xxxxxxx'
						id='mobile'
						name='mobile'
						autoComplete='mobile'
						value={state.mobile}
						onChange={e => changeState({ mobile: e.target.value })}
						error={state.mobileError !== ''}
						helperText={state.mobileError}
					/>


					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						city *
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 100,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='city'
						name='city'
						autoComplete='city'
						value={state.city}
						onChange={e => changeState({ city: e.target.value })}
						error={state.cityError !== ''}
						helperText={state.cityError}
					/>


					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						district *
					</Typography>

					<TextField
						className={classes.formField}
						InputProps={{
							inputProps: {
								maxLength: 100,
							}
						}}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='district'
						name='district'
						autoComplete='district'
						value={state.district}
						onChange={e => changeState({ district: e.target.value })}
						error={state.districtError !== ''}
						helperText={state.districtError}
					/>

				</FormControl>
			</form>

			<Typography
				variant='subtitle1'
				style={{
					color: '#38364c',
					fontSize: '1rem',
					fontWeight: 'bold',
					alignSelf: 'flex-start',
					marginLeft: '2rem'
				}}
			>
				* MUST BE COMPLETED
			</Typography>

			<Button
				style={{
					backgroundColor: '#59567f',
					width: '100px',
					display: 'flex',
					marginLeft: 'auto',
					marginRight: 'auto',
					fontWeight: 900
				}}
				variant='contained'
				color='primary'
				className={classes.submit}
				onClick={nextStep}
			>
				Next
			</Button>
		</>
	)
}

export default Step1;

import React from 'react';
import { Avatar, Container, Typography } from "@material-ui/core";
import { Helmet } from 'react-helmet-async';
import logo from '../../img/mhpss.png';


const HamdardForce = ({ classes }) => {

	return (
		<Container component='main' className={classes.criteriaPage}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Inclusion Criteria for Hamdard Force</title>
				<meta
					name='description'
					content='Inclusion Criteria for Hamdard Force'
				/>
			</Helmet>

			<Avatar
				className={`${classes.avatar} ${classes.pageLogo}`}
				alt='logo'
				src={logo}
			/>

			<Container className={classes.watermark}>
				<Typography
					id='hamdard-force'
					component='h3'
					variant='h5'
					gutterBottom
				>
					Tier 1: Hamdard Force (Mental health workers)
				</Typography>

				<Typography
					component='div'
					variant='body1'
					className={classes.listBody}
					gutterBottom
				>
					<ol>
						<li>High school education* (FSc /A Levels)</li>
						<li>Active engagement with the community</li>
						<li>
							Should be motivated to contribute towards MHPSS project voluntarily
						</li>
						<li>Should have effective communication skills</li>
						<li>Should be willing to seek training and supervision </li>
						<li>Must uphold high ethical standards </li>
						<li>Must have good standing in their community</li>
						<li>Should be interested in continuous professional development</li>
						<li>Two references needed</li>
						<li>Should be based in Islamabad</li>
					</ol>
				</Typography>

				<Typography variant='caption' display='block' gutterBottom>
					*The educational requirement might be modified in rural communities.
				</Typography>
			</Container>
		</Container>
	);
};

export default HamdardForce;

const host = `${process.env.REACT_APP_API_HOST}/api`;

const routes = {
  register_individual: `${host}/mapping/individual`,
  register_organization: `${host}/mapping/organization`,
  register_as_clinical_psychologists: `${host}/register_as_clinical_psychologists`,
  register_as_psychiatrists_path: `${host}/register_as_psychiatrists`,
  register_as_mental_health_worker_path: `${host}/auth/register_as_mental_health_worker`,
  register_as_doctors_path: `${host}/register_as_doctors`,
  user_exist: `${host}/user_exist`,
  phone_exist: `${host}/phone_no_exist`,
  give_feedback: `${host}/feedbacks`,
  log_in: `${host}/auth/web_portal_login`,
  log_out: `${host}/auth/sign_out`,
  hamdard_reload: `${host}/web_portal/reload`,
  book_appointment: `${host}/web_portal/book_an_appointment`,
  language_select: `${host}/web_portal/user_enrol_hamdard_course`,
  change_language: `${host}/web_portal/switch_hamdard_course_lang`,
  get_psychiatrists: `${host}/psychiatrists`,
  get_psychologists: `${host}/clinical_psychologists`,
  get_mhws: `${host}/mental_health_workers`,
  get_mhws_csv: `${host}/mental_health_workers/csv`,
  get_doctors: `${host}/doctors`,
  get_allcases: `${host}/webportal/cases`,
  forgotpassword: `${host}/auth/password`,
  change_password: `${host}/auth/change_password`,
  reset_password: `${host}/auth/reset_password`,
  get_assigned_cases: `${host}/case_assignments`,
  add_comments: `${host}/web_portal/add_comments`,
  get_statuses: `${host}/status_lookups`,
  count_mhw: `${host}/count_mhw`,
  frontline_responders: `${host}/register_as_frontline_responders`,
  certificate: `${host}/certificate`,
  get_cases: `${host}/cases`,
  mhw_trained_count: `${host}/mhw_trained_count`,
  delete_case: `${host}/cases`,
  delete_request: `${host}/users/request-deletion`,
};

const makeRequest = async (url = "", data = {}, method = "POST") => {
  // Default options are marked with *
  const response = await fetch(url, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: !data || Object.keys(data).length === 0 ? null : JSON.stringify(data),
    // body data type must match 'Content-Type' header
  });

  return response;
};

const makeAuthRequest = async (
  url = "",
  data = {},
  method = "POST",
  authData = {}
) => {
  const response = await fetch(url, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": authData.accessToken,
      client: authData.client,
      expiry: authData.expiry,
      "token-type": authData.tokenType,
      uid: authData.uid,
    },
    body: !data || Object.keys(data).length === 0 ? null : JSON.stringify(data),
  });
  return response;
};

export { makeAuthRequest, makeRequest, routes };

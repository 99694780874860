import {
	Button, Checkbox, CircularProgress, FormControl, FormControlLabel, TextField, Typography
} from '@material-ui/core';
import { useEffect, useState } from 'react';


const PasswordDetails = ({
	classes, register,
	passwordState, setPasswordState,
	minLength, registerStyles
}) => {

	const [showPassword, setShowPassword] = useState(false);

	const [showLoader, setShowLoader] = useState(false);

	const validatePassword = val => {
		let error = '';

		if (val) {
			if (val.length < minLength) {
				error = `is too short (minimum is ${minLength} characters)`;
			}
		}
		else {
			error = 'Password cannot be blank.';
		}

		setPasswordState(prev => {
			return {
				...prev,
				password: val,
				passwordError: error
			}
		});

		return error === '';
	}

	const validateConfirmation = val => {
		let error = '';
		if (!val) error = 'Please enter password again for confirmation';
		else if (passwordState.password !== val) error = 'Password does not match.';

		setPasswordState(prev => {
			return {
				...prev,
				passwordConfirmation: val,
				confirmationError: error
			}
		});
		return error === '';
	};

	const handleRegister = () => {
		if (
			validatePassword(passwordState.password) &&
			validateConfirmation(passwordState.passwordConfirmation)
		) {
			setShowLoader(true);
			register();
		}
	}

	useEffect(() => {
		return () => {
			setShowLoader(false);
		}
	}, [])

	return (
		<>
			<form className={classes.form} noValidate>
				<FormControl
					component='fieldset'
					className={classes.formControl}
					fullWidth
					style={{
						backgroundColor: "#EAEAEA",
						border: '2px solid rgb(206, 201, 222)',
						borderRadius: '7px'
					}}
				>
					<Typography
						variant='subtitle1'
						className={classes.formLabel}
					>
						PASSWORD
					</Typography>

					<TextField
						className={classes.formField}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						name='password'
						type={showPassword ? 'text' : 'password'}
						id='password'
						autoComplete='password'
						value={passwordState.password}
						onChange={e => validatePassword(e.target.value)}
						error={passwordState.passwordError !== ''}
						helperText={passwordState.passwordError}
						style={{ border: '2px solid rgb(206, 201, 222)' }}
					/>

					<Typography
						variant='subtitle1'
						className={classes.formLabel}
					>
						PASSWORD CONFIRMATION
					</Typography>

					<TextField
						className={classes.formField}
						variant='outlined'
						margin='normal'
						required
						fullWidth
						name='password_confirmation'
						type={showPassword ? 'text' : 'password'}
						id='password_confirmation'
						autoComplete='password-confirm'
						value={passwordState.passwordConfirmation}
						onChange={e => validateConfirmation(e.target.value)}
						error={passwordState.confirmationError !== ''}
						helperText={passwordState.confirmationError}
						style={{ border: '2px solid rgb(206, 201, 222)' }}
					/>

					<FormControlLabel
						style={{
							paddingLeft: '1rem'
						}}
						control={
							<Checkbox
								value='show_password'
								color='primary'
								checked={showPassword}
								onChange={e => setShowPassword(e.target.checked)}
							/>
						}
						label='Show Password'
					/>
				</FormControl>

			</form>

			<Button
				style={{
					backgroundColor: '#38364c',
					width: '100px',
					...registerStyles
				}}
				fullWidth
				variant='contained'
				color='primary'
				className={classes.submit}
				onClick={handleRegister}
				disabled={showLoader}
			>
				{
					showLoader ?
						<CircularProgress color="inherit" size={24} />
						:
						'Register'
				}
			</Button>
		</>
	)
};

PasswordDetails.defaultProps = {
	minLength: 6,
};


export default PasswordDetails;

import { Button, Container, Typography } from '@material-ui/core';

const Step3 = ({ classes }) => {
	return (
		<>
			<Typography
				component='h1'
				variant='h5'
				className={`${classes.heading} ${classes.uppercase} `}
				style={{
					color: '#a1035a',
					textTransform: 'uppercase',
					fontWeight: 900,
					minWidth: '100%',
					margin: '2rem 0'
				}}
			>
				Congratulations on completing your course
			</Typography>
			<Container
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					paddingBottom: '3rem',
					paddingLeft: '0px',
					paddingRight: '0px'
				}}
				className={classes.root}
			>
				<Typography
					component='h1'
					variant='h5'
					className={`${classes.heading} ${classes.uppercase} `}
					style={{
						color:'#451062',
						textTransform: 'uppercase',
						fontWeight: 900,
						minWidth: '100%',
					}}
				>
					Step 2: Please Download the hamdard force app
				</Typography>
				<Button
					style={{
						backgroundColor: '#9487bc',
						color: '#fafafa',
						fontSize:'0.875rem',
						width: '18rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						margin: '0 auto',
						marginTop: '2rem',
						textTransform: 'uppercase'
					}}
					href='https://apps.apple.com/pk/app/hamdard-force/id1583191739'
					target='_blank'
				>
					Go to App Store
				</Button>

				<Button
					style={{
						backgroundColor: '#9487bc',
						color: '#fafafa',
						fontSize:'0.875rem',
						width: '18rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						margin: '0 auto',
						marginTop: '2rem',
						textTransform: 'uppercase'
					}}
					href='https://play.google.com/store/apps/details?id=com.hamdardforce'
					target='_blank'
				>
					Go to Play Store
				</Button>
			</Container>
		</>
	);
};

export default Step3;

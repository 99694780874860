import { Grid, makeStyles, useTheme } from "@material-ui/core";
import Logo from "../img/Logo.png";
import ministryLogo from "../img/ministry-logo.png";
import unicef from "../img/unicef.png";
import React from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: "68%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "75%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem",
  },
  paperBackground: {
    backgroundColor: "#fafafa",
    border: "solid black",
    borderWidth: "0.15rem",
    borderRadius: "0.3rem",
  },
  topHeader: {
    textAlign: "center",
    padding: "10",
    background: "#eaeaea",
    color: "#383838",
    fontSize: "14",
    fontFamily: "Arial",
    fontWeight: "400",
  },
  logo: {
    width: "7rem",
    height: "7rem",
    objectFit: "contain",
    display: "flex",
    margin: "auto",
  },
  center: {
    textAlign: "center",
    fontFamily: "Arial",
  },
}));

const GuestLayout = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <>
      <Grid
        container
        style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={Logo}
          alt="logo"
          style={{ width: 120 }}
          className={classes.logo}
        />
      </Grid>
      <Grid
        className={classes.topHeader}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={0} lg={1} sm={0}></Grid>
        <Grid item xs={12} lg={2} sm={3}>
          <img src={ministryLogo} alt="logo" className={classes.logo} />
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          sm={6}
          className={classes.center}
          style={{ fontSize: "1rem", fontFamily: "Arial", fontWeight: 700 }}
        >
          Mental Health &amp; Psychosocial Support <br />
          A Project of Planning, Development &amp; Special Initiatives <br />
          Government of Pakistan
        </Grid>

        <Grid item xs={12} lg={2} sm={3} className={classes.right}>
          <img src={unicef} alt="logo" className={classes.logo} style={{}} />
        </Grid>
        <Grid item xs={0} lg={1} sm={0}></Grid>
      </Grid>

      <div className={classes.paper}>{children}</div>
    </>
  );
};

export default GuestLayout;

import { Button, Checkbox, CircularProgress, Container, FormControl, FormControlLabel, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useState } from 'react';
import Clinic from './Clinic';
import Hospital from './Hospital';
import Ngo from './Ngo';

const Step3 = ({
	classes,
	prevStep,
	register,
	profession,
	state,
	changeState,
	positionOptions,
	academicOptions,
	nonAcademicOptions,
	hospitalTypeOptions
}) => {
	const [loading, setLoading] = useState(false);

	const clickRegister = async () => {
		setLoading(true);
		const newLoading = await register();
		setLoading(newLoading);
	};

	return (
		<>
			<Typography
				component='h2'
				variant='h5'
				className={clsx(classes.heading, classes.uppercase, classes.mapHeading)}
			>
				Work Sector
			</Typography>

			<Typography
				component='h3'
				variant='h5'
				style={{
					marginLeft: '2rem',
					fontWeight: 'bold'
				}}
			>
				{profession}
			</Typography>

			<form className={classes.form}>

				<FormControl
					component='fieldset'
					className={classes.formControl}
					fullWidth
					style={{
						backgroundColor: "#EAEAEA",
						border: '2px solid #cec9de',
						borderRadius: '7px',
					}}
				>

					<Typography
						variant='subtitle1'
						className={classes.mapFormLabel}
					>
						Work Sector *
					</Typography>

					<Grid
						container
						className={classes.checkboxContainer}
					>
						<Grid item lg={12}>
							<Typography>
								More than one field can be ticked
							</Typography>
						</Grid>

						<Grid
							item
							lg={12}
							className={classes.checkboxes}
							style={{
								justifyContent: 'space-between'
							}}
						>
							<FormControlLabel
								control={
									<Checkbox
										name='is_public_sector'
										color='primary'
										checked={state.isPublic}
										onChange={e => changeState({ isPublic: e.target.checked })}
									/>
								}
								label='Public'
								classes={{
									label: clsx(classes.uppercase, classes.bold)
								}}
							/>
							<FormControlLabel
								control={
									<Checkbox
										name='is_private_sector'
										color='primary'
										checked={state.isPrivate}
										onChange={e => changeState({ isPrivate: e.target.checked })}
									/>
								}
								label='Private'
								classes={{
									label: clsx(classes.uppercase, classes.bold)
								}}
							/>
							<FormControlLabel
								control={
									<Checkbox
										name='is_ngo_sector'
										color='primary'
										checked={state.isNgo}
										onChange={e => changeState({ isNgo: e.target.checked })}
									/>
								}
								label='Ngo'
								classes={{
									label: clsx(classes.uppercase, classes.bold)
								}}
							/>
						</Grid>

						{
							state.sectorError &&
							<Grid item lg={12}>
								<Typography color='error'>
									{state.sectorError}
								</Typography>
							</Grid>
						}
					</Grid>
				</FormControl>
			</form>

			{
				state.isPublic && (
					<>
						<Typography
							component='h3'
							variant='h5'
						>
							Public
						</Typography>

						<Hospital
							classes={classes}
							state={state.publicHospital}
							changeState={changes => changeState({
								publicHospital: changes
							})}
							positionOptions={positionOptions}
							academicOptions={academicOptions}
							nonAcademicOptions={nonAcademicOptions}
							typesOptions={hospitalTypeOptions}
						/>
					</>
				)
			}

			{
				state.isPrivate && (
					<>
						<Typography
							component='h3'
							variant='h5'
						>
							Private
						</Typography>

						<FormControl
							component='fieldset'
							className={classes.formControl}
							fullWidth
							style={{
								backgroundColor: "#EAEAEA",
								border: '2px solid #cec9de',
								borderRadius: '7px',
							}}
						>

							<Typography
								variant='subtitle1'
								className={classes.mapFormLabel}
							>
								Clinical Practice
							</Typography>

							<Grid
								container
								className={classes.checkboxContainer}
							>
								<Grid item lg={12}>
									<Typography>
										More than one field can be ticked
									</Typography>
								</Grid>

								<Grid
									item
									lg={12}
									className={classes.checkboxes}
									style={{
										justifyContent: 'space-between'
									}}
								>
									<FormControlLabel
										control={
											<Checkbox
												name='showHospital'
												color='primary'
												checked={state.showPrivateHospital}
												onChange={e => changeState({ showPrivateHospital: e.target.checked })}
											/>
										}
										label='Hospital'
										classes={{
											label: clsx(classes.uppercase, classes.bold)
										}}
									/>
									<FormControlLabel
										control={
											<Checkbox
												name='showClinic'
												color='primary'
												checked={state.showPrivateClinic}
												onChange={e => changeState({ showPrivateClinic: e.target.checked })}
											/>
										}
										label='Independent Clinic'
										classes={{
											label: clsx(classes.uppercase, classes.bold)
										}}
									/>
								</Grid>
							</Grid>
						</FormControl>

						{
							state.showPrivateHospital && (
								<Hospital
									classes={classes}
									state={state.privateHospital}
									changeState={changes => changeState({
										privateHospital: changes
									})}
									positionOptions={positionOptions}
									academicOptions={academicOptions}
									nonAcademicOptions={nonAcademicOptions}
									typesOptions={hospitalTypeOptions}
								/>
							)
						}

						{
							state.showPrivateClinic && (
								<Clinic
									classes={classes}
									state={state.clinic}
									changeState={changes => changeState({
										clinic: changes
									})}
								/>
							)
						}
					</>
				)
			}

			{
				state.isNgo && (
					<>
						<Typography
							component='h3'
							variant='h5'
						>
							NGO
						</Typography>

						<Ngo
							classes={classes}
							state={state.ngo}
							changeState={changes => changeState({
								ngo: changes
							})}
						/>
					</>
				)
			}

			<Container className={classes.containerBetween}>

				<Button
					style={{
						backgroundColor: '#59567f',
						width: '100px',
						display: 'flex',
						fontWeight: 900
					}}
					variant='contained'
					color='primary'
					className={classes.submit}
					onClick={prevStep}
				>
					Back
				</Button>

				<Button
					style={{
						backgroundColor: '#59567f',
						width: '100px',
						display: 'flex',
						fontWeight: 900
					}}
					variant='contained'
					color='primary'
					className={classes.submit}
					onClick={clickRegister}
				>
					{
						loading ?
							<CircularProgress color="inherit" size={24} />
							:
							'Register'
					}
				</Button>
			</Container>
		</>
	)
};

export default Step3;

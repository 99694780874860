import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography, FormControl } from "@material-ui/core";
import { Helmet } from "react-helmet-async";

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1.3rem",
    backgroundColor: "#eaeaea",
    border: "1px solid #cec9de",
    borderRadius: "7px",
    paddingBottom: "2rem",
  },
  textFeild: {
    backgroundColor: "#fafafa",
    border: "1px solid #cec9de",
    borderRadius: "6px",
  },
  label: {
    color: "#5D616D",
    padding: "0.5rem",
    fontSize: "16px",
    fontWeight: "800",
    textAlign: "left",
    textTransform: "uppercase",
  },
  formLayout: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "520px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
}));

const DeleteForm = ({ values, setValues }) => {
  const classes = useStyle();

  return (
    <div className={classes.formLayout} style={{ paddingBottom: "2rem" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Delete Request</title>
        <meta name="description" content="Send delete account request form." />
      </Helmet>
      <form className={classes.root} autoComplete="off">
        <FormControl style={{ width: "100%", padding: "0rem" }}>
          <Typography variant="subtitle1" className={classes.label}>
            Mobile Number
          </Typography>
          <TextField
            variant="outlined"
            autoComplete="off"
            fullWidth
            required
            aut
            value={values.name}
            error={values.numberError && values.numberError !== ""}
            helperText={values.numberError}
            placeholder="03XX XXX XXXX"
            onChange={(e) => {
              setValues({
                mobileNumber: e.target.value,
              });
            }}
            className={classes.textFeild}
            style={{ padding: "0" }}
          />
          <Typography variant="h6" className={classes.label}>
            Password
          </Typography>

          <TextField
            variant="outlined"
            fullWidth
            required
            type="password"
            value={values.email}
            error={values.passwordError && values.passwordError !== ""}
            helperText={values.passwordError}
            onChange={(e) => {
              setValues({
                password: e.target.value,
              });
            }}
            className={classes.textFeild}
          />
          <Typography variant="h6" className={classes.label}>
            Reason for Deletion
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            required
            multiline
            rows={6}
            value={values.reason_for_deletion}
            error={values.reasonError && values.reasonError !== ""}
            helperText={values.reasonError}
            onChange={(e) => {
              setValues({
                reason_for_deletion: e.target.value,
              });
            }}
            className={classes.textFeild}
          />
        </FormControl>
      </form>
    </div>
  );
};

export default DeleteForm;

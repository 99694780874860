import React from 'react';
import { Avatar, Container, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import logo from '../../img/mhpss.png';


const Specialists = ({ classes }) => {

	return (
		<Container component='main' className={classes.criteriaPage}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Inclusion Criteria for Specialists</title>
				<meta
					name='description'
					content='Inclusion Criteria for Specialists'
				/>
			</Helmet>

			<Avatar
				className={`${classes.avatar} ${classes.pageLogo}`}
				alt='logo'
				src={logo}
			/>

			<Container className={classes.watermark}>
				<Typography
					id='specialists'
					component='h3'
					variant='h5'
					gutterBottom
				>
					Tier 4: Specialists
				</Typography>

				<Typography
					component='div'
					variant='body1'
					className={classes.listBody}
					gutterBottom
				>
					<ol>
						<li>
							Post-graduate qualification in Psychiatry
							(FCPS, MRCPsych, American Board of Psychiatry)
							<br />
							OR
							<br />
							Clinical Psychology (ADCP, MS, PsychD)
						</li>
						<li>
							Should have at least 5 years of clinical experience
							(but not more than 10 years)
							including at least 2 years of MHPSS experience
						</li>
						<li>
							Should be motivated to contribute towards MHPSS project voluntarily
						</li>
						<li>Should have effective communication skills</li>
						<li>Should be willing to provide supervision</li>
						<li>Must uphold high ethical standards in their own practice</li>
						<li>Must have good standing in their professional circle</li>
						<li>Should be interested in continuous professional development</li>
						<li>Two references needed</li>
						<li>Should be based in Islamabad</li>
					</ol>
				</Typography>
			</Container>
		</Container>
	);
};

export default Specialists;

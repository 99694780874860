import { Avatar, Container, Typography, Button } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import logo from '../img/mhpss.png';

const SubmissionSuccess = ({ classes, mhw }) => {
	return (
		<Container component='main' maxWidth='md' >
			<Helmet>
				<meta charSet="utf-8" />
				<title>Success!!</title>
				<meta
					name='description'
					content='Success Page shown, when user has successfully registered.'
				/>
			</Helmet>

			<div className={classes.paper}>
				<Avatar
					className={classes.avatar}
					alt='logo'
					src={logo}
				/>
				<Typography
					component='h1'
					variant='h5'
					className={classes.successText}
					gutterBottom
				>
					Thank you for completing your registration.
				</Typography>
				{
					mhw &&
					<>
						<Typography
							component='h1'
							variant='h5'
							className={classes.successText}
							gutterBottom
						>
							You can now Login by using your phone number and password.
						</Typography>

						<Button
							style={{
								backgroundColor: ' #59567f',
								color: '#fafafa',
								fontSize: '0.8rem',
							}}
							href='https://mhpss.pk/hamdard/login'
						>
							Login
						</Button>
					</>
				}

			</div>
		</Container>
	);
};

SubmissionSuccess.defaultProps = {
	mhw: false
};

export default SubmissionSuccess;

import {
	Box, Button, FormControl, Grid, LinearProgress, Typography
} from '@material-ui/core';

const Step2 = ({ classes, courseData, langData, changeLanguageConfirmation, download }) => {

	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}
				className={classes.formLayout}>
				<Typography
					component='h1'
					variant='h5'
					className={`${classes.heading} ${classes.uppercase} ${classes.languageTitle}`}
					style={{
						color: '#451062',
						textTransform: 'uppercase', fontWeight: 900, minWidth: '100%'
					}}
				>
					Progress of your course
				</Typography>


			</div>
			<form className={`${classes.root} ${classes.formLayout}`}
				style={{ minWidth: '100%' }}
			>
				<FormControl style={{ width: '100%', padding: '0rem' }}>
					<Typography style={{ color: ' #a1035a', textAlign: 'center', textTransform: 'uppercase' }}>
						You have not completed your course
					</Typography>
					<Box style={{ width: '100%', color: 'a1035a' }}>
						<LinearProgress
							variant="determinate"
							value={courseData.progress || 0}
							className={`${classes.progressBar} ${classes.colorPrimary}`}
							classes={{
								barColorPrimary: classes.barColorPrimary
							}}
						/>
					</Box>
					<Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						<div style={{ marginLeft: 0 }}>
							<Typography style={{ marginLeft: 0 }}>0%</Typography>
						</div>
						<div style={{ alignText: 'center' }}>
							<Typography
								style={{ textAlign: 'center' }}
							>
								{parseInt(courseData.progress) || 0}%
							</Typography>
						</div>
						<div style={{ marginRight: 0 }}>
							<Typography style={{ marginRight: 0 }}>100%</Typography>
						</div>
					</Grid>
					<Typography style={{ color: ' #a1035a', textAlign: 'center', marginTop: '1rem', marginBottom: '1rem', textTransform: 'uppercase' }}>
						Your selected language is: <span style={{ fontWeight: 'bold' }}>{courseData?.language}</span>
					</Typography>
					{
						langData.changed || courseData.lang_request ?
							<Typography
								style={{
									color: ' #451062',
									textAlign: 'center',
									marginTop: '1rem',
									textTransform: 'uppercase',
									fontWeight: 'bold'
								}}
							>
								{
									langData.changed ?
										langData.changed === 'success' ?
											`Your language is changed successfully to ${courseData.language}`
											:
											`Sorry, your language couldn't be changed at the moment. Please try again later`
										:
										'Your request for language change is under review. We will email you when your course language is switched.'

								}
							</Typography>
							:
							<>
								<Typography style={{ color: ' #a1035a', textAlign: 'center', textTransform: 'uppercase' }}>
									If you like, you can change the language of the course
								</Typography>
								<Button
									style={{
										backgroundColor: '#9487bc',
										color: '#fafafa',
										fontSize: '0.875rem',
										width: '14rem',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'center',
										margin: '0 auto',
										textTransform: 'uppercase',
										marginTop: '0.875rem'
									}}
									onClick={changeLanguageConfirmation}
								>
									Change your language
								</Button>
							</>
					}

					<Button
						style={{
							backgroundColor: '#9487bc',
							color: '#fafafa',
							fontSize: '0.875rem',
							width: '14rem',
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'center',
							margin: '0 auto',
							marginTop: '2rem'
						}}
						href={courseData.link}
						target='_blank'
					>
						Go to your Course
					</Button>
				</FormControl>
			</form>
		</>
	);
};
export default Step2;

import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { useState } from "react";
import { deleteCaseById } from "../../helpers/admin.helpers";
import AddEditOutcomeModal from "./AddEditOutcomeModal";
import { CaseCardStyle } from "./CaseCardStyle";
import DeleteModel from "./DeleteModel";

const useStyle = makeStyles((theme) => ({
  status: {
    marginLeft: "25%",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px",
    },
  },
}));

const CaseCard = ({ caseData, onUpdate, onDelete }) => {
  const [isModal, isSetModal] = useState(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);
  const theme = useTheme();
  const classes = useStyle(theme);
  const handleOpen = () => {
    isSetModal(true);
  };

  const handleDeleteModelOpen = () => {
    setDeleteModelOpen((model) => !model);
  };

  const handleConfirm = () =>
    deleteCaseById(caseData.id, (id) => {
      onDelete(id);
      setDeleteModelOpen(false);
    });

  const labels = [
    {
      label: "Status",
      value: [caseData.status, caseData.createdAt],
      style: {
        fontWeight: "bold",
        color: "#5a2b71",
        fontSize: "1.3rem",
        marginBottom: "15px",
        borderBottom: "1px solid #5b2b6f",
      },
    },
    { label: "MHPSS Case ID", value: caseData.id },
    { label: "Name", value: caseData.name },
    { label: "Age", value: caseData.age },
    { label: "Contact Number", value: caseData.contactNumber },
    { label: "Source", value: caseData.source },
    { label: "District", value: caseData.district },
    { label: "Description", value: caseData.description },
    { label: "Diagnosis", value: caseData.diagnosis },
    { label: "Urgent", value: caseData["urgent?"] },
    { label: "Contact the team", value: caseData["contactTeam?"] },
    { label: "Outcome", value: caseData.outcome },
    { label: "Reporter ID", value: caseData.reporterId },
    { label: "Reporter Name", value: caseData.reporterName },
    { label: "Reporter Phone Number", value: caseData.reporterPhoneNumber },
  ];

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card style={CaseCardStyle.cardStyles}>
        <CardContent style={{ width: "100%" }}>
          {labels.map((item, index) => (
            <Typography key={index} style={item.style}>
              {index === 0 && item.value[1]}
              {item.label === "Status" && (
                <span className={classes.status}>{item.label}: </span>
              )}
              {item.label !== "" && item.label !== "Status" && (
                <span style={{ fontWeight: "bold" }}>{item.label}: </span>
              )}
              {item.label === "Status" && (
                <>
                  <span style={{ fontSize: "16px" }}>{item["value"][0]}</span>
                  <IconButton
                    size="small"
                    style={{ marginLeft: "4px" }}
                    onClick={handleDeleteModelOpen}>
                    <Delete
                      fontSize="small"
                      style={{
                        color: "#e4403f",
                      }}
                    />
                  </IconButton>
                </>
              )}
              {(item.label !== "Status" ||
                (item.label === "Outcome" && item.value !== "N/A ")) &&
                item.value}
              {item.label === "Outcome" && item.value === "N/A " && (
                <Button
                  style={CaseCardStyle.AddEditButtonStyle}
                  onClick={handleOpen}>
                  Add
                </Button>
              )}
              {item.label === "Outcome" && item.value !== "N/A " && (
                <Typography
                  style={{
                    display: "inline",
                    cursor: "pointer",
                    color: "#5b2b6f",
                    fontWeight: "bold",
                    marginLeft: "7px",
                  }}
                  onClick={handleOpen}>
                  <u>Edit Outcome</u>
                </Typography>
              )}
              {item.label === "Urgent" && (
                <Switch checked={item.value === "Yes"} />
              )}
              {item.label === "Contact the team" && (
                <Switch checked={item.value === "Yes"} />
              )}
            </Typography>
          ))}
          {isModal && (
            <AddEditOutcomeModal
              isModal={isModal}
              isSetModal={isSetModal}
              onUpdate={onUpdate}
              caseData={caseData}
            />
          )}
          {deleteModelOpen && (
            <DeleteModel
              deleteModelOpen={deleteModelOpen}
              handleDeleteModelOpen={handleDeleteModelOpen}
              handleConfirm={handleConfirm}
            />
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CaseCard;
